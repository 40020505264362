import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

export const HeaderLogo: React.FC = () => {
  return (
    <StyledLink href="/">
      <img alt="Tumelo" src="/images/Main_Logo_Tumelo_White-min.png" width="79" height="17" />
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  align-self: center;
  min-width: 79px;
`
