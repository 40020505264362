import React from 'react'
import styled from 'styled-components'
import { Stack } from '@tumelo/designsystem'
import { useRoutes } from '../../../application/hooks/useRoutes'
import { HeaderLink } from './HeaderLink'

export const HeaderNavigation: React.FC = () => {
  const routes = useRoutes()
  return (
    <nav>
      <Stack as="ul" flexDirection="row" flexWrap rowGap="0" columnGap="2.4rem">
        {routes.map(({ label, href, current, hidden }) =>
          hidden ? null : (
            <StyledListItem key={label}>
              <HeaderLink href={href} current={current} label={label} />
            </StyledListItem>
          )
        )}
      </Stack>
    </nav>
  )
}

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
