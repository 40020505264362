import { FundManager } from '../../proto/tumelo/stewardshipbff/v1/fund_manager'

export const sampleFundManager = (override?: Partial<FundManager>): FundManager => {
  return FundManager.fromPartial({
    id: 'd2dbc903-44e7-43a8-b8e2-c010a31716bc',
    title: 'LGIM',
    websiteUrl: 'https://www.lgim.com/',
    ...override,
  })
}
