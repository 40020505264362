import { VotingInstruction } from '../proto/tumelo/votingpolicy/v1/voting_recommendation';
import { VoteOption } from '../proto/tumelo/lib/v1/voting';
export function toVoteOption(votingInstruction) {
    switch (votingInstruction) {
        case VotingInstruction.VOTING_INSTRUCTION_INVALID:
            return VoteOption.VOTE_OPTION_INVALID;
        case VotingInstruction.VOTING_INSTRUCTION_FOR:
            return VoteOption.VOTE_OPTION_FOR;
        case VotingInstruction.VOTING_INSTRUCTION_AGAINST:
            return VoteOption.VOTE_OPTION_AGAINST;
        case VotingInstruction.VOTING_INSTRUCTION_ABSTAIN:
            return VoteOption.VOTE_OPTION_ABSTAIN;
        case VotingInstruction.VOTING_INSTRUCTION_WITHHOLD:
            return VoteOption.VOTE_OPTION_WITHHOLD;
        case VotingInstruction.VOTING_INSTRUCTION_NO_ACTION:
            return VoteOption.VOTE_OPTION_NO_ACTION;
        case VotingInstruction.VOTING_INSTRUCTION_ONE_YEAR:
            return VoteOption.VOTE_OPTION_ONE_YEAR;
        case VotingInstruction.VOTING_INSTRUCTION_TWO_YEARS:
            return VoteOption.VOTE_OPTION_TWO_YEARS;
        case VotingInstruction.VOTING_INSTRUCTION_THREE_YEARS:
            return VoteOption.VOTE_OPTION_THREE_YEARS;
        default:
            return VoteOption.UNRECOGNIZED;
    }
}
export var getVotingInstructionsToValueLabelPairs = function () {
    return [
        { value: VotingInstruction.VOTING_INSTRUCTION_FOR, label: 'For' },
        { value: VotingInstruction.VOTING_INSTRUCTION_AGAINST, label: 'Against' },
        { value: VotingInstruction.VOTING_INSTRUCTION_ABSTAIN, label: 'Abstain' },
        { value: VotingInstruction.VOTING_INSTRUCTION_WITHHOLD, label: 'Withhold' },
        { value: VotingInstruction.VOTING_INSTRUCTION_ONE_YEAR, label: 'One Year' },
        { value: VotingInstruction.VOTING_INSTRUCTION_TWO_YEARS, label: 'Two Years' },
        { value: VotingInstruction.VOTING_INSTRUCTION_THREE_YEARS, label: 'Three Years' },
        { value: VotingInstruction.VOTING_INSTRUCTION_NO_ACTION, label: 'No Action' },
    ];
};
