import { AvailableToInvestorType } from '../proto/tumelo/votingpolicy/v1/voting_policy';
var prefix = "votingPolicies/";
export var IDToName = function (_a) {
    var votingPolicyID = _a.votingPolicyID;
    return prefix + votingPolicyID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 2) {
        throw new Error("bad voting policy name: ".concat(name));
    }
    return {
        votingPolicyID: parts[1],
    };
};
export var AvailableToInvestorToDisplayString = function (availableTo) {
    switch (availableTo) {
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL:
            return 'Institutional';
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_RETAIL:
            return 'Retail EOW';
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV:
            return 'Retail PTV';
    }
    return 'unknown';
};
