import {
  Body1,
  Caption,
  InformationIcon,
  InstagramIcon,
  LinkedinFillIcon,
  MessageIcon,
  Stack,
  TwitterFillIcon,
  VisuallyHidden,
  Modal,
} from '@tumelo/designsystem'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

interface FooterProps {
  className?: string
}

export const Footer = ({ className }: FooterProps) => {
  const { colors } = useTheme()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <StyledFooter className={className}>
      <Stack flexDirection="row" justifyContent="space-between">
        <div>
          <Body1 semibold color="white">
            Stewardship Platform
          </Body1>
          <StyledImage
            src="https://res.cloudinary.com/tumelo/image/upload/v1666371214/ajijjkk3j1uggztlt9rm.png"
            alt="Powered by Tumelo"
            height="15"
            crossOrigin=""
          />
        </div>
        <div>
          <Stack gap="4.0rem" flexDirection="row">
            <div>
              <Caption semibold color={colors.white}>
                Product improvements
              </Caption>
              <Stack as="ul">
                <li>
                  <StyledFlexAnchor href="mailto:userresearch@tumelo.com">
                    <MessageIcon width="15" height="15" fill={colors.info.medium} />
                    <Caption semibold color={colors.info.medium} mb="0">
                      Feedback
                    </Caption>
                  </StyledFlexAnchor>
                </li>
                <li>
                  <StyledButton onClick={() => setModalOpen(!modalOpen)}>
                    <InformationIcon width="15" height="15" fill={colors.info.medium} />
                    <Caption as="span" semibold color={colors.info.medium} mb="0">
                      Disclaimer
                    </Caption>
                  </StyledButton>
                </li>
              </Stack>
              <Modal
                type="default"
                open={modalOpen}
                headerType="title"
                title="Disclaimer"
                onCloseCallback={() => setModalOpen(!modalOpen)}
                body="The information, data, analyses and opinions presented within the Stewardship Platform do not constitute investment advice; are provided solely for informational purposes and therefore is not an offer to buy or sell a security; are not warranted to be correct, complete or accurate; and are subject to change without notice. Except as otherwise required by law, Tumelo shall not be responsible for any trading decisions, damages or other losses resulting from, or related to, the information, data, analyses or opinions or their use."
              />
            </div>
            <div>
              <Caption semibold color={colors.white}>
                Follow us
              </Caption>
              <Stack as="ul" flexDirection="row">
                <li>
                  <a href="https://www.instagram.com/tumelohq/" target="_blank" rel="noreferrer">
                    <InstagramIcon width="20" height="20" fill={colors.info.medium} />
                    <VisuallyHidden>Instagram</VisuallyHidden>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/tumelohq" target="_blank" rel="noreferrer">
                    <TwitterFillIcon width="20" height="20" fill={colors.info.medium} />
                    <VisuallyHidden>Twitter</VisuallyHidden>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/tumelo/" target="_blank" rel="noreferrer">
                    <LinkedinFillIcon width="20" height="20" fill={colors.info.medium} />
                    <VisuallyHidden>LinkedIn</VisuallyHidden>
                  </a>
                </li>
              </Stack>
            </div>
          </Stack>
        </div>
      </Stack>

      <StyledBottomFooter>
        <Stack gap="4.0rem" flexDirection="row">
          <Caption semibold color={colors.info.medium} mb="0">
            &copy; Tumelo
          </Caption>
          <Caption semibold color={colors.info.medium} mb="0">
            Runway East, Bristol, BS1 6AA
          </Caption>
          <StyledStack forwardedAs="ul" flexDirection="row" gap="4.0rem">
            <li>
              <StyledAnchor href="https://www.tumelo.com/terms-and-conditions" target="_blank">
                Terms & conditions
              </StyledAnchor>
            </li>
            <li>
              <StyledAnchor href="https://www.tumelo.com/privacy-policy" target="_blank">
                Privacy policy
              </StyledAnchor>
            </li>
            <li>
              <StyledAnchor href="https://www.tumelo.com/cookies-policy" target="_blank">
                Cookies policy
              </StyledAnchor>
            </li>
          </StyledStack>
        </Stack>
      </StyledBottomFooter>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  padding: 4rem;
  background: ${({ theme }) => theme.colors.background.dark};
`

const StyledStack = styled(Stack)`
  margin-top: -3px;
`

const StyledImage = styled.img`
  margin-bottom: 2.4rem;
`

const StyledBottomFooter = styled.div`
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.colors.primary.light};
  padding-top: 1.6rem;
  margin-top: 3.6rem;
  max-width: 80rem; /* TODO: check that value after implementing Globastyles */
`

const StyledFlexAnchor = styled.a`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.info.medium};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

const StyledButton = styled.button`
  display: flex;
  border: none;
  padding: 0;
  background: none;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 0.8rem;
  }
`
