var prefix1a = 'fundManagers/';
var prefix1b = 'providers/';
var prefix2 = 'securityIDTypes/';
var prefix3 = 'securityIDs/';
export var typeIDToName = function (_a) {
    var parentName = _a.parentName, securityIDType = _a.securityIDType;
    return parentName + '/' + prefix2 + securityIDType;
};
export var typeNameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4 || (!name.startsWith(prefix1a) && !name.startsWith(prefix1b)) || !name.includes(prefix2)) {
        throw new Error("bad custom security ID type name: ".concat(name));
    }
    return { parentName: parts.slice(0, 2).join('/'), securityIDType: parts[3] };
};
export var IDToName = function (_a) {
    var parentName = _a.parentName, securityIDType = _a.securityIDType, securityID = _a.securityID;
    return parentName + '/' + prefix2 + securityIDType + '/' + prefix3 + securityID;
};
export var nameIsCustomName = function (name) {
    var parts = name.split('/');
    return (parts.length === 6 &&
        (name.startsWith(prefix1a) || name.startsWith(prefix1b)) &&
        name.includes(prefix2) &&
        name.includes(prefix3));
};
export var nameToID = function (name) {
    if (!nameIsCustomName(name)) {
        throw new Error("bad custom security ID name: ".concat(name));
    }
    var parts = name.split('/');
    return { parentName: parts.slice(0, 2).join('/'), securityIDType: parts[3], securityID: parts[5] };
};
export var wildcardParentName = prefix1a + '-/' + prefix2 + '-';
export var IDToLabel = function (_a) {
    var securityIDType = _a.securityIDType, securityID = _a.securityID;
    return "".concat(securityIDType, ": ").concat(securityID);
};
export var toOption = function (title, name) {
    return {
        label: title + ': ' + typeNameToID(name).securityIDType,
        value: name,
    };
};
