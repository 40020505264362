import React from 'react'
import { useRouter } from 'next/router'
import { useAsync } from '@tumelo/common'
import { Body1, H2, Link, Card, Stack } from '@tumelo/designsystem'
import { AsyncUnwrapper } from '../asyncUnwrapper'
import { blankPrivilegesCtx, MappedPrivileges, PrivilegesContext, PrivilegesCtx } from '../../application/user'
import { Spinner } from '../Spinner'
import { getCurrentSlug } from '../../utils/getCurrentSlug'
import { PageLayout } from '../PageLayout'
import { useFetchListSchemes } from '../../application/hooks/useFetchListSchemes'

interface LandingProps {
  children: React.ReactNode
}

export const Landing: React.FC<LandingProps> = ({ children }) => {
  const { data, isLoading } = useFetchListSchemes()

  const router = useRouter()
  const currentSlug = getCurrentSlug(router)

  const fetchPrivilegesCtx = useAsync(async () => {
    const mappedPrivileges: MappedPrivileges = {
      canGetSchemes: data?.schemes || [],
    }
    const ctx: PrivilegesCtx = { mappedPrivileges }
    return ctx
  }, [data])

  return (
    <AsyncUnwrapper
      result={fetchPrivilegesCtx}
      onData={(privilegesCtx) => {
        if (
          privilegesCtx &&
          (privilegesCtx.mappedPrivileges.canGetSchemes.length > 0 || router.asPath.includes(`${currentSlug}/profile`))
        ) {
          return <PrivilegesContext.Provider value={privilegesCtx}>{children}</PrivilegesContext.Provider>
        }
        if (!isLoading) {
          return (
            <PrivilegesContext.Provider value={blankPrivilegesCtx}>
              <PageLayout>
                <Card>
                  <H2 mb="3.2rem">Welcome to the Tumelo Stewardship platform</H2>
                  <Body1>This platform is designed to help you steward the investments you own or represent. </Body1>
                  <Body1>
                    In order to access the appropriate data, please email us at{' '}
                    <Link href="mailto:stewardship@tumelo.com">stewardship@tumelo.com</Link> with your name, company and
                    the scheme or portfolio you require access to.
                  </Body1>
                </Card>
              </PageLayout>
            </PrivilegesContext.Provider>
          )
        }
      }}
      onPending={
        <PrivilegesContext.Provider value={blankPrivilegesCtx}>
          <PageLayout>
            <Stack justifyContent="center">
              <Spinner />
              <Body1>Loading...</Body1>
            </Stack>
          </PageLayout>
        </PrivilegesContext.Provider>
      }
    />
  )
}
