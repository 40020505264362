import React, { useState } from 'react'
import { ButtonText, Stack, MenuIcon } from '@tumelo/designsystem'
import styled, { useTheme } from 'styled-components'
import { HeaderMenu } from './HeaderMenu'

export const HeaderMenuButton: React.FC = () => {
  const { colors } = useTheme()
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledHeaderLogo onClick={() => setOpen(!open)}>
        <Stack flexDirection="row" alignItems="center" gap="0.8rem">
          <MenuIcon fill={colors.white} />
          <ButtonText color={colors.white} mb="0">
            Menu
          </ButtonText>
        </Stack>
      </StyledHeaderLogo>
      <HeaderMenu isOpen={open} toggleOpen={() => setOpen(!open)} />
    </>
  )
}

const StyledHeaderLogo = styled.div`
  white-space: nowrap;
  cursor: pointer;
`
