var prefix1 = 'securityIDTypes/';
var prefix2 = 'securityIDs/';
// '^' + prefix1 + '[a-zA-Z]+/' + prefix2
var publicSecurityIDRegex = new RegExp('^securityIDTypes/[a-zA-Z]+/securityIDs/');
export var publicSecurityIDOptions = [
    { label: 'ISIN', value: 'isin' },
    { label: 'SEDOL', value: 'sedol' },
    { label: 'CUSIP', value: 'cusip' },
    { label: 'CIN', value: 'cin' },
    { label: 'REFINITIVTICKER', value: 'refinitivticker' },
    { label: 'CASH', value: 'cash' },
];
export var publicSecurityIDTypes = publicSecurityIDOptions.map(function (_a) {
    var value = _a.value;
    return value;
});
export var isPublicSecurityID = function (name) {
    return publicSecurityIDRegex.test(name);
};
export var IDToName = function (_a) {
    var securityIDType = _a.securityIDType, securityID = _a.securityID;
    return prefix1 + securityIDType + '/' + prefix2 + securityID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4 || !name.startsWith(prefix1) || !name.includes(prefix2)) {
        throw new Error("bad public security ID name: ".concat(name));
    }
    return { securityIDType: parts[1], securityID: parts[3] };
};
export var IDToLabel = function (_a) {
    var securityIDType = _a.securityIDType, securityID = _a.securityID;
    return "".concat(securityIDType, ": ").concat(securityID);
};
export function fromISIN(isin) {
    return IDToName({ securityIDType: 'isin', securityID: isin });
}
export function isISINInPublicSecurityIDName(publicSecurityIDName, ISIN) {
    var _a = nameToID(publicSecurityIDName), securityID = _a.securityID, securityIDType = _a.securityIDType;
    return securityIDType === 'isin' && securityID === ISIN;
}
export function isISIN(publicSecurityIDName) {
    var id = nameToID(publicSecurityIDName);
    return id.securityIDType === 'isin';
}
