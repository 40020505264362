import { libVotingPolicy } from '.';
var prefix = "votingRecommendations/";
export var IDToName = function (_a) {
    var votingPolicyID = _a.votingPolicyID, recommendationID = _a.recommendationID;
    return libVotingPolicy.IDToName({ votingPolicyID: votingPolicyID }) + '/' + prefix + recommendationID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4) {
        throw new Error("bad voting recommendation name: ".concat(name));
    }
    return {
        votingPolicyID: parts[1],
        recommendationID: parts[3],
    };
};
