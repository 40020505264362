/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */

export const protobufPackage = "tumelo.poll.v1";

/** Option describes an option of how someone may vote: FOR, AGAINST or ABSTAIN */
export enum Option {
  OPTION_INVALID = 0,
  OPTION_FOR = 1,
  OPTION_AGAINST = 2,
  OPTION_ABSTAIN = 3,
  OPTION_WITHHOLD = 4,
  OPTION_NO_ACTION = 5,
  UNRECOGNIZED = -1,
}

export function optionFromJSON(object: any): Option {
  switch (object) {
    case 0:
    case "OPTION_INVALID":
      return Option.OPTION_INVALID;
    case 1:
    case "OPTION_FOR":
      return Option.OPTION_FOR;
    case 2:
    case "OPTION_AGAINST":
      return Option.OPTION_AGAINST;
    case 3:
    case "OPTION_ABSTAIN":
      return Option.OPTION_ABSTAIN;
    case 4:
    case "OPTION_WITHHOLD":
      return Option.OPTION_WITHHOLD;
    case 5:
    case "OPTION_NO_ACTION":
      return Option.OPTION_NO_ACTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Option.UNRECOGNIZED;
  }
}

export function optionToJSON(object: Option): string {
  switch (object) {
    case Option.OPTION_INVALID:
      return "OPTION_INVALID";
    case Option.OPTION_FOR:
      return "OPTION_FOR";
    case Option.OPTION_AGAINST:
      return "OPTION_AGAINST";
    case Option.OPTION_ABSTAIN:
      return "OPTION_ABSTAIN";
    case Option.OPTION_WITHHOLD:
      return "OPTION_WITHHOLD";
    case Option.OPTION_NO_ACTION:
      return "OPTION_NO_ACTION";
    case Option.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
