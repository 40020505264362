/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Option, optionFromJSON, optionToJSON } from "./option";

export const protobufPackage = "tumelo.stewardshipreporting.v1";

export interface MisalignmentReportRow {
  /** organization_title is the title of the Organization. */
  organizationTitle: string;
  /** general_meeting_date is the date the GM took place. */
  generalMeetingDate:
    | Date
    | undefined;
  /** themes is a list of the themes related to this Proposals. */
  themes: MisalignmentReportRow_ProposalTheme[];
  /** proposal_name is the name of this Proposal, of the format `organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}` */
  proposalName: string;
  /** proposal_title is the title of this Proposal. */
  proposalTitle: string;
  /** investor_vote_instruction is the vote instruction provided by the current scheme. */
  investorVoteInstruction: Option;
  /** fund_manager_votes is a list of votes cast on this Proposal by Fund Managers (and their rationale, where given). */
  fundManagerVotes: MisalignmentReportRow_FundManagerVote[];
  /**
   * policy_recommendation is the recommendation provided by the current Scheme's voting policy.
   *
   * Note: in the future we could have recommendations from multiple policies.
   */
  policyRecommendation:
    | MisalignmentReportRow_PolicyRecommendation
    | undefined;
  /** gm_result is the outcome of this proposal at the General Meeting. */
  gmResult: Option;
}

export interface MisalignmentReportRow_ProposalTheme {
  /** code is the identifier code for the Proposal Theme. */
  code: string;
  /** title is the title of the Proposal Theme. */
  title: string;
}

export interface MisalignmentReportRow_FundManagerVote {
  /**
   * fund_manager_name is the identifier of the fund manager
   *
   * Example value: fundManagers/{uuid}
   */
  fundManagerName: string;
  /** fund_manager_title is the title of the fund manager. */
  fundManagerTitle: string;
  /** votes is the list of the votes cast by the fund manager. */
  votes: MisalignmentReportRow_FundVote[];
}

export interface MisalignmentReportRow_FundVote {
  /**
   * fund_variant_name is the identifier of the fund variant
   *
   * Example value: funds/{uuid}/variants/{uuid}
   */
  fundVariantName: string;
  /** fund_variant_title is the title of the fund. */
  fundVariantTitle: string;
  /** instruction is the vote instruction cast by the fund manager for the specified fund. */
  instruction: Option;
  /** rationale is the fund manager rationale for the vote instruction. */
  rationale: string;
}

export interface MisalignmentReportRow_PolicyRecommendation {
  /**
   * name is the identifier of the policy recommendation(?)
   *
   * Example value: votingPolicies/{uuid}/votingRecommendations/{uuid}
   */
  name: string;
  /** title is the title of the voting policy. */
  title: string;
  /** provier_title is the title of the voting policy provider. */
  providerTitle: string;
  /** instruction is the recommended voting option. */
  instruction: Option;
}

function createBaseMisalignmentReportRow(): MisalignmentReportRow {
  return {
    organizationTitle: "",
    generalMeetingDate: undefined,
    themes: [],
    proposalName: "",
    proposalTitle: "",
    investorVoteInstruction: 0,
    fundManagerVotes: [],
    policyRecommendation: undefined,
    gmResult: 0,
  };
}

export const MisalignmentReportRow = {
  encode(message: MisalignmentReportRow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organizationTitle !== "") {
      writer.uint32(10).string(message.organizationTitle);
    }
    if (message.generalMeetingDate !== undefined) {
      Timestamp.encode(toTimestamp(message.generalMeetingDate), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.themes) {
      MisalignmentReportRow_ProposalTheme.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.proposalName !== "") {
      writer.uint32(82).string(message.proposalName);
    }
    if (message.proposalTitle !== "") {
      writer.uint32(34).string(message.proposalTitle);
    }
    if (message.investorVoteInstruction !== 0) {
      writer.uint32(48).int32(message.investorVoteInstruction);
    }
    for (const v of message.fundManagerVotes) {
      MisalignmentReportRow_FundManagerVote.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.policyRecommendation !== undefined) {
      MisalignmentReportRow_PolicyRecommendation.encode(message.policyRecommendation, writer.uint32(66).fork())
        .ldelim();
    }
    if (message.gmResult !== 0) {
      writer.uint32(72).int32(message.gmResult);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MisalignmentReportRow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMisalignmentReportRow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizationTitle = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.generalMeetingDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.themes.push(MisalignmentReportRow_ProposalTheme.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.proposalName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalTitle = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.investorVoteInstruction = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fundManagerVotes.push(MisalignmentReportRow_FundManagerVote.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.policyRecommendation = MisalignmentReportRow_PolicyRecommendation.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.gmResult = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MisalignmentReportRow {
    return {
      organizationTitle: isSet(object.organizationTitle) ? String(object.organizationTitle) : "",
      generalMeetingDate: isSet(object.generalMeetingDate) ? fromJsonTimestamp(object.generalMeetingDate) : undefined,
      themes: globalThis.Array.isArray(object?.themes)
        ? object.themes.map((e: any) => MisalignmentReportRow_ProposalTheme.fromJSON(e))
        : [],
      proposalName: isSet(object.proposalName) ? String(object.proposalName) : "",
      proposalTitle: isSet(object.proposalTitle) ? String(object.proposalTitle) : "",
      investorVoteInstruction: isSet(object.investorVoteInstruction)
        ? optionFromJSON(object.investorVoteInstruction)
        : 0,
      fundManagerVotes: globalThis.Array.isArray(object?.fundManagerVotes)
        ? object.fundManagerVotes.map((e: any) => MisalignmentReportRow_FundManagerVote.fromJSON(e))
        : [],
      policyRecommendation: isSet(object.policyRecommendation)
        ? MisalignmentReportRow_PolicyRecommendation.fromJSON(object.policyRecommendation)
        : undefined,
      gmResult: isSet(object.gmResult) ? optionFromJSON(object.gmResult) : 0,
    };
  },

  toJSON(message: MisalignmentReportRow): unknown {
    const obj: any = {};
    if (message.organizationTitle !== "") {
      obj.organizationTitle = message.organizationTitle;
    }
    if (message.generalMeetingDate !== undefined) {
      obj.generalMeetingDate = message.generalMeetingDate.toISOString();
    }
    if (message.themes?.length) {
      obj.themes = message.themes.map((e) => MisalignmentReportRow_ProposalTheme.toJSON(e));
    }
    if (message.proposalName !== "") {
      obj.proposalName = message.proposalName;
    }
    if (message.proposalTitle !== "") {
      obj.proposalTitle = message.proposalTitle;
    }
    if (message.investorVoteInstruction !== 0) {
      obj.investorVoteInstruction = optionToJSON(message.investorVoteInstruction);
    }
    if (message.fundManagerVotes?.length) {
      obj.fundManagerVotes = message.fundManagerVotes.map((e) => MisalignmentReportRow_FundManagerVote.toJSON(e));
    }
    if (message.policyRecommendation !== undefined) {
      obj.policyRecommendation = MisalignmentReportRow_PolicyRecommendation.toJSON(message.policyRecommendation);
    }
    if (message.gmResult !== 0) {
      obj.gmResult = optionToJSON(message.gmResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MisalignmentReportRow>, I>>(base?: I): MisalignmentReportRow {
    return MisalignmentReportRow.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MisalignmentReportRow>, I>>(object: I): MisalignmentReportRow {
    const message = createBaseMisalignmentReportRow();
    message.organizationTitle = object.organizationTitle ?? "";
    message.generalMeetingDate = object.generalMeetingDate ?? undefined;
    message.themes = object.themes?.map((e) => MisalignmentReportRow_ProposalTheme.fromPartial(e)) || [];
    message.proposalName = object.proposalName ?? "";
    message.proposalTitle = object.proposalTitle ?? "";
    message.investorVoteInstruction = object.investorVoteInstruction ?? 0;
    message.fundManagerVotes =
      object.fundManagerVotes?.map((e) => MisalignmentReportRow_FundManagerVote.fromPartial(e)) || [];
    message.policyRecommendation = (object.policyRecommendation !== undefined && object.policyRecommendation !== null)
      ? MisalignmentReportRow_PolicyRecommendation.fromPartial(object.policyRecommendation)
      : undefined;
    message.gmResult = object.gmResult ?? 0;
    return message;
  },
};

function createBaseMisalignmentReportRow_ProposalTheme(): MisalignmentReportRow_ProposalTheme {
  return { code: "", title: "" };
}

export const MisalignmentReportRow_ProposalTheme = {
  encode(message: MisalignmentReportRow_ProposalTheme, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MisalignmentReportRow_ProposalTheme {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMisalignmentReportRow_ProposalTheme();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MisalignmentReportRow_ProposalTheme {
    return {
      code: isSet(object.code) ? String(object.code) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: MisalignmentReportRow_ProposalTheme): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MisalignmentReportRow_ProposalTheme>, I>>(
    base?: I,
  ): MisalignmentReportRow_ProposalTheme {
    return MisalignmentReportRow_ProposalTheme.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MisalignmentReportRow_ProposalTheme>, I>>(
    object: I,
  ): MisalignmentReportRow_ProposalTheme {
    const message = createBaseMisalignmentReportRow_ProposalTheme();
    message.code = object.code ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseMisalignmentReportRow_FundManagerVote(): MisalignmentReportRow_FundManagerVote {
  return { fundManagerName: "", fundManagerTitle: "", votes: [] };
}

export const MisalignmentReportRow_FundManagerVote = {
  encode(message: MisalignmentReportRow_FundManagerVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fundManagerName !== "") {
      writer.uint32(10).string(message.fundManagerName);
    }
    if (message.fundManagerTitle !== "") {
      writer.uint32(18).string(message.fundManagerTitle);
    }
    for (const v of message.votes) {
      MisalignmentReportRow_FundVote.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MisalignmentReportRow_FundManagerVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMisalignmentReportRow_FundManagerVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundManagerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fundManagerTitle = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.votes.push(MisalignmentReportRow_FundVote.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MisalignmentReportRow_FundManagerVote {
    return {
      fundManagerName: isSet(object.fundManagerName) ? String(object.fundManagerName) : "",
      fundManagerTitle: isSet(object.fundManagerTitle) ? String(object.fundManagerTitle) : "",
      votes: globalThis.Array.isArray(object?.votes)
        ? object.votes.map((e: any) => MisalignmentReportRow_FundVote.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MisalignmentReportRow_FundManagerVote): unknown {
    const obj: any = {};
    if (message.fundManagerName !== "") {
      obj.fundManagerName = message.fundManagerName;
    }
    if (message.fundManagerTitle !== "") {
      obj.fundManagerTitle = message.fundManagerTitle;
    }
    if (message.votes?.length) {
      obj.votes = message.votes.map((e) => MisalignmentReportRow_FundVote.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MisalignmentReportRow_FundManagerVote>, I>>(
    base?: I,
  ): MisalignmentReportRow_FundManagerVote {
    return MisalignmentReportRow_FundManagerVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MisalignmentReportRow_FundManagerVote>, I>>(
    object: I,
  ): MisalignmentReportRow_FundManagerVote {
    const message = createBaseMisalignmentReportRow_FundManagerVote();
    message.fundManagerName = object.fundManagerName ?? "";
    message.fundManagerTitle = object.fundManagerTitle ?? "";
    message.votes = object.votes?.map((e) => MisalignmentReportRow_FundVote.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMisalignmentReportRow_FundVote(): MisalignmentReportRow_FundVote {
  return { fundVariantName: "", fundVariantTitle: "", instruction: 0, rationale: "" };
}

export const MisalignmentReportRow_FundVote = {
  encode(message: MisalignmentReportRow_FundVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fundVariantName !== "") {
      writer.uint32(10).string(message.fundVariantName);
    }
    if (message.fundVariantTitle !== "") {
      writer.uint32(18).string(message.fundVariantTitle);
    }
    if (message.instruction !== 0) {
      writer.uint32(24).int32(message.instruction);
    }
    if (message.rationale !== "") {
      writer.uint32(34).string(message.rationale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MisalignmentReportRow_FundVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMisalignmentReportRow_FundVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundVariantName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fundVariantTitle = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.instruction = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rationale = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MisalignmentReportRow_FundVote {
    return {
      fundVariantName: isSet(object.fundVariantName) ? String(object.fundVariantName) : "",
      fundVariantTitle: isSet(object.fundVariantTitle) ? String(object.fundVariantTitle) : "",
      instruction: isSet(object.instruction) ? optionFromJSON(object.instruction) : 0,
      rationale: isSet(object.rationale) ? String(object.rationale) : "",
    };
  },

  toJSON(message: MisalignmentReportRow_FundVote): unknown {
    const obj: any = {};
    if (message.fundVariantName !== "") {
      obj.fundVariantName = message.fundVariantName;
    }
    if (message.fundVariantTitle !== "") {
      obj.fundVariantTitle = message.fundVariantTitle;
    }
    if (message.instruction !== 0) {
      obj.instruction = optionToJSON(message.instruction);
    }
    if (message.rationale !== "") {
      obj.rationale = message.rationale;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MisalignmentReportRow_FundVote>, I>>(base?: I): MisalignmentReportRow_FundVote {
    return MisalignmentReportRow_FundVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MisalignmentReportRow_FundVote>, I>>(
    object: I,
  ): MisalignmentReportRow_FundVote {
    const message = createBaseMisalignmentReportRow_FundVote();
    message.fundVariantName = object.fundVariantName ?? "";
    message.fundVariantTitle = object.fundVariantTitle ?? "";
    message.instruction = object.instruction ?? 0;
    message.rationale = object.rationale ?? "";
    return message;
  },
};

function createBaseMisalignmentReportRow_PolicyRecommendation(): MisalignmentReportRow_PolicyRecommendation {
  return { name: "", title: "", providerTitle: "", instruction: 0 };
}

export const MisalignmentReportRow_PolicyRecommendation = {
  encode(message: MisalignmentReportRow_PolicyRecommendation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.providerTitle !== "") {
      writer.uint32(26).string(message.providerTitle);
    }
    if (message.instruction !== 0) {
      writer.uint32(32).int32(message.instruction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MisalignmentReportRow_PolicyRecommendation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMisalignmentReportRow_PolicyRecommendation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providerTitle = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.instruction = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MisalignmentReportRow_PolicyRecommendation {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
      providerTitle: isSet(object.providerTitle) ? String(object.providerTitle) : "",
      instruction: isSet(object.instruction) ? optionFromJSON(object.instruction) : 0,
    };
  },

  toJSON(message: MisalignmentReportRow_PolicyRecommendation): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.providerTitle !== "") {
      obj.providerTitle = message.providerTitle;
    }
    if (message.instruction !== 0) {
      obj.instruction = optionToJSON(message.instruction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MisalignmentReportRow_PolicyRecommendation>, I>>(
    base?: I,
  ): MisalignmentReportRow_PolicyRecommendation {
    return MisalignmentReportRow_PolicyRecommendation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MisalignmentReportRow_PolicyRecommendation>, I>>(
    object: I,
  ): MisalignmentReportRow_PolicyRecommendation {
    const message = createBaseMisalignmentReportRow_PolicyRecommendation();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    message.providerTitle = object.providerTitle ?? "";
    message.instruction = object.instruction ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
