import { createGlobalStyle } from 'styled-components'
import { SoftConfigThemeSchema } from '@tumelo/designsystem'

const defaultTheme = SoftConfigThemeSchema.parse()

export const AmplifyStyles = createGlobalStyle`
  [data-amplify-authenticator] {
    background-color: ${defaultTheme.colors.primary.dark};
    flex-grow:1;
  }


  /* apply LayoutWrapper styles */
  #__next > div { 
    display: flex;
    flex-direction: column;
    overflow: inherit;
    width: auto;
    height: 100vh;
  }

  [data-amplify-authenticator] [data-amplify-container] { 
    place-self: start center;
    margin-top: 10%;
    margin-bottom: 100px;

  }

  [data-amplify-authenticator] [data-amplify-router] {
    border-radius: 8px;
    border-width: 0;
  } 
  
  .amplify-tabs__list {
    border-radius: 8px;
    border-width: 1px 0 0 0;
  }

  .amplify-tabs__item {
    border-width: 4px 0 0 0;
    border-radius: 0 8px 0 0;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .amplify-tabs__item:first-child {
    border-radius: 8px 0 0 0;
  }
  
`
