import { Poll } from '../../proto/tumelo/poll/v1/poll'
import { sampleProposal } from './proposal'

export const samplePoll = (override?: Partial<Poll>): Poll =>
  Poll.fromPartial({
    name: 'polls/83c58afa-78c5-4573-aa4d-98270834d602',
    proposalName: sampleProposal().name,
    title: 'Sample poll',
    ...override,
  })
