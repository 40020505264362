/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.poll.v1";

/** GroupedVotePreferences is a group of anonymized vote preferences with a key that can be used in a map */
export interface GroupedVotePreferences {
  key: string;
  pollVotePreferences: PollVotePreferences[];
}

/** PollVotePreferences contains anonymized vote preferences for a poll */
export interface PollVotePreferences {
  /** polls/{uuid} */
  pollName: string;
  votesTotal: number;
  votesFor: number;
  votesAgainst: number;
  votesAbstain: number;
  votesWithhold: number;
  votesNoAction: number;
  /** The count of automatic votes for the poll */
  autoVotesFor: number;
  /** The count of automatic votes against the poll */
  autoVotesAgainst: number;
  /** The count of automatic votes where users abstained the poll */
  autoVotesAbstain: number;
  /** The count of automatic votes where users withheld from the poll */
  autoVotesWithhold: number;
  /** The count of automatic votes where users voted 'no action' in the poll */
  autoVotesNoAction: number;
}

function createBaseGroupedVotePreferences(): GroupedVotePreferences {
  return { key: "", pollVotePreferences: [] };
}

export const GroupedVotePreferences = {
  encode(message: GroupedVotePreferences, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    for (const v of message.pollVotePreferences) {
      PollVotePreferences.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupedVotePreferences {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupedVotePreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollVotePreferences.push(PollVotePreferences.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupedVotePreferences {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      pollVotePreferences: globalThis.Array.isArray(object?.pollVotePreferences)
        ? object.pollVotePreferences.map((e: any) => PollVotePreferences.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GroupedVotePreferences): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.pollVotePreferences?.length) {
      obj.pollVotePreferences = message.pollVotePreferences.map((e) => PollVotePreferences.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupedVotePreferences>, I>>(base?: I): GroupedVotePreferences {
    return GroupedVotePreferences.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupedVotePreferences>, I>>(object: I): GroupedVotePreferences {
    const message = createBaseGroupedVotePreferences();
    message.key = object.key ?? "";
    message.pollVotePreferences = object.pollVotePreferences?.map((e) => PollVotePreferences.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollVotePreferences(): PollVotePreferences {
  return {
    pollName: "",
    votesTotal: 0,
    votesFor: 0,
    votesAgainst: 0,
    votesAbstain: 0,
    votesWithhold: 0,
    votesNoAction: 0,
    autoVotesFor: 0,
    autoVotesAgainst: 0,
    autoVotesAbstain: 0,
    autoVotesWithhold: 0,
    autoVotesNoAction: 0,
  };
}

export const PollVotePreferences = {
  encode(message: PollVotePreferences, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollName !== "") {
      writer.uint32(10).string(message.pollName);
    }
    if (message.votesTotal !== 0) {
      writer.uint32(16).int32(message.votesTotal);
    }
    if (message.votesFor !== 0) {
      writer.uint32(24).int32(message.votesFor);
    }
    if (message.votesAgainst !== 0) {
      writer.uint32(32).int32(message.votesAgainst);
    }
    if (message.votesAbstain !== 0) {
      writer.uint32(40).int32(message.votesAbstain);
    }
    if (message.votesWithhold !== 0) {
      writer.uint32(48).int32(message.votesWithhold);
    }
    if (message.votesNoAction !== 0) {
      writer.uint32(56).int32(message.votesNoAction);
    }
    if (message.autoVotesFor !== 0) {
      writer.uint32(64).int32(message.autoVotesFor);
    }
    if (message.autoVotesAgainst !== 0) {
      writer.uint32(72).int32(message.autoVotesAgainst);
    }
    if (message.autoVotesAbstain !== 0) {
      writer.uint32(80).int32(message.autoVotesAbstain);
    }
    if (message.autoVotesWithhold !== 0) {
      writer.uint32(88).int32(message.autoVotesWithhold);
    }
    if (message.autoVotesNoAction !== 0) {
      writer.uint32(96).int32(message.autoVotesNoAction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollVotePreferences {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollVotePreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.votesTotal = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.votesFor = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.votesAgainst = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.votesAbstain = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.votesWithhold = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.votesNoAction = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.autoVotesFor = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.autoVotesAgainst = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.autoVotesAbstain = reader.int32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.autoVotesWithhold = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.autoVotesNoAction = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollVotePreferences {
    return {
      pollName: isSet(object.pollName) ? String(object.pollName) : "",
      votesTotal: isSet(object.votesTotal) ? Number(object.votesTotal) : 0,
      votesFor: isSet(object.votesFor) ? Number(object.votesFor) : 0,
      votesAgainst: isSet(object.votesAgainst) ? Number(object.votesAgainst) : 0,
      votesAbstain: isSet(object.votesAbstain) ? Number(object.votesAbstain) : 0,
      votesWithhold: isSet(object.votesWithhold) ? Number(object.votesWithhold) : 0,
      votesNoAction: isSet(object.votesNoAction) ? Number(object.votesNoAction) : 0,
      autoVotesFor: isSet(object.autoVotesFor) ? Number(object.autoVotesFor) : 0,
      autoVotesAgainst: isSet(object.autoVotesAgainst) ? Number(object.autoVotesAgainst) : 0,
      autoVotesAbstain: isSet(object.autoVotesAbstain) ? Number(object.autoVotesAbstain) : 0,
      autoVotesWithhold: isSet(object.autoVotesWithhold) ? Number(object.autoVotesWithhold) : 0,
      autoVotesNoAction: isSet(object.autoVotesNoAction) ? Number(object.autoVotesNoAction) : 0,
    };
  },

  toJSON(message: PollVotePreferences): unknown {
    const obj: any = {};
    if (message.pollName !== "") {
      obj.pollName = message.pollName;
    }
    if (message.votesTotal !== 0) {
      obj.votesTotal = Math.round(message.votesTotal);
    }
    if (message.votesFor !== 0) {
      obj.votesFor = Math.round(message.votesFor);
    }
    if (message.votesAgainst !== 0) {
      obj.votesAgainst = Math.round(message.votesAgainst);
    }
    if (message.votesAbstain !== 0) {
      obj.votesAbstain = Math.round(message.votesAbstain);
    }
    if (message.votesWithhold !== 0) {
      obj.votesWithhold = Math.round(message.votesWithhold);
    }
    if (message.votesNoAction !== 0) {
      obj.votesNoAction = Math.round(message.votesNoAction);
    }
    if (message.autoVotesFor !== 0) {
      obj.autoVotesFor = Math.round(message.autoVotesFor);
    }
    if (message.autoVotesAgainst !== 0) {
      obj.autoVotesAgainst = Math.round(message.autoVotesAgainst);
    }
    if (message.autoVotesAbstain !== 0) {
      obj.autoVotesAbstain = Math.round(message.autoVotesAbstain);
    }
    if (message.autoVotesWithhold !== 0) {
      obj.autoVotesWithhold = Math.round(message.autoVotesWithhold);
    }
    if (message.autoVotesNoAction !== 0) {
      obj.autoVotesNoAction = Math.round(message.autoVotesNoAction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollVotePreferences>, I>>(base?: I): PollVotePreferences {
    return PollVotePreferences.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollVotePreferences>, I>>(object: I): PollVotePreferences {
    const message = createBasePollVotePreferences();
    message.pollName = object.pollName ?? "";
    message.votesTotal = object.votesTotal ?? 0;
    message.votesFor = object.votesFor ?? 0;
    message.votesAgainst = object.votesAgainst ?? 0;
    message.votesAbstain = object.votesAbstain ?? 0;
    message.votesWithhold = object.votesWithhold ?? 0;
    message.votesNoAction = object.votesNoAction ?? 0;
    message.autoVotesFor = object.autoVotesFor ?? 0;
    message.autoVotesAgainst = object.autoVotesAgainst ?? 0;
    message.autoVotesAbstain = object.autoVotesAbstain ?? 0;
    message.autoVotesWithhold = object.autoVotesWithhold ?? 0;
    message.autoVotesNoAction = object.autoVotesNoAction ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
