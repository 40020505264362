/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/**
 * Scheme represents a slice of a Habitat, as seen by a Stewardship Platform user.
 * A scheme may be associated with
 * - multiple (composite) instruments (through the fund group)
 * - multiple investor account tags
 * These associations determine the data a Stewardship Platform user can view.
 */
export interface Scheme {
  /** GENERATED a unique relative identifier of the form 'habitats/{UUID}/schemes/{UUID}' */
  name: string;
  /** REQUIRED a user-facing title for the scheme - unique within its parent habitat */
  title: string;
  /** OPTIONAL investor_name is the investor that this scheme receives ballots for and has the ability to cast expression of wish votes */
  investorName: string;
}

function createBaseScheme(): Scheme {
  return { name: "", title: "", investorName: "" };
}

export const Scheme = {
  encode(message: Scheme, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.investorName !== "") {
      writer.uint32(26).string(message.investorName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Scheme {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScheme();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.investorName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Scheme {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
      investorName: isSet(object.investorName) ? String(object.investorName) : "",
    };
  },

  toJSON(message: Scheme): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.investorName !== "") {
      obj.investorName = message.investorName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Scheme>, I>>(base?: I): Scheme {
    return Scheme.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Scheme>, I>>(object: I): Scheme {
    const message = createBaseScheme();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    message.investorName = object.investorName ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
