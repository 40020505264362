/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** GeneralMeeting describes a general meeting */
export interface GeneralMeeting {
  /** REQUIRED the name of the general meeting (format 'organizations/{uuid}/generalMeetings/{uuid}') */
  name: string;
  /** REQUIRED name of the organization that this general meeting is for (format 'organizations/{uuid}') */
  organizationName: string;
  /** REQUIRED the proposals for this general meeting */
  proposalNames: string[];
  /** REQUIRED the date of the general meeting (this will be just the date, and the time will be midnight 00:00:00.000) */
  meetingDate:
    | Date
    | undefined;
  /** OPTIONAL url links to a page containing the proxy notice of the general meeting */
  proxyNoticeUrl: string;
  /** REQUIRED deadline to place a vote or comment */
  actionDeadline:
    | Date
    | undefined;
  /** OPTIONAL a list of the 2-letter classification codes (themes from EDI data feed) that apply to the proposals of this general meeting */
  proposalClassificationCodes: string[];
}

function createBaseGeneralMeeting(): GeneralMeeting {
  return {
    name: "",
    organizationName: "",
    proposalNames: [],
    meetingDate: undefined,
    proxyNoticeUrl: "",
    actionDeadline: undefined,
    proposalClassificationCodes: [],
  };
}

export const GeneralMeeting = {
  encode(message: GeneralMeeting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.organizationName !== "") {
      writer.uint32(18).string(message.organizationName);
    }
    for (const v of message.proposalNames) {
      writer.uint32(26).string(v!);
    }
    if (message.meetingDate !== undefined) {
      Timestamp.encode(toTimestamp(message.meetingDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.proxyNoticeUrl !== "") {
      writer.uint32(42).string(message.proxyNoticeUrl);
    }
    if (message.actionDeadline !== undefined) {
      Timestamp.encode(toTimestamp(message.actionDeadline), writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.proposalClassificationCodes) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GeneralMeeting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneralMeeting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.organizationName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalNames.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.meetingDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.proxyNoticeUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.actionDeadline = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.proposalClassificationCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GeneralMeeting {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      organizationName: isSet(object.organizationName) ? String(object.organizationName) : "",
      proposalNames: globalThis.Array.isArray(object?.proposalNames)
        ? object.proposalNames.map((e: any) => String(e))
        : [],
      meetingDate: isSet(object.meetingDate) ? fromJsonTimestamp(object.meetingDate) : undefined,
      proxyNoticeUrl: isSet(object.proxyNoticeUrl) ? String(object.proxyNoticeUrl) : "",
      actionDeadline: isSet(object.actionDeadline) ? fromJsonTimestamp(object.actionDeadline) : undefined,
      proposalClassificationCodes: globalThis.Array.isArray(object?.proposalClassificationCodes)
        ? object.proposalClassificationCodes.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: GeneralMeeting): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.organizationName !== "") {
      obj.organizationName = message.organizationName;
    }
    if (message.proposalNames?.length) {
      obj.proposalNames = message.proposalNames;
    }
    if (message.meetingDate !== undefined) {
      obj.meetingDate = message.meetingDate.toISOString();
    }
    if (message.proxyNoticeUrl !== "") {
      obj.proxyNoticeUrl = message.proxyNoticeUrl;
    }
    if (message.actionDeadline !== undefined) {
      obj.actionDeadline = message.actionDeadline.toISOString();
    }
    if (message.proposalClassificationCodes?.length) {
      obj.proposalClassificationCodes = message.proposalClassificationCodes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GeneralMeeting>, I>>(base?: I): GeneralMeeting {
    return GeneralMeeting.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GeneralMeeting>, I>>(object: I): GeneralMeeting {
    const message = createBaseGeneralMeeting();
    message.name = object.name ?? "";
    message.organizationName = object.organizationName ?? "";
    message.proposalNames = object.proposalNames?.map((e) => e) || [];
    message.meetingDate = object.meetingDate ?? undefined;
    message.proxyNoticeUrl = object.proxyNoticeUrl ?? "";
    message.actionDeadline = object.actionDeadline ?? undefined;
    message.proposalClassificationCodes = object.proposalClassificationCodes?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
