/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { User } from "./user";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** Detailed user consists of a user message and extra information that is stored in cognito */
export interface DetailedUser {
  /** REQUIRED The user stored in the stewardship bff database */
  user:
    | User
    | undefined;
  /** OPTIONAL the user stored in Cognito - can be empty if the user cannot be found in cognito (e.g was deleted) */
  cognitoUser:
    | CognitoUser
    | undefined;
  /** OPTIONAL The email for the user if no cognito user exists, can be empty if user has been redacted */
  email: string;
}

export interface CognitoUser {
  sub: string;
  givenName: string;
  familyName: string;
  email: string;
  enabled: boolean;
  userStatus: CognitoUser_UserStatus;
}

export enum CognitoUser_UserStatus {
  USER_STATUS_INVALID = 0,
  USER_STATUS_UNCONFIRMED = 1,
  USER_STATUS_CONFIRMED = 2,
  USER_STATUS_ARCHIVED = 3,
  USER_STATUS_COMPROMISED = 4,
  USER_STATUS_UNKNOWN = 5,
  USER_STATUS_RESET_REQUIRED = 6,
  USER_STATUS_FORCE_CHANGE_PASSWORD = 7,
  UNRECOGNIZED = -1,
}

export function cognitoUser_UserStatusFromJSON(object: any): CognitoUser_UserStatus {
  switch (object) {
    case 0:
    case "USER_STATUS_INVALID":
      return CognitoUser_UserStatus.USER_STATUS_INVALID;
    case 1:
    case "USER_STATUS_UNCONFIRMED":
      return CognitoUser_UserStatus.USER_STATUS_UNCONFIRMED;
    case 2:
    case "USER_STATUS_CONFIRMED":
      return CognitoUser_UserStatus.USER_STATUS_CONFIRMED;
    case 3:
    case "USER_STATUS_ARCHIVED":
      return CognitoUser_UserStatus.USER_STATUS_ARCHIVED;
    case 4:
    case "USER_STATUS_COMPROMISED":
      return CognitoUser_UserStatus.USER_STATUS_COMPROMISED;
    case 5:
    case "USER_STATUS_UNKNOWN":
      return CognitoUser_UserStatus.USER_STATUS_UNKNOWN;
    case 6:
    case "USER_STATUS_RESET_REQUIRED":
      return CognitoUser_UserStatus.USER_STATUS_RESET_REQUIRED;
    case 7:
    case "USER_STATUS_FORCE_CHANGE_PASSWORD":
      return CognitoUser_UserStatus.USER_STATUS_FORCE_CHANGE_PASSWORD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CognitoUser_UserStatus.UNRECOGNIZED;
  }
}

export function cognitoUser_UserStatusToJSON(object: CognitoUser_UserStatus): string {
  switch (object) {
    case CognitoUser_UserStatus.USER_STATUS_INVALID:
      return "USER_STATUS_INVALID";
    case CognitoUser_UserStatus.USER_STATUS_UNCONFIRMED:
      return "USER_STATUS_UNCONFIRMED";
    case CognitoUser_UserStatus.USER_STATUS_CONFIRMED:
      return "USER_STATUS_CONFIRMED";
    case CognitoUser_UserStatus.USER_STATUS_ARCHIVED:
      return "USER_STATUS_ARCHIVED";
    case CognitoUser_UserStatus.USER_STATUS_COMPROMISED:
      return "USER_STATUS_COMPROMISED";
    case CognitoUser_UserStatus.USER_STATUS_UNKNOWN:
      return "USER_STATUS_UNKNOWN";
    case CognitoUser_UserStatus.USER_STATUS_RESET_REQUIRED:
      return "USER_STATUS_RESET_REQUIRED";
    case CognitoUser_UserStatus.USER_STATUS_FORCE_CHANGE_PASSWORD:
      return "USER_STATUS_FORCE_CHANGE_PASSWORD";
    case CognitoUser_UserStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseDetailedUser(): DetailedUser {
  return { user: undefined, cognitoUser: undefined, email: "" };
}

export const DetailedUser = {
  encode(message: DetailedUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.cognitoUser !== undefined) {
      CognitoUser.encode(message.cognitoUser, writer.uint32(18).fork()).ldelim();
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DetailedUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailedUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cognitoUser = CognitoUser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DetailedUser {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      cognitoUser: isSet(object.cognitoUser) ? CognitoUser.fromJSON(object.cognitoUser) : undefined,
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: DetailedUser): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.cognitoUser !== undefined) {
      obj.cognitoUser = CognitoUser.toJSON(message.cognitoUser);
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DetailedUser>, I>>(base?: I): DetailedUser {
    return DetailedUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetailedUser>, I>>(object: I): DetailedUser {
    const message = createBaseDetailedUser();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.cognitoUser = (object.cognitoUser !== undefined && object.cognitoUser !== null)
      ? CognitoUser.fromPartial(object.cognitoUser)
      : undefined;
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseCognitoUser(): CognitoUser {
  return { sub: "", givenName: "", familyName: "", email: "", enabled: false, userStatus: 0 };
}

export const CognitoUser = {
  encode(message: CognitoUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sub !== "") {
      writer.uint32(10).string(message.sub);
    }
    if (message.givenName !== "") {
      writer.uint32(18).string(message.givenName);
    }
    if (message.familyName !== "") {
      writer.uint32(26).string(message.familyName);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.enabled === true) {
      writer.uint32(40).bool(message.enabled);
    }
    if (message.userStatus !== 0) {
      writer.uint32(48).int32(message.userStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CognitoUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCognitoUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sub = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.givenName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.familyName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.userStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CognitoUser {
    return {
      sub: isSet(object.sub) ? String(object.sub) : "",
      givenName: isSet(object.givenName) ? String(object.givenName) : "",
      familyName: isSet(object.familyName) ? String(object.familyName) : "",
      email: isSet(object.email) ? String(object.email) : "",
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
      userStatus: isSet(object.userStatus) ? cognitoUser_UserStatusFromJSON(object.userStatus) : 0,
    };
  },

  toJSON(message: CognitoUser): unknown {
    const obj: any = {};
    if (message.sub !== "") {
      obj.sub = message.sub;
    }
    if (message.givenName !== "") {
      obj.givenName = message.givenName;
    }
    if (message.familyName !== "") {
      obj.familyName = message.familyName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.enabled === true) {
      obj.enabled = message.enabled;
    }
    if (message.userStatus !== 0) {
      obj.userStatus = cognitoUser_UserStatusToJSON(message.userStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CognitoUser>, I>>(base?: I): CognitoUser {
    return CognitoUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CognitoUser>, I>>(object: I): CognitoUser {
    const message = createBaseCognitoUser();
    message.sub = object.sub ?? "";
    message.givenName = object.givenName ?? "";
    message.familyName = object.familyName ?? "";
    message.email = object.email ?? "";
    message.enabled = object.enabled ?? false;
    message.userStatus = object.userStatus ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
