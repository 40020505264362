/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { MisalignmentReportRow } from "./misalignment_report";
import { PaginationRequest, PaginationResponse } from "./pagination";

export const protobufPackage = "tumelo.stewardshipreporting.v1";

export interface FetchMisalignmentReportRequest {
  /** REQUIRED filters to use when generating the report */
  filters:
    | MisalignmentReportFilters
    | undefined;
  /** OPTIONAL the pagination to apply to the report results */
  pagination: PaginationRequest | undefined;
}

export interface GenerateMisalignmentReportDownloadLinkRequest {
  /** REQUIRED filters to use when generating the report */
  filters: MisalignmentReportFilters | undefined;
}

export interface MisalignmentReportFilters {
  /**
   * REQUIRED scheme_name which the report should be generated for.
   *
   * Example value: habitats/{uuid}/schemes/{uuid}
   */
  schemeName: string;
  /** OPTIONAL free_text_search filters the proposals based on a free-text search of the organization title. */
  freeTextSearch: string;
  /**
   * OPTIONAL only_fund_manager_votes filters the proposals to only those that Fund Managers have voted on.
   * `true` filters for proposals that have been voted on by a Fund Manager.
   * `false` does not filter.
   * Defaults to `false`.
   */
  onlyFundManagerVotes: boolean;
  /** OPTIONAL include_theme_codes filters the proposals based on the given list of Proposal theme codes. */
  includeThemeCodes: string[];
  /** OPTIONAL meeting_date_after specifies the start of the time range to report Proposals from (inclusive). */
  meetingDateAfter:
    | Date
    | undefined;
  /** OPTIONAL meeting_date_before specifies the end of the time range to report Proposals from (inclusive). */
  meetingDateBefore:
    | Date
    | undefined;
  /**
   * OPTIONAL misalignment_a specifies the first party to include in the misalignment filters.
   *
   * Required if misalignment_b is specified.
   * Cannot be the same as misalignment_b.
   *
   * Allowed values:
   * - investor
   * - policy
   * - gm
   * - all_fund_managers
   * - Fund Manager Name (in the format `fundManagers/{uuid}`)
   */
  misalignmentA: string;
  /**
   * OPTIONAL misalignment_b specifies the second party to include in the misalignment filters.
   *
   * Required if misalignment_a is specified.
   * Cannot be the same as misalignment_a.
   *
   * Allowed values:
   * - investor
   * - policy
   * - gm
   * - all_fund_managers
   * - Fund Manager Name (in the format `fundManagers/{uuid}`)
   */
  misalignmentB: string;
}

export interface FetchMisalignmentReportResponse {
  /** rows specifies the rows which within the report. */
  rows: MisalignmentReportRow[];
  /** pagination contains information about the pagination state of the response. */
  pagination: PaginationResponse | undefined;
}

export interface GenerateMisalignmentReportDownloadLinkResponse {
  /** links is a list report urls */
  links: string[];
}

function createBaseFetchMisalignmentReportRequest(): FetchMisalignmentReportRequest {
  return { filters: undefined, pagination: undefined };
}

export const FetchMisalignmentReportRequest = {
  encode(message: FetchMisalignmentReportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filters !== undefined) {
      MisalignmentReportFilters.encode(message.filters, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchMisalignmentReportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchMisalignmentReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filters = MisalignmentReportFilters.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchMisalignmentReportRequest {
    return {
      filters: isSet(object.filters) ? MisalignmentReportFilters.fromJSON(object.filters) : undefined,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: FetchMisalignmentReportRequest): unknown {
    const obj: any = {};
    if (message.filters !== undefined) {
      obj.filters = MisalignmentReportFilters.toJSON(message.filters);
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchMisalignmentReportRequest>, I>>(base?: I): FetchMisalignmentReportRequest {
    return FetchMisalignmentReportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchMisalignmentReportRequest>, I>>(
    object: I,
  ): FetchMisalignmentReportRequest {
    const message = createBaseFetchMisalignmentReportRequest();
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? MisalignmentReportFilters.fromPartial(object.filters)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGenerateMisalignmentReportDownloadLinkRequest(): GenerateMisalignmentReportDownloadLinkRequest {
  return { filters: undefined };
}

export const GenerateMisalignmentReportDownloadLinkRequest = {
  encode(message: GenerateMisalignmentReportDownloadLinkRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filters !== undefined) {
      MisalignmentReportFilters.encode(message.filters, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateMisalignmentReportDownloadLinkRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateMisalignmentReportDownloadLinkRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filters = MisalignmentReportFilters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateMisalignmentReportDownloadLinkRequest {
    return { filters: isSet(object.filters) ? MisalignmentReportFilters.fromJSON(object.filters) : undefined };
  },

  toJSON(message: GenerateMisalignmentReportDownloadLinkRequest): unknown {
    const obj: any = {};
    if (message.filters !== undefined) {
      obj.filters = MisalignmentReportFilters.toJSON(message.filters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateMisalignmentReportDownloadLinkRequest>, I>>(
    base?: I,
  ): GenerateMisalignmentReportDownloadLinkRequest {
    return GenerateMisalignmentReportDownloadLinkRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateMisalignmentReportDownloadLinkRequest>, I>>(
    object: I,
  ): GenerateMisalignmentReportDownloadLinkRequest {
    const message = createBaseGenerateMisalignmentReportDownloadLinkRequest();
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? MisalignmentReportFilters.fromPartial(object.filters)
      : undefined;
    return message;
  },
};

function createBaseMisalignmentReportFilters(): MisalignmentReportFilters {
  return {
    schemeName: "",
    freeTextSearch: "",
    onlyFundManagerVotes: false,
    includeThemeCodes: [],
    meetingDateAfter: undefined,
    meetingDateBefore: undefined,
    misalignmentA: "",
    misalignmentB: "",
  };
}

export const MisalignmentReportFilters = {
  encode(message: MisalignmentReportFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.schemeName !== "") {
      writer.uint32(10).string(message.schemeName);
    }
    if (message.freeTextSearch !== "") {
      writer.uint32(18).string(message.freeTextSearch);
    }
    if (message.onlyFundManagerVotes === true) {
      writer.uint32(24).bool(message.onlyFundManagerVotes);
    }
    for (const v of message.includeThemeCodes) {
      writer.uint32(42).string(v!);
    }
    if (message.meetingDateAfter !== undefined) {
      Timestamp.encode(toTimestamp(message.meetingDateAfter), writer.uint32(50).fork()).ldelim();
    }
    if (message.meetingDateBefore !== undefined) {
      Timestamp.encode(toTimestamp(message.meetingDateBefore), writer.uint32(58).fork()).ldelim();
    }
    if (message.misalignmentA !== "") {
      writer.uint32(66).string(message.misalignmentA);
    }
    if (message.misalignmentB !== "") {
      writer.uint32(74).string(message.misalignmentB);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MisalignmentReportFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMisalignmentReportFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.schemeName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.freeTextSearch = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.onlyFundManagerVotes = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.includeThemeCodes.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.meetingDateAfter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.meetingDateBefore = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.misalignmentA = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.misalignmentB = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MisalignmentReportFilters {
    return {
      schemeName: isSet(object.schemeName) ? String(object.schemeName) : "",
      freeTextSearch: isSet(object.freeTextSearch) ? String(object.freeTextSearch) : "",
      onlyFundManagerVotes: isSet(object.onlyFundManagerVotes) ? Boolean(object.onlyFundManagerVotes) : false,
      includeThemeCodes: globalThis.Array.isArray(object?.includeThemeCodes)
        ? object.includeThemeCodes.map((e: any) => String(e))
        : [],
      meetingDateAfter: isSet(object.meetingDateAfter) ? fromJsonTimestamp(object.meetingDateAfter) : undefined,
      meetingDateBefore: isSet(object.meetingDateBefore) ? fromJsonTimestamp(object.meetingDateBefore) : undefined,
      misalignmentA: isSet(object.misalignmentA) ? String(object.misalignmentA) : "",
      misalignmentB: isSet(object.misalignmentB) ? String(object.misalignmentB) : "",
    };
  },

  toJSON(message: MisalignmentReportFilters): unknown {
    const obj: any = {};
    if (message.schemeName !== "") {
      obj.schemeName = message.schemeName;
    }
    if (message.freeTextSearch !== "") {
      obj.freeTextSearch = message.freeTextSearch;
    }
    if (message.onlyFundManagerVotes === true) {
      obj.onlyFundManagerVotes = message.onlyFundManagerVotes;
    }
    if (message.includeThemeCodes?.length) {
      obj.includeThemeCodes = message.includeThemeCodes;
    }
    if (message.meetingDateAfter !== undefined) {
      obj.meetingDateAfter = message.meetingDateAfter.toISOString();
    }
    if (message.meetingDateBefore !== undefined) {
      obj.meetingDateBefore = message.meetingDateBefore.toISOString();
    }
    if (message.misalignmentA !== "") {
      obj.misalignmentA = message.misalignmentA;
    }
    if (message.misalignmentB !== "") {
      obj.misalignmentB = message.misalignmentB;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MisalignmentReportFilters>, I>>(base?: I): MisalignmentReportFilters {
    return MisalignmentReportFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MisalignmentReportFilters>, I>>(object: I): MisalignmentReportFilters {
    const message = createBaseMisalignmentReportFilters();
    message.schemeName = object.schemeName ?? "";
    message.freeTextSearch = object.freeTextSearch ?? "";
    message.onlyFundManagerVotes = object.onlyFundManagerVotes ?? false;
    message.includeThemeCodes = object.includeThemeCodes?.map((e) => e) || [];
    message.meetingDateAfter = object.meetingDateAfter ?? undefined;
    message.meetingDateBefore = object.meetingDateBefore ?? undefined;
    message.misalignmentA = object.misalignmentA ?? "";
    message.misalignmentB = object.misalignmentB ?? "";
    return message;
  },
};

function createBaseFetchMisalignmentReportResponse(): FetchMisalignmentReportResponse {
  return { rows: [], pagination: undefined };
}

export const FetchMisalignmentReportResponse = {
  encode(message: FetchMisalignmentReportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rows) {
      MisalignmentReportRow.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchMisalignmentReportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchMisalignmentReportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rows.push(MisalignmentReportRow.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchMisalignmentReportResponse {
    return {
      rows: globalThis.Array.isArray(object?.rows)
        ? object.rows.map((e: any) => MisalignmentReportRow.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: FetchMisalignmentReportResponse): unknown {
    const obj: any = {};
    if (message.rows?.length) {
      obj.rows = message.rows.map((e) => MisalignmentReportRow.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchMisalignmentReportResponse>, I>>(base?: I): FetchMisalignmentReportResponse {
    return FetchMisalignmentReportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchMisalignmentReportResponse>, I>>(
    object: I,
  ): FetchMisalignmentReportResponse {
    const message = createBaseFetchMisalignmentReportResponse();
    message.rows = object.rows?.map((e) => MisalignmentReportRow.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGenerateMisalignmentReportDownloadLinkResponse(): GenerateMisalignmentReportDownloadLinkResponse {
  return { links: [] };
}

export const GenerateMisalignmentReportDownloadLinkResponse = {
  encode(
    message: GenerateMisalignmentReportDownloadLinkResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.links) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateMisalignmentReportDownloadLinkResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateMisalignmentReportDownloadLinkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.links.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateMisalignmentReportDownloadLinkResponse {
    return { links: globalThis.Array.isArray(object?.links) ? object.links.map((e: any) => String(e)) : [] };
  },

  toJSON(message: GenerateMisalignmentReportDownloadLinkResponse): unknown {
    const obj: any = {};
    if (message.links?.length) {
      obj.links = message.links;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateMisalignmentReportDownloadLinkResponse>, I>>(
    base?: I,
  ): GenerateMisalignmentReportDownloadLinkResponse {
    return GenerateMisalignmentReportDownloadLinkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateMisalignmentReportDownloadLinkResponse>, I>>(
    object: I,
  ): GenerateMisalignmentReportDownloadLinkResponse {
    const message = createBaseGenerateMisalignmentReportDownloadLinkResponse();
    message.links = object.links?.map((e) => e) || [];
    return message;
  },
};

/** StewardshipReportingService provides reporting functionality for the stewardship platform */
export interface StewardshipReportingService {
  /**
   * FetchMisalignmentReport retrieves a paginated list of past proposals for the given Scheme.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - PermissionDenied - if the user does not have access to this scheme.
   * - NotFound - if the scheme was not found.
   */
  FetchMisalignmentReport(
    request: DeepPartial<FetchMisalignmentReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchMisalignmentReportResponse>;
  /**
   * GenerateMisalignmentReportDownloadLink generates a misalignment report download link.
   *
   * Possible errors:
   * - InvalidArgument - if any of the request parameters are invalid.
   * - PermissionDenied - if the user does not have access to this scheme.
   * - NotFound - if the scheme was not found.
   */
  GenerateMisalignmentReportDownloadLink(
    request: DeepPartial<GenerateMisalignmentReportDownloadLinkRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateMisalignmentReportDownloadLinkResponse>;
}

export class StewardshipReportingServiceClientImpl implements StewardshipReportingService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.FetchMisalignmentReport = this.FetchMisalignmentReport.bind(this);
    this.GenerateMisalignmentReportDownloadLink = this.GenerateMisalignmentReportDownloadLink.bind(this);
  }

  FetchMisalignmentReport(
    request: DeepPartial<FetchMisalignmentReportRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchMisalignmentReportResponse> {
    return this.rpc.unary(
      StewardshipReportingServiceFetchMisalignmentReportDesc,
      FetchMisalignmentReportRequest.fromPartial(request),
      metadata,
    );
  }

  GenerateMisalignmentReportDownloadLink(
    request: DeepPartial<GenerateMisalignmentReportDownloadLinkRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateMisalignmentReportDownloadLinkResponse> {
    return this.rpc.unary(
      StewardshipReportingServiceGenerateMisalignmentReportDownloadLinkDesc,
      GenerateMisalignmentReportDownloadLinkRequest.fromPartial(request),
      metadata,
    );
  }
}

export const StewardshipReportingServiceDesc = {
  serviceName: "tumelo.stewardshipreporting.v1.StewardshipReportingService",
};

export const StewardshipReportingServiceFetchMisalignmentReportDesc: UnaryMethodDefinitionish = {
  methodName: "FetchMisalignmentReport",
  service: StewardshipReportingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchMisalignmentReportRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchMisalignmentReportResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const StewardshipReportingServiceGenerateMisalignmentReportDownloadLinkDesc: UnaryMethodDefinitionish = {
  methodName: "GenerateMisalignmentReportDownloadLink",
  service: StewardshipReportingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GenerateMisalignmentReportDownloadLinkRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GenerateMisalignmentReportDownloadLinkResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
