import { BrowserHeaders } from 'browser-headers'
import { fetchAuthSession } from 'aws-amplify/auth'
import { createContext } from 'react'
import {
  GrpcWebImpl,
  StewardshipReportingService,
  StewardshipReportingServiceClientImpl,
} from '../proto/tumelo/stewardshipreporting/v1/service'
import { Config } from './environment'
import { sampleReport1, sampleReport2 } from './samples/report'

export const MockReportingClient: StewardshipReportingService = {
  FetchMisalignmentReport: async () => ({
    pagination: {
      currentPage: 1,
      pageSize: 10,
      total: 2,
    },
    rows: [sampleReport1(), sampleReport2()],
  }),
  GenerateMisalignmentReportDownloadLink: async () => ({
    links: ['https://www.tumelo.com/', 'https://hedge_app.gitlab.io/mono/'],
  }),
}

export const getMockedOrNotMockedReportingClient = ({
  clientType,
  clientAddress,
}: Config): (() => Promise<StewardshipReportingService>) => {
  if (clientType === 'mock') {
    return async () => MockReportingClient
  }
  return async () => {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString()
    const metadata = new BrowserHeaders({ Authorization: `Bearer ${token}` })
    return new StewardshipReportingServiceClientImpl(new GrpcWebImpl(clientAddress, { metadata }))
  }
}

export const ClientContext = createContext<() => Promise<StewardshipReportingService>>(() => {
  throw new Error('need to set client context value')
})
