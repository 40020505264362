import { libProposal } from '@tumelo/common'
import { ListGeneralMeetingsResponse } from '../../proto/tumelo/stewardshipbff/v1/service'
import { sampleBallot } from './ballot'
import { sampleGeneralMeeting } from './generalMeeting'
import { sampleOrganization } from './organization'
import { sampleProposal } from './proposal'

export const sampleListGeneralMeetings = (
  override?: Partial<ListGeneralMeetingsResponse>
): ListGeneralMeetingsResponse => {
  const proposal1 = sampleProposal({
    name: libProposal.IDToName({ organizationID: 'uuid', generalMeetingID: 'uuid', proposalID: 'proposal1' }),
    title: 'Proposal 1',
  })
  const proposal2 = sampleProposal({
    name: libProposal.IDToName({ organizationID: 'uuid', generalMeetingID: 'uuid', proposalID: 'proposal2' }),
    title: 'Proposal 2',
  })

  const gm1 = sampleGeneralMeeting({ proposalNames: [proposal1.name, proposal2.name] })
  const gm2 = sampleGeneralMeeting({
    name: 'organizations/uuid/generalMeetings/87ec1877-9edd-4a6a-9ffb-19b74a5b0f19',
    proposalNames: [proposal1.name],
    actionDeadline: new Date(),
  })
  const ballot1 = sampleBallot({ name: gm1.name })
  const ballot2 = sampleBallot({ name: gm2.name })
  const org = sampleOrganization()

  return ListGeneralMeetingsResponse.fromPartial({
    meetings: [gm1, gm2],
    organizations: {
      [org.name]: org,
    },
    meetingNamesToBallotNames: {
      [gm1.name]: ballot1.name,
      [gm2.name]: ballot2.name,
    },
    nextPageToken: '',
    ...override,
  })
}
