import { useState, useEffect, useCallback } from 'react';
// Hook
export var useAsync = function (asyncFunction, dependencies, immediate) {
    if (dependencies === void 0) { dependencies = []; }
    if (immediate === void 0) { immediate = true; }
    var _a = useState('idle'), status = _a[0], setStatus = _a[1];
    var _b = useState(undefined), value = _b[0], setValue = _b[1];
    var _c = useState(undefined), error = _c[0], setError = _c[1];
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    var execute = useCallback(function () {
        setStatus('pending');
        setValue(undefined);
        setError(undefined);
        return (asyncFunction()
            .then(function (response) {
            setValue(response);
            setStatus('success');
        })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch(function (error) {
            setError(error);
            setStatus('error');
        }));
    }, dependencies);
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(function () {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);
    return { execute: execute, status: status, value: value, error: error };
};
