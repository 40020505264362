export var prefix = 'organizations/';
export var IDToName = function (_a) {
    var organizationID = _a.organizationID;
    return prefix + organizationID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 2) {
        throw new Error("wrong number of parts in ".concat(name));
    }
    return {
        organizationID: parts[1],
    };
};
