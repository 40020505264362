import { DateTime } from 'luxon';
/**
 * Parse a date and time string into a DateTime object
 * @param isoDateString - ISO date string
 * @param isoTimeString - ISO time string
 * @param timezone - Timezone string
 * @returns DateTime object
 * @example
 * ```ts
 * parseDateTime('2020-01-01', '12:00:00', 'America/New_York')
 * ```
 * @example
 * ```ts
 * parseDateTime('2020-01-01', '12:00:00')
 * ```
 */
export function parseDateTime(isoDateString, isoTimeString, timezone) {
    return DateTime.fromISO("".concat(isoDateString, "T").concat(isoTimeString), { zone: timezone });
}
