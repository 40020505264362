import { libOrganization } from '.';
var prefix = 'generalMeetings/';
export var IDToName = function (_a) {
    var organizationID = _a.organizationID, generalMeetingID = _a.generalMeetingID;
    return libOrganization.IDToName({ organizationID: organizationID }) + '/' + prefix + generalMeetingID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4) {
        throw new Error("bad general meeting name: ".concat(name));
    }
    return {
        organizationID: parts[1],
        generalMeetingID: parts[3],
    };
};
