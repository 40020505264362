import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
export var prefix = 'funds';
export var prefixWithSeparator = prefix + SEPARATOR;
export var wildcardName = prefixWithSeparator + '-';
export var IDToName = function (_a) {
    var fundID = _a.fundID;
    return prefixWithSeparator + fundID;
};
export var validateName = genericValidateName([exactValidator(prefix), uuidValidator()]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split(SEPARATOR);
    return { fundID: parts[1] };
};
