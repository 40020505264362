import React from 'react'
import { screen, Stack, useBreakpoints } from '@tumelo/designsystem'
import styled from 'styled-components'
import { HeaderLogo } from './HeaderLogo'
import { HeaderNavigation } from './HeaderNavigation'
import { HeaderProfile } from './HeaderProfile'
import { HeaderMenuButton } from './HeaderMenuButton'
import { SwitchSchemeContainer } from './SwitchSchemeContainer'

export const Header: React.FC = () => {
  const { isXsmallOnly, isSmallUp, isMediumUp, isLargeUp } = useBreakpoints()

  return (
    <StyledHeader>
      {(isXsmallOnly || isSmallUp) && (
        <>
          <HeaderMenuButton />
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap="0.8rem">
            <HeaderProfile />
            <SwitchSchemeContainer />
          </Stack>
        </>
      )}
      {isMediumUp && (
        <>
          <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap="4.0rem">
            <HeaderLogo />
            <HeaderNavigation />
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap="0.8rem">
            <HeaderProfile />
            <SwitchSchemeContainer />
          </Stack>
        </>
      )}
      {isLargeUp && (
        <>
          <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap="4.0rem">
            <HeaderLogo />
            <HeaderNavigation />
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center" gap="0.8rem">
            <HeaderProfile />
            <SwitchSchemeContainer />
          </Stack>
        </>
      )}
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  background: ${({ theme }) => theme.colors.background.dark};
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  min-height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media ${screen.xsmallOnly} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  @media ${screen.smallUp} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  @media ${screen.mediumUp} {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
  @media ${screen.largeUp} {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
  }
`
