/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
export var protobufPackage = "tumelo.lib.v1";
/**
 * Option describes an option of how an investor may vote: FOR, AGAINST, ABSTAIN, WITHHOLD, ONE_YEAR, TWO_YEARS, THREE_YEARS or NOACTION
 * Voting NOACTION would mean the investor
 * - does not want the voting recommendation to be automatically applied (if they have a voting policy selected)
 * - does not want to submit a (FOR/AGAINST/ABSTAIN/WITHHOLD/ONE_YEAR/TWO_YEARS/THREE_YEARS) vote manually
 */
export var VoteOption;
(function (VoteOption) {
    VoteOption[VoteOption["VOTE_OPTION_INVALID"] = 0] = "VOTE_OPTION_INVALID";
    VoteOption[VoteOption["VOTE_OPTION_FOR"] = 1] = "VOTE_OPTION_FOR";
    VoteOption[VoteOption["VOTE_OPTION_AGAINST"] = 2] = "VOTE_OPTION_AGAINST";
    VoteOption[VoteOption["VOTE_OPTION_ABSTAIN"] = 3] = "VOTE_OPTION_ABSTAIN";
    VoteOption[VoteOption["VOTE_OPTION_WITHHOLD"] = 4] = "VOTE_OPTION_WITHHOLD";
    VoteOption[VoteOption["VOTE_OPTION_NO_ACTION"] = 5] = "VOTE_OPTION_NO_ACTION";
    VoteOption[VoteOption["VOTE_OPTION_ONE_YEAR"] = 6] = "VOTE_OPTION_ONE_YEAR";
    VoteOption[VoteOption["VOTE_OPTION_TWO_YEARS"] = 7] = "VOTE_OPTION_TWO_YEARS";
    VoteOption[VoteOption["VOTE_OPTION_THREE_YEARS"] = 8] = "VOTE_OPTION_THREE_YEARS";
    VoteOption[VoteOption["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VoteOption || (VoteOption = {}));
export function voteOptionFromJSON(object) {
    switch (object) {
        case 0:
        case "VOTE_OPTION_INVALID":
            return VoteOption.VOTE_OPTION_INVALID;
        case 1:
        case "VOTE_OPTION_FOR":
            return VoteOption.VOTE_OPTION_FOR;
        case 2:
        case "VOTE_OPTION_AGAINST":
            return VoteOption.VOTE_OPTION_AGAINST;
        case 3:
        case "VOTE_OPTION_ABSTAIN":
            return VoteOption.VOTE_OPTION_ABSTAIN;
        case 4:
        case "VOTE_OPTION_WITHHOLD":
            return VoteOption.VOTE_OPTION_WITHHOLD;
        case 5:
        case "VOTE_OPTION_NO_ACTION":
            return VoteOption.VOTE_OPTION_NO_ACTION;
        case 6:
        case "VOTE_OPTION_ONE_YEAR":
            return VoteOption.VOTE_OPTION_ONE_YEAR;
        case 7:
        case "VOTE_OPTION_TWO_YEARS":
            return VoteOption.VOTE_OPTION_TWO_YEARS;
        case 8:
        case "VOTE_OPTION_THREE_YEARS":
            return VoteOption.VOTE_OPTION_THREE_YEARS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VoteOption.UNRECOGNIZED;
    }
}
export function voteOptionToJSON(object) {
    switch (object) {
        case VoteOption.VOTE_OPTION_INVALID:
            return "VOTE_OPTION_INVALID";
        case VoteOption.VOTE_OPTION_FOR:
            return "VOTE_OPTION_FOR";
        case VoteOption.VOTE_OPTION_AGAINST:
            return "VOTE_OPTION_AGAINST";
        case VoteOption.VOTE_OPTION_ABSTAIN:
            return "VOTE_OPTION_ABSTAIN";
        case VoteOption.VOTE_OPTION_WITHHOLD:
            return "VOTE_OPTION_WITHHOLD";
        case VoteOption.VOTE_OPTION_NO_ACTION:
            return "VOTE_OPTION_NO_ACTION";
        case VoteOption.VOTE_OPTION_ONE_YEAR:
            return "VOTE_OPTION_ONE_YEAR";
        case VoteOption.VOTE_OPTION_TWO_YEARS:
            return "VOTE_OPTION_TWO_YEARS";
        case VoteOption.VOTE_OPTION_THREE_YEARS:
            return "VOTE_OPTION_THREE_YEARS";
        case VoteOption.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** VoteMethod is the method by which a vote was cast: manual or automatic */
export var VoteMethod;
(function (VoteMethod) {
    VoteMethod[VoteMethod["VOTE_METHOD_INVALID"] = 0] = "VOTE_METHOD_INVALID";
    VoteMethod[VoteMethod["VOTE_METHOD_MANUAL"] = 1] = "VOTE_METHOD_MANUAL";
    VoteMethod[VoteMethod["VOTE_METHOD_AUTOMATIC"] = 2] = "VOTE_METHOD_AUTOMATIC";
    VoteMethod[VoteMethod["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VoteMethod || (VoteMethod = {}));
export function voteMethodFromJSON(object) {
    switch (object) {
        case 0:
        case "VOTE_METHOD_INVALID":
            return VoteMethod.VOTE_METHOD_INVALID;
        case 1:
        case "VOTE_METHOD_MANUAL":
            return VoteMethod.VOTE_METHOD_MANUAL;
        case 2:
        case "VOTE_METHOD_AUTOMATIC":
            return VoteMethod.VOTE_METHOD_AUTOMATIC;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VoteMethod.UNRECOGNIZED;
    }
}
export function voteMethodToJSON(object) {
    switch (object) {
        case VoteMethod.VOTE_METHOD_INVALID:
            return "VOTE_METHOD_INVALID";
        case VoteMethod.VOTE_METHOD_MANUAL:
            return "VOTE_METHOD_MANUAL";
        case VoteMethod.VOTE_METHOD_AUTOMATIC:
            return "VOTE_METHOD_AUTOMATIC";
        case VoteMethod.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
