import { libProvider } from '.';
export var prefix = 'fundUniverses/';
export var IDToName = function (_a) {
    var providerID = _a.providerID, universeID = _a.universeID;
    return libProvider.IDToName({ providerID: providerID }) + '/' + prefix + universeID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4 || !name.startsWith(libProvider.prefix) || !name.includes(prefix)) {
        throw new Error("bad fund universe name: ".concat(name));
    }
    return {
        providerID: parts[1],
        universeID: parts[3],
    };
};
