import { libSplitVoteInstruction } from '.';
export var prefix = '/submissions/';
export var IDToName = function (_a) {
    var splitVoteInstructionID = _a.splitVoteInstructionID, submissionID = _a.submissionID;
    return libSplitVoteInstruction.IDToName({ splitVoteInstructionID: splitVoteInstructionID }) + prefix + submissionID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4) {
        throw new Error("bad split vote instruction submission name: ".concat(name));
    }
    return { splitVoteInstructionID: parts[1], submissionID: parts[3] };
};
