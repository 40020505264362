/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
export var protobufPackage = "tumelo.investor.v1";
function createBaseProposalVotes() {
    return { for: 0, against: 0, abstain: 0, withhold: 0, noVote: 0, oneYear: 0, twoYears: 0, threeYears: 0 };
}
export var ProposalVotes = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.for !== 0) {
            writer.uint32(9).double(message.for);
        }
        if (message.against !== 0) {
            writer.uint32(17).double(message.against);
        }
        if (message.abstain !== 0) {
            writer.uint32(25).double(message.abstain);
        }
        if (message.withhold !== 0) {
            writer.uint32(33).double(message.withhold);
        }
        if (message.noVote !== 0) {
            writer.uint32(41).double(message.noVote);
        }
        if (message.oneYear !== 0) {
            writer.uint32(49).double(message.oneYear);
        }
        if (message.twoYears !== 0) {
            writer.uint32(57).double(message.twoYears);
        }
        if (message.threeYears !== 0) {
            writer.uint32(65).double(message.threeYears);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposalVotes();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 9) {
                        break;
                    }
                    message.for = reader.double();
                    continue;
                case 2:
                    if (tag !== 17) {
                        break;
                    }
                    message.against = reader.double();
                    continue;
                case 3:
                    if (tag !== 25) {
                        break;
                    }
                    message.abstain = reader.double();
                    continue;
                case 4:
                    if (tag !== 33) {
                        break;
                    }
                    message.withhold = reader.double();
                    continue;
                case 5:
                    if (tag !== 41) {
                        break;
                    }
                    message.noVote = reader.double();
                    continue;
                case 6:
                    if (tag !== 49) {
                        break;
                    }
                    message.oneYear = reader.double();
                    continue;
                case 7:
                    if (tag !== 57) {
                        break;
                    }
                    message.twoYears = reader.double();
                    continue;
                case 8:
                    if (tag !== 65) {
                        break;
                    }
                    message.threeYears = reader.double();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            for: isSet(object.for) ? Number(object.for) : 0,
            against: isSet(object.against) ? Number(object.against) : 0,
            abstain: isSet(object.abstain) ? Number(object.abstain) : 0,
            withhold: isSet(object.withhold) ? Number(object.withhold) : 0,
            noVote: isSet(object.noVote) ? Number(object.noVote) : 0,
            oneYear: isSet(object.oneYear) ? Number(object.oneYear) : 0,
            twoYears: isSet(object.twoYears) ? Number(object.twoYears) : 0,
            threeYears: isSet(object.threeYears) ? Number(object.threeYears) : 0,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.for !== 0) {
            obj.for = message.for;
        }
        if (message.against !== 0) {
            obj.against = message.against;
        }
        if (message.abstain !== 0) {
            obj.abstain = message.abstain;
        }
        if (message.withhold !== 0) {
            obj.withhold = message.withhold;
        }
        if (message.noVote !== 0) {
            obj.noVote = message.noVote;
        }
        if (message.oneYear !== 0) {
            obj.oneYear = message.oneYear;
        }
        if (message.twoYears !== 0) {
            obj.twoYears = message.twoYears;
        }
        if (message.threeYears !== 0) {
            obj.threeYears = message.threeYears;
        }
        return obj;
    },
    create: function (base) {
        return ProposalVotes.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var message = createBaseProposalVotes();
        message.for = (_a = object.for) !== null && _a !== void 0 ? _a : 0;
        message.against = (_b = object.against) !== null && _b !== void 0 ? _b : 0;
        message.abstain = (_c = object.abstain) !== null && _c !== void 0 ? _c : 0;
        message.withhold = (_d = object.withhold) !== null && _d !== void 0 ? _d : 0;
        message.noVote = (_e = object.noVote) !== null && _e !== void 0 ? _e : 0;
        message.oneYear = (_f = object.oneYear) !== null && _f !== void 0 ? _f : 0;
        message.twoYears = (_g = object.twoYears) !== null && _g !== void 0 ? _g : 0;
        message.threeYears = (_h = object.threeYears) !== null && _h !== void 0 ? _h : 0;
        return message;
    },
};
function isSet(value) {
    return value !== null && value !== undefined;
}
