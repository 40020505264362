import { Organization as TransparencyOrganization } from '../../proto/tumelo/transparency/v1/organization'
import { Organization } from '../../proto/tumelo/stewardshipbff/v1/organization'

export const sampleTransparencyOrganization = (
  override?: Partial<TransparencyOrganization>
): TransparencyOrganization =>
  TransparencyOrganization.fromPartial({
    name: 'organizations/uuid',
    logoThumbnailUrl:
      'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1589310276/dcxehfjc44ex3a4gldii.png',
    displayTitle: 'Lowes Companies',
    legalTitle: 'Lowes Companies Inc',
    ...override,
  })

export const sampleOrganization = (override?: Partial<Organization>): Organization =>
  Organization.fromPartial({
    name: 'organizations/uuid',
    displayTitle: 'Sample Org',
    logoUrl: 'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1589310276/dcxehfjc44ex3a4gldii.png',
    naicsTitle: 'Manufacturing',
    ...override,
  })
