import moment from 'moment';
// daysAgo returns the number of days ago that a date was compared to the current time
// optional now date for comparison against.
export var daysAgo = function (then, now) {
    if (now === void 0) { now = new Date(); }
    return Math.floor(moment.duration(moment(now).diff(then)).asDays());
};
export var hoursAgo = function (then, now) {
    if (now === void 0) { now = new Date(); }
    return Math.floor(moment.duration(moment(now).diff(then)).asHours());
};
export var daysString = function (then, now) {
    if (now === void 0) { now = new Date(); }
    if (then === undefined)
        return '-';
    var days = daysAgo(then, now);
    if (days == 0) {
        return 'today';
    }
    var unit = Math.abs(days) === 1 ? 'day' : 'days';
    var noun = days > 0 ? 'ago' : 'remaining';
    return "".concat(Math.abs(days).toLocaleString(), " ").concat(unit, " ").concat(noun);
};
export var timeString = function (then, now) {
    if (now === void 0) { now = new Date(); }
    if (!then)
        return 'unknown';
    var days = daysAgo(then, now);
    if (days <= 2) {
        var hours = hoursAgo(then, now);
        var noun = hours < 0 ? 'remaining' : 'ago';
        var unit = Math.abs(hours) === 1 ? 'hour' : 'hours';
        return "".concat(Math.abs(hours), " ").concat(unit, " ").concat(noun);
    }
    return daysString(then, now);
};
