import { libProposal } from '@tumelo/common'
import { GetGeneralMeetingResponse } from '../../proto/tumelo/stewardshipbff/v1/service'
import { sampleBallot } from './ballot'
import { sampleGeneralMeeting } from './generalMeeting'
import { sampleOrganization } from './organization'
import { sampleProposal } from './proposal'

export const sampleGetGeneralMeeting = (override?: Partial<GetGeneralMeetingResponse>): GetGeneralMeetingResponse => {
  const proposal1 = sampleProposal({
    name: libProposal.IDToName({ organizationID: 'uuid', generalMeetingID: 'uuid', proposalID: 'proposal1' }),
    title: 'Proposal 1',
  })
  const proposal2 = sampleProposal({
    name: libProposal.IDToName({ organizationID: 'uuid', generalMeetingID: 'uuid', proposalID: 'proposal2' }),
    title: 'Proposal 2',
  })

  return GetGeneralMeetingResponse.fromPartial({
    meeting: sampleGeneralMeeting({
      proposalNames: [proposal1.name, proposal2.name],
    }),
    organization: sampleOrganization(),
    proposals: [proposal1, proposal2],
    ballots: {
      [proposal1.name]: sampleBallot({ response: undefined, comment: undefined }),
      [proposal2.name]: sampleBallot(),
    },
    ...override,
  })
}
