import React from 'react'
import { useLogger } from '@tumelo/logging'
import { AsyncResult } from '@tumelo/common'

interface Props<T, E> {
  result: AsyncResult<T, E>
  onData: (data: T | undefined) => React.ReactNode
  onError?: (error: E) => React.ReactNode
  onPending?: React.ReactElement
  onIdle?: React.ReactElement
}

export const AsyncUnwrapper = <T, E = string>({
  result: r,
  onData,
  onError,
  onPending,
  onIdle,
}: Props<T, E>): React.ReactElement => {
  const logger = useLogger()
  switch (r.status) {
    case 'pending':
      if (onPending) {
        return onPending
      }
      return <></>
    case 'idle':
      if (onIdle) {
        return onIdle
      }
      return <></>
    case 'error':
      if (!r.error || !onError) {
        logger.logError(r.error)
        return <>Error: {JSON.stringify(r.error)}</>
      }
      return <>{onError(r.error)}</>
    case 'success':
      return <>{onData(r.value)}</>
  }
}
