import React from 'react'
import { Stack, screen, ButtonText, CrossIcon } from '@tumelo/designsystem'
import styled, { useTheme } from 'styled-components'
import { useRoutes } from '../../../application/hooks/useRoutes'
import { HeaderLogo } from './HeaderLogo'
import { HeaderLink } from './HeaderLink'
import { HeaderProfile } from './HeaderProfile'

interface Props {
  isOpen: boolean
  toggleOpen: () => void
}

export const HeaderMenu: React.FC<Props> = ({ isOpen, toggleOpen }) => {
  const { colors } = useTheme()
  const routes = useRoutes()
  return (
    <>
      <StyledOverlay isOpen={isOpen} onClick={toggleOpen} />
      <StyledSidepanel isOpen={isOpen}>
        <StyledMenuStack justifyContent="space-between" gap="4.0rem">
          <Stack alignItems="flex-start" gap="4.0rem">
            <Stack alignItems="flex-start">
              <StyledCloseMenu onClick={toggleOpen}>
                <Stack flexDirection="row" alignItems="center" gap="0.8rem">
                  <CrossIcon fill={colors.white} />
                  <ButtonText color={colors.white} mb="0">
                    Close Menu
                  </ButtonText>
                </Stack>
              </StyledCloseMenu>
              <HeaderLogo />
            </Stack>
            <nav>
              <Stack as="ul" alignItems="flex-start" gap="2.4rem">
                {routes.map(
                  ({ label, href, current, hidden }) =>
                    !hidden && (
                      <li key={label}>
                        <HeaderLink href={href} current={current} label={label} />
                      </li>
                    )
                )}
              </Stack>
            </nav>
          </Stack>
          <Stack justifyContent="flex-end" alignItems="flex-start" gap="1.6rem">
            <HeaderProfile />
          </Stack>
        </StyledMenuStack>
      </StyledSidepanel>
    </>
  )
}

interface SidepanelProps {
  isOpen?: boolean
}

const StyledSidepanel = styled.div<SidepanelProps>`
  position: fixed;
  z-index: 1500;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.background.dark};
  height: 100%;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  @media ${screen.xsmallOnly} {
    padding: 1.6rem;
    width: ${({ isOpen }) => (isOpen ? '24.6rem' : '0')};
  }
  @media ${screen.smallUp} {
    padding: 2.4rem;
    width: ${({ isOpen }) => (isOpen ? '32rem' : '0')};
  }
`

const StyledOverlay = styled.div<SidepanelProps>`
  display: flex;
  z-index: 1300;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  backdrop-filter: brightness(0.6);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

const StyledMenuStack = styled(Stack)`
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledCloseMenu = styled.div`
  cursor: pointer;
  &:hover {
    p {
      text-decoration: underline;
    }
  }
`
