import { AuthError, fetchUserAttributes, signOut } from 'aws-amplify/auth'
import React from 'react'
import { Scheme } from '../proto/tumelo/stewardshipbff/v1/scheme'
import { getConfig } from './environment'
import { getMockedOrNotMockedClient } from './client'
import { sampleCognitoUser } from './samples/cognitoUser'
import { User } from './domain/user'

export interface UserCtx {
  /**
   * getUserDetails returns undefined in the case that data cannot be fetched.
   */
  getUserDetails: () => Promise<User | undefined>
  signOut: () => Promise<void>
}

export const AmplifyUserCtx = (): UserCtx => {
  return {
    getUserDetails: async () => {
      // The majority of the user data resides in cognito...
      try {
        const { email, given_name, family_name, sub } = await fetchUserAttributes()
        if (!email || !given_name || !family_name || !sub) {
          throw new Error(`user attribute missing: ${JSON.stringify({ email, given_name, family_name, sub })}`)
        }

        // ...but we need to get the user id from stewardship-bff
        const getClient = getMockedOrNotMockedClient(getConfig())
        const client = await getClient()
        const user = await client.GetUser({})

        return {
          id: user.name.split('/')[1],
          email,
          firstName: given_name,
          lastName: family_name,
          userName: sub,
        }
      } catch (err) {
        if (err instanceof AuthError) {
          // We call this at the top level of the app so the user might not be authenticated
          if (err.name !== 'UserUnAuthenticatedException') {
            throw err
          }
          return undefined
        }
      }
    },
    signOut: async () => await signOut(),
  }
}

export const sampleUserContext = (): UserCtx => {
  return {
    getUserDetails: async () => sampleCognitoUser(),
    signOut: async () => undefined,
  }
}

export const UserContext = React.createContext<UserCtx>(sampleUserContext())

export interface MappedPrivileges {
  canGetSchemes: Scheme[]
}

export interface PrivilegesCtx {
  mappedPrivileges: MappedPrivileges
}

export const blankPrivilegesCtx: PrivilegesCtx = {
  mappedPrivileges: {
    canGetSchemes: [],
  },
}

export const PrivilegesContext = React.createContext<PrivilegesCtx>(blankPrivilegesCtx)
