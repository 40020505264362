import { libFundManager } from '@tumelo/common'
import { MisalignmentReportRow } from '../../proto/tumelo/stewardshipreporting/v1/misalignment_report'
import { Option } from '../../proto/tumelo/stewardshipbff/v1/option'
import { sampleVotingPolicy } from './votingPolicy'
import { sampleFundManager } from './fundManager'
import { sampleFundVote } from './fundVote'

const fundManager1 = sampleFundManager()
const fundManager2 = sampleFundManager({
  id: '89be2c76-b5a3-4319-879d-1bcf10cad675',
  title: 'JP Morgan Asset Management',
  websiteUrl: 'https://am.jpmorgan.com/hk/en/asset-management/per/',
})

const votingPolicy = sampleVotingPolicy()

export const sampleReport1 = (override?: Partial<MisalignmentReportRow>): MisalignmentReportRow =>
  MisalignmentReportRow.fromPartial({
    organizationTitle: 'Alphabet',
    themes: [
      {
        code: 'BE',
        title: 'Other Election',
      },
      {
        code: 'OG',
        title: 'Other Governance',
      },
    ],
    proposalName:
      'organizations/9747caea-e97f-4bed-829a-a74eaa6e275c/generalMeetings/00cf8450-a5f5-4fb3-ae95-6f6acb4c3142/proposals/8dbe9a24-9fd8-4747-bf51-a72e4d70842d',
    proposalTitle: 'To Elect Sergey Brin',
    gmResult: Option.OPTION_FOR,
    investorVoteInstruction: Option.OPTION_FOR,
    generalMeetingDate: new Date(2022, 12, 15, 0, 0, 0, 0),
    policyRecommendation: {
      name: 'recommendation 1',
      instruction: Option.OPTION_FOR,
      title: votingPolicy.title,
    },
    fundManagerVotes: [
      {
        fundManagerName: libFundManager.IDToName({ fundManagerID: fundManager1.id }), // unique
        fundManagerTitle: fundManager1.title,
        votes: [
          sampleFundVote({ fundVariantTitle: 'Fund Variant A', instruction: Option.OPTION_FOR, rationale: 'We agree' }),
          sampleFundVote({
            fundVariantTitle: 'Fund Variant B',
            instruction: Option.OPTION_AGAINST,
            rationale: "We don't agree here though",
          }),
        ],
      },
      {
        fundManagerName: libFundManager.IDToName({ fundManagerID: fundManager2.id }),
        fundManagerTitle: fundManager2.title,
        votes: [
          sampleFundVote({ fundVariantTitle: 'Fund Variant C', instruction: Option.OPTION_ABSTAIN, rationale: '' }),
        ],
      },
    ],
    ...override,
  })

export const sampleReport2 = (override?: Partial<MisalignmentReportRow>): MisalignmentReportRow =>
  MisalignmentReportRow.fromPartial({
    organizationTitle: 'Apple',
    themes: [
      {
        code: 'OG',
        title: 'Other Governance',
      },
    ],
    proposalName:
      'organizations/98d4924b-c2aa-43a9-a4c6-e274d4b6ed5a/generalMeetings/30380cd5-9f01-4322-962b-96499375bdc6/proposals/afc956ac-e845-4f95-a4bc-f47f01a7da83',
    proposalTitle: 'A shareholder proposal on Board policy for communication with shareholder proponents',
    gmResult: Option.OPTION_AGAINST,
    investorVoteInstruction: Option.OPTION_FOR,
    generalMeetingDate: new Date(2022, 12, 15, 0, 0, 0, 0),
    policyRecommendation: {
      name: 'recommendation 1',
      instruction: Option.OPTION_FOR,
      title: votingPolicy.title,
    },
    fundManagerVotes: [
      {
        fundManagerName: libFundManager.IDToName({ fundManagerID: fundManager1.id }),
        fundManagerTitle: fundManager1.title,
        votes: [
          sampleFundVote({ fundVariantTitle: 'Fund Variant A', instruction: Option.OPTION_FOR, rationale: 'We agree' }),
          sampleFundVote({
            fundVariantTitle: 'Fund Variant B',
            instruction: Option.OPTION_AGAINST,
            rationale: "We don't agree here though",
          }),
        ],
      },
    ],
    ...override,
  })
