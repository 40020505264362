/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
export var protobufPackage = "tumelo.fundmanager.v1";
/** Requirement Type is the type of data requirement this is */
export var DataRequirement_RequirementType;
(function (DataRequirement_RequirementType) {
    DataRequirement_RequirementType[DataRequirement_RequirementType["REQUIREMENT_TYPE_INVALID"] = 0] = "REQUIREMENT_TYPE_INVALID";
    DataRequirement_RequirementType[DataRequirement_RequirementType["UNDERLYING_HOLDINGS"] = 1] = "UNDERLYING_HOLDINGS";
    DataRequirement_RequirementType[DataRequirement_RequirementType["FUND_MANAGER_VOTES"] = 2] = "FUND_MANAGER_VOTES";
    DataRequirement_RequirementType[DataRequirement_RequirementType["FUND_MANAGER_SIGNIFICANT_VOTES"] = 3] = "FUND_MANAGER_SIGNIFICANT_VOTES";
    DataRequirement_RequirementType[DataRequirement_RequirementType["PLSA_STATISTICS"] = 4] = "PLSA_STATISTICS";
    DataRequirement_RequirementType[DataRequirement_RequirementType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(DataRequirement_RequirementType || (DataRequirement_RequirementType = {}));
export function dataRequirement_RequirementTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "REQUIREMENT_TYPE_INVALID":
            return DataRequirement_RequirementType.REQUIREMENT_TYPE_INVALID;
        case 1:
        case "UNDERLYING_HOLDINGS":
            return DataRequirement_RequirementType.UNDERLYING_HOLDINGS;
        case 2:
        case "FUND_MANAGER_VOTES":
            return DataRequirement_RequirementType.FUND_MANAGER_VOTES;
        case 3:
        case "FUND_MANAGER_SIGNIFICANT_VOTES":
            return DataRequirement_RequirementType.FUND_MANAGER_SIGNIFICANT_VOTES;
        case 4:
        case "PLSA_STATISTICS":
            return DataRequirement_RequirementType.PLSA_STATISTICS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DataRequirement_RequirementType.UNRECOGNIZED;
    }
}
export function dataRequirement_RequirementTypeToJSON(object) {
    switch (object) {
        case DataRequirement_RequirementType.REQUIREMENT_TYPE_INVALID:
            return "REQUIREMENT_TYPE_INVALID";
        case DataRequirement_RequirementType.UNDERLYING_HOLDINGS:
            return "UNDERLYING_HOLDINGS";
        case DataRequirement_RequirementType.FUND_MANAGER_VOTES:
            return "FUND_MANAGER_VOTES";
        case DataRequirement_RequirementType.FUND_MANAGER_SIGNIFICANT_VOTES:
            return "FUND_MANAGER_SIGNIFICANT_VOTES";
        case DataRequirement_RequirementType.PLSA_STATISTICS:
            return "PLSA_STATISTICS";
        case DataRequirement_RequirementType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** Status is the status of the data requirement */
export var DataRequirement_Status;
(function (DataRequirement_Status) {
    DataRequirement_Status[DataRequirement_Status["STATUS_INVALID"] = 0] = "STATUS_INVALID";
    DataRequirement_Status[DataRequirement_Status["STATUS_UNAVAILABLE"] = 1] = "STATUS_UNAVAILABLE";
    DataRequirement_Status[DataRequirement_Status["STATUS_REQUESTED"] = 2] = "STATUS_REQUESTED";
    DataRequirement_Status[DataRequirement_Status["STATUS_RECEIVED"] = 4] = "STATUS_RECEIVED";
    DataRequirement_Status[DataRequirement_Status["STATUS_AVAILABLE"] = 3] = "STATUS_AVAILABLE";
    DataRequirement_Status[DataRequirement_Status["STATUS_REFUSED"] = 5] = "STATUS_REFUSED";
    DataRequirement_Status[DataRequirement_Status["STATUS_PENDING_OUTREACH"] = 6] = "STATUS_PENDING_OUTREACH";
    DataRequirement_Status[DataRequirement_Status["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(DataRequirement_Status || (DataRequirement_Status = {}));
export function dataRequirement_StatusFromJSON(object) {
    switch (object) {
        case 0:
        case "STATUS_INVALID":
            return DataRequirement_Status.STATUS_INVALID;
        case 1:
        case "STATUS_UNAVAILABLE":
            return DataRequirement_Status.STATUS_UNAVAILABLE;
        case 2:
        case "STATUS_REQUESTED":
            return DataRequirement_Status.STATUS_REQUESTED;
        case 4:
        case "STATUS_RECEIVED":
            return DataRequirement_Status.STATUS_RECEIVED;
        case 3:
        case "STATUS_AVAILABLE":
            return DataRequirement_Status.STATUS_AVAILABLE;
        case 5:
        case "STATUS_REFUSED":
            return DataRequirement_Status.STATUS_REFUSED;
        case 6:
        case "STATUS_PENDING_OUTREACH":
            return DataRequirement_Status.STATUS_PENDING_OUTREACH;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DataRequirement_Status.UNRECOGNIZED;
    }
}
export function dataRequirement_StatusToJSON(object) {
    switch (object) {
        case DataRequirement_Status.STATUS_INVALID:
            return "STATUS_INVALID";
        case DataRequirement_Status.STATUS_UNAVAILABLE:
            return "STATUS_UNAVAILABLE";
        case DataRequirement_Status.STATUS_REQUESTED:
            return "STATUS_REQUESTED";
        case DataRequirement_Status.STATUS_RECEIVED:
            return "STATUS_RECEIVED";
        case DataRequirement_Status.STATUS_AVAILABLE:
            return "STATUS_AVAILABLE";
        case DataRequirement_Status.STATUS_REFUSED:
            return "STATUS_REFUSED";
        case DataRequirement_Status.STATUS_PENDING_OUTREACH:
            return "STATUS_PENDING_OUTREACH";
        case DataRequirement_Status.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseDataRequirement() {
    return {
        name: "",
        createTime: undefined,
        updateTime: undefined,
        requirementType: 0,
        targetDate: undefined,
        status: 0,
    };
}
export var DataRequirement = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).ldelim();
        }
        if (message.updateTime !== undefined) {
            Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.requirementType !== 0) {
            writer.uint32(32).int32(message.requirementType);
        }
        if (message.targetDate !== undefined) {
            Timestamp.encode(toTimestamp(message.targetDate), writer.uint32(42).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(48).int32(message.status);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseDataRequirement();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }
                    message.requirementType = reader.int32();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.targetDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 6:
                    if (tag !== 48) {
                        break;
                    }
                    message.status = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
            requirementType: isSet(object.requirementType)
                ? dataRequirement_RequirementTypeFromJSON(object.requirementType)
                : 0,
            targetDate: isSet(object.targetDate) ? fromJsonTimestamp(object.targetDate) : undefined,
            status: isSet(object.status) ? dataRequirement_StatusFromJSON(object.status) : 0,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.updateTime !== undefined) {
            obj.updateTime = message.updateTime.toISOString();
        }
        if (message.requirementType !== 0) {
            obj.requirementType = dataRequirement_RequirementTypeToJSON(message.requirementType);
        }
        if (message.targetDate !== undefined) {
            obj.targetDate = message.targetDate.toISOString();
        }
        if (message.status !== 0) {
            obj.status = dataRequirement_StatusToJSON(message.status);
        }
        return obj;
    },
    create: function (base) {
        return DataRequirement.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f;
        var message = createBaseDataRequirement();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.createTime = (_b = object.createTime) !== null && _b !== void 0 ? _b : undefined;
        message.updateTime = (_c = object.updateTime) !== null && _c !== void 0 ? _c : undefined;
        message.requirementType = (_d = object.requirementType) !== null && _d !== void 0 ? _d : 0;
        message.targetDate = (_e = object.targetDate) !== null && _e !== void 0 ? _e : undefined;
        message.status = (_f = object.status) !== null && _f !== void 0 ? _f : 0;
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
