import { VoteMethod, VoteOption } from '../proto/tumelo/lib/v1/voting';
export var voteOptionToString = function (voteOption) {
    switch (voteOption) {
        case VoteOption.VOTE_OPTION_INVALID:
            return 'invalid';
        case VoteOption.VOTE_OPTION_FOR:
            return 'For';
        case VoteOption.VOTE_OPTION_AGAINST:
            return 'Against';
        case VoteOption.VOTE_OPTION_ABSTAIN:
            return 'Abstain';
        case VoteOption.VOTE_OPTION_WITHHOLD:
            return 'Withhold';
        case VoteOption.VOTE_OPTION_NO_ACTION:
            return 'No Action';
        case VoteOption.VOTE_OPTION_ONE_YEAR:
            return 'One Year';
        case VoteOption.VOTE_OPTION_TWO_YEARS:
            return 'Two Years';
        case VoteOption.VOTE_OPTION_THREE_YEARS:
            return 'Three Years';
        default:
            return 'unknown';
    }
};
export var voteMethodToString = function (voteMethod) {
    switch (voteMethod) {
        case VoteMethod.VOTE_METHOD_INVALID:
            return 'invalid';
        case VoteMethod.VOTE_METHOD_AUTOMATIC:
            return 'Automatic';
        case VoteMethod.VOTE_METHOD_MANUAL:
            return 'Manual';
        default:
            return 'unknown';
    }
};
