/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** Reference to an instrument - either be a Public or Custom Security ID. */
export interface InstrumentReference {
  /**
   * The Public Security ID Type / Custom Security ID Alias.
   * There are a set of valid public security ID types; custom security ID aliases are free text.
   */
  idType: string;
  /** The Security ID Value. */
  idValue: string;
}

/** CompositeInstrument represents a Fund. */
export interface CompositeInstrument {
  /**
   * Unique identifier (Public / Custom Security ID) for the Composite Instrument.
   * Valid formats:
   * - 'securityIDTypes/{type}/securityIDs/{value}'
   * - 'fundManagers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   * - 'providers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   */
  id: string;
  /** The human-readable title of the Composite Instrument. */
  title: string;
  /** The Fund Manager name (in the format 'fundManagers/{uuid}'). */
  fundManagerName: string;
  /** The InstrumentReference / Security ID which identifies this Composite Instrument. */
  instrumentReference:
    | InstrumentReference
    | undefined;
  /**
   * Whether this fund reference is resolved (we know its composition).
   *
   * This value is determined by the 'ValidAt' property which is attached to the Composite
   * Instruments returned from the API. If 'ValidAt' is nil, we don't currently know the
   * composition of the fund.
   */
  isResolved: boolean;
  /**
   * Map of schemes. The key is the scheme name, and the value is whether this composite instrument is flagged as default for that scheme.
   * Scheme names are in the format 'habitats/{uuid}/schemes/{uuid}'.
   */
  schemes: { [key: string]: boolean };
}

export interface CompositeInstrument_SchemesEntry {
  key: string;
  value: boolean;
}

/** Represents a connection between two Composite Instruments. */
export interface CompositeTreeEdge {
  /**
   * The parent Composite Instrument ID.
   * Valid formats:
   * - 'securityIDTypes/{type}/securityIDs/{value}'
   * - 'fundManagers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   * - 'providers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   */
  from: string;
  /**
   * The child Composite Instrument ID.
   * Valid formats:
   * - 'securityIDTypes/{type}/securityIDs/{value}'
   * - 'fundManagers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   * - 'providers/{UUID}/securityIDTypes/{type}/securityIDs/{value}'
   */
  to: string;
  /** The weight of the connection. */
  weight: number;
}

function createBaseInstrumentReference(): InstrumentReference {
  return { idType: "", idValue: "" };
}

export const InstrumentReference = {
  encode(message: InstrumentReference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.idType !== "") {
      writer.uint32(10).string(message.idType);
    }
    if (message.idValue !== "") {
      writer.uint32(18).string(message.idValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentReference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentReference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.idType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.idValue = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentReference {
    return {
      idType: isSet(object.idType) ? String(object.idType) : "",
      idValue: isSet(object.idValue) ? String(object.idValue) : "",
    };
  },

  toJSON(message: InstrumentReference): unknown {
    const obj: any = {};
    if (message.idType !== "") {
      obj.idType = message.idType;
    }
    if (message.idValue !== "") {
      obj.idValue = message.idValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentReference>, I>>(base?: I): InstrumentReference {
    return InstrumentReference.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentReference>, I>>(object: I): InstrumentReference {
    const message = createBaseInstrumentReference();
    message.idType = object.idType ?? "";
    message.idValue = object.idValue ?? "";
    return message;
  },
};

function createBaseCompositeInstrument(): CompositeInstrument {
  return { id: "", title: "", fundManagerName: "", instrumentReference: undefined, isResolved: false, schemes: {} };
}

export const CompositeInstrument = {
  encode(message: CompositeInstrument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.fundManagerName !== "") {
      writer.uint32(26).string(message.fundManagerName);
    }
    if (message.instrumentReference !== undefined) {
      InstrumentReference.encode(message.instrumentReference, writer.uint32(34).fork()).ldelim();
    }
    if (message.isResolved === true) {
      writer.uint32(40).bool(message.isResolved);
    }
    Object.entries(message.schemes).forEach(([key, value]) => {
      CompositeInstrument_SchemesEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompositeInstrument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompositeInstrument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fundManagerName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.instrumentReference = InstrumentReference.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isResolved = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = CompositeInstrument_SchemesEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.schemes[entry6.key] = entry6.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompositeInstrument {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      fundManagerName: isSet(object.fundManagerName) ? String(object.fundManagerName) : "",
      instrumentReference: isSet(object.instrumentReference)
        ? InstrumentReference.fromJSON(object.instrumentReference)
        : undefined,
      isResolved: isSet(object.isResolved) ? Boolean(object.isResolved) : false,
      schemes: isObject(object.schemes)
        ? Object.entries(object.schemes).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
          acc[key] = Boolean(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: CompositeInstrument): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.fundManagerName !== "") {
      obj.fundManagerName = message.fundManagerName;
    }
    if (message.instrumentReference !== undefined) {
      obj.instrumentReference = InstrumentReference.toJSON(message.instrumentReference);
    }
    if (message.isResolved === true) {
      obj.isResolved = message.isResolved;
    }
    if (message.schemes) {
      const entries = Object.entries(message.schemes);
      if (entries.length > 0) {
        obj.schemes = {};
        entries.forEach(([k, v]) => {
          obj.schemes[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompositeInstrument>, I>>(base?: I): CompositeInstrument {
    return CompositeInstrument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompositeInstrument>, I>>(object: I): CompositeInstrument {
    const message = createBaseCompositeInstrument();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.fundManagerName = object.fundManagerName ?? "";
    message.instrumentReference = (object.instrumentReference !== undefined && object.instrumentReference !== null)
      ? InstrumentReference.fromPartial(object.instrumentReference)
      : undefined;
    message.isResolved = object.isResolved ?? false;
    message.schemes = Object.entries(object.schemes ?? {}).reduce<{ [key: string]: boolean }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Boolean(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCompositeInstrument_SchemesEntry(): CompositeInstrument_SchemesEntry {
  return { key: "", value: false };
}

export const CompositeInstrument_SchemesEntry = {
  encode(message: CompositeInstrument_SchemesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompositeInstrument_SchemesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompositeInstrument_SchemesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompositeInstrument_SchemesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: CompositeInstrument_SchemesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value === true) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompositeInstrument_SchemesEntry>, I>>(
    base?: I,
  ): CompositeInstrument_SchemesEntry {
    return CompositeInstrument_SchemesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompositeInstrument_SchemesEntry>, I>>(
    object: I,
  ): CompositeInstrument_SchemesEntry {
    const message = createBaseCompositeInstrument_SchemesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseCompositeTreeEdge(): CompositeTreeEdge {
  return { from: "", to: "", weight: 0 };
}

export const CompositeTreeEdge = {
  encode(message: CompositeTreeEdge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== "") {
      writer.uint32(10).string(message.from);
    }
    if (message.to !== "") {
      writer.uint32(18).string(message.to);
    }
    if (message.weight !== 0) {
      writer.uint32(29).float(message.weight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompositeTreeEdge {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompositeTreeEdge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.weight = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompositeTreeEdge {
    return {
      from: isSet(object.from) ? String(object.from) : "",
      to: isSet(object.to) ? String(object.to) : "",
      weight: isSet(object.weight) ? Number(object.weight) : 0,
    };
  },

  toJSON(message: CompositeTreeEdge): unknown {
    const obj: any = {};
    if (message.from !== "") {
      obj.from = message.from;
    }
    if (message.to !== "") {
      obj.to = message.to;
    }
    if (message.weight !== 0) {
      obj.weight = message.weight;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompositeTreeEdge>, I>>(base?: I): CompositeTreeEdge {
    return CompositeTreeEdge.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompositeTreeEdge>, I>>(object: I): CompositeTreeEdge {
    const message = createBaseCompositeTreeEdge();
    message.from = object.from ?? "";
    message.to = object.to ?? "";
    message.weight = object.weight ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
