/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipreporting.v1";

export interface PaginationRequest {
  /**
   * OPTIONAL page_size specifies the number of results which should be displayed per page.
   *
   * Default: 50
   */
  pageSize: number;
  /**
   * OPTIONAL page_number specifies what page number to get the results back for. For the first page set page number as `1`.
   *
   * Default: 1
   */
  pageNumber: number;
}

export interface PaginationResponse {
  /** page_size specifies the number of results being returned for this page. */
  pageSize: number;
  /** total specifies the total number of results that match the current filters. */
  total: number;
  /** current_page specifies the number of the page currently being displayed. */
  currentPage: number;
}

function createBasePaginationRequest(): PaginationRequest {
  return { pageSize: 0, pageNumber: 0 };
}

export const PaginationRequest = {
  encode(message: PaginationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageNumber !== 0) {
      writer.uint32(16).int32(message.pageNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaginationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaginationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaginationRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageNumber: isSet(object.pageNumber) ? Number(object.pageNumber) : 0,
    };
  },

  toJSON(message: PaginationRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaginationRequest>, I>>(base?: I): PaginationRequest {
    return PaginationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaginationRequest>, I>>(object: I): PaginationRequest {
    const message = createBasePaginationRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageNumber = object.pageNumber ?? 0;
    return message;
  },
};

function createBasePaginationResponse(): PaginationResponse {
  return { pageSize: 0, total: 0, currentPage: 0 };
}

export const PaginationResponse = {
  encode(message: PaginationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.total !== 0) {
      writer.uint32(16).int32(message.total);
    }
    if (message.currentPage !== 0) {
      writer.uint32(24).int32(message.currentPage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaginationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaginationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.total = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currentPage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaginationResponse {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      total: isSet(object.total) ? Number(object.total) : 0,
      currentPage: isSet(object.currentPage) ? Number(object.currentPage) : 0,
    };
  },

  toJSON(message: PaginationResponse): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.total !== 0) {
      obj.total = Math.round(message.total);
    }
    if (message.currentPage !== 0) {
      obj.currentPage = Math.round(message.currentPage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PaginationResponse>, I>>(base?: I): PaginationResponse {
    return PaginationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaginationResponse>, I>>(object: I): PaginationResponse {
    const message = createBasePaginationResponse();
    message.pageSize = object.pageSize ?? 0;
    message.total = object.total ?? 0;
    message.currentPage = object.currentPage ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
