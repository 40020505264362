/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.transparency.v1";

/**
 * OrganizationBio is a short description of the organization's activities. It contains the description as well as a
 * source which is both an url, and an encoded enum.
 */
export interface OrganizationBio {
  /**
   * REQUIRED description is a description of the bio that has a maximum length of 280 characters, which at the time
   * of defining is the length of a tweet.
   */
  description: string;
  /** REQUIRED source_url is the source of the description. It must currently be from Wikipedia. */
  sourceUrl: string;
  /** REQUIRED source is an encoded reference to the source. Currently, it must be Wikipedia. */
  source: OrganizationBio_Source;
}

/** Source is an encoded source for display purposes. */
export enum OrganizationBio_Source {
  ORGANIZATION_BIO_SOURCE_INVALID = 0,
  ORGANIZATION_BIO_SOURCE_WIKIPEDIA = 1,
  ORGANIZATION_BIO_SOURCE_TUMELO = 2,
  UNRECOGNIZED = -1,
}

export function organizationBio_SourceFromJSON(object: any): OrganizationBio_Source {
  switch (object) {
    case 0:
    case "ORGANIZATION_BIO_SOURCE_INVALID":
      return OrganizationBio_Source.ORGANIZATION_BIO_SOURCE_INVALID;
    case 1:
    case "ORGANIZATION_BIO_SOURCE_WIKIPEDIA":
      return OrganizationBio_Source.ORGANIZATION_BIO_SOURCE_WIKIPEDIA;
    case 2:
    case "ORGANIZATION_BIO_SOURCE_TUMELO":
      return OrganizationBio_Source.ORGANIZATION_BIO_SOURCE_TUMELO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrganizationBio_Source.UNRECOGNIZED;
  }
}

export function organizationBio_SourceToJSON(object: OrganizationBio_Source): string {
  switch (object) {
    case OrganizationBio_Source.ORGANIZATION_BIO_SOURCE_INVALID:
      return "ORGANIZATION_BIO_SOURCE_INVALID";
    case OrganizationBio_Source.ORGANIZATION_BIO_SOURCE_WIKIPEDIA:
      return "ORGANIZATION_BIO_SOURCE_WIKIPEDIA";
    case OrganizationBio_Source.ORGANIZATION_BIO_SOURCE_TUMELO:
      return "ORGANIZATION_BIO_SOURCE_TUMELO";
    case OrganizationBio_Source.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseOrganizationBio(): OrganizationBio {
  return { description: "", sourceUrl: "", source: 0 };
}

export const OrganizationBio = {
  encode(message: OrganizationBio, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.sourceUrl !== "") {
      writer.uint32(18).string(message.sourceUrl);
    }
    if (message.source !== 0) {
      writer.uint32(24).int32(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationBio {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationBio();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sourceUrl = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationBio {
    return {
      description: isSet(object.description) ? String(object.description) : "",
      sourceUrl: isSet(object.sourceUrl) ? String(object.sourceUrl) : "",
      source: isSet(object.source) ? organizationBio_SourceFromJSON(object.source) : 0,
    };
  },

  toJSON(message: OrganizationBio): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.sourceUrl !== "") {
      obj.sourceUrl = message.sourceUrl;
    }
    if (message.source !== 0) {
      obj.source = organizationBio_SourceToJSON(message.source);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationBio>, I>>(base?: I): OrganizationBio {
    return OrganizationBio.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrganizationBio>, I>>(object: I): OrganizationBio {
    const message = createBaseOrganizationBio();
    message.description = object.description ?? "";
    message.sourceUrl = object.sourceUrl ?? "";
    message.source = object.source ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
