import { libFund, libFundVariant } from '.';
// Order on save disagrees with eslint
// eslint-disable-next-line import/order
import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
export var prefix = 'votes';
var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var fundID = _a.fundID, variantID = _a.variantID, voteID = _a.voteID;
    return libFundVariant.IDToName({ fundID: fundID, variantID: variantID }) + SEPARATOR + prefixWithSeparator + voteID;
};
export var validateName = genericValidateName([
    exactValidator(libFund.prefix),
    uuidValidator(),
    exactValidator(libFundVariant.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split(SEPARATOR);
    return {
        fundID: parts[1],
        variantID: parts[3],
        voteID: parts[5],
    };
};
export var fundVariantNameFromFundVariantVoteName = function (name) {
    var _a = nameToID(name), fundID = _a.fundID, variantID = _a.variantID;
    return libFundVariant.IDToName({ fundID: fundID, variantID: variantID });
};
