import { prefixWithSeparator as habitatPrefix } from './habitat';
export var prefix = "schemes/";
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, schemeID = _a.schemeID;
    return habitatPrefix + habitatID + '/' + prefix + schemeID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4) {
        throw new Error("bad scheme name: ".concat(name));
    }
    return {
        habitatID: parts[1],
        schemeID: parts[3],
    };
};
