import { UserData, LoggerService } from './Logger'

let user: UserData = {}

export const LoggerServiceConsole: LoggerService = {
  logError: (e, context) =>
    // eslint-disable-next-line no-console
    console.error(e, {
      ...context,
      user: {
        ...user,
      },
    }),
  setUserData: (u) => (user = u),
}
