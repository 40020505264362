import { useState } from 'react';
import { useLogger } from '@tumelo/logging';
// based off of https://usehooks.com/useLocalStorage/
export var useLocalStorage = function (key, defaultValue) {
    var _a = useState(function () {
        if (typeof window === 'undefined') {
            return defaultValue;
        }
        try {
            var item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : defaultValue;
        }
        catch (error) {
            logger.logError(error, { extra: { where: 'useLocalStorage' } });
            return defaultValue;
        }
    }), storedValue = _a[0], setStoredValue = _a[1];
    var logger = useLogger();
    var setValue = function (value) {
        try {
            // Allow value to be a function so we have same API as useState
            var valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        }
        catch (error) {
            logger.logError(error, { extra: { where: 'useLocalStorage' } });
        }
    };
    return [storedValue, setValue];
};
