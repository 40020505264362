import { BrowserHeaders } from 'browser-headers'
import { fetchAuthSession } from 'aws-amplify/auth'
import { createContext } from 'react'
import {
  FetchAnonymizedVoteCommentsBySchemeResponse,
  FetchAnonymizedVotePreferencesBySchemeResponse,
  GrpcWebImpl,
  StewardshipBff,
  StewardshipBffClientImpl,
  GetFundManagerResponse,
  ListGeneralMeetingsResponse,
} from '../proto/tumelo/stewardshipbff/v1/service'
import { Option } from '../proto/tumelo/stewardshipbff/v1/option'
import { sampleTransparencyGeneralMeeting, sampleGeneralMeeting } from './samples/generalMeeting'
import { sampleFundManagerPolicy } from './samples/fundManagerPolicy'
import { sampleTransparencyOrganization } from './samples/organization'
import { Config } from './environment'
import { sampleFundManager } from './samples/fundManager'
import { sampleTransparencyProposal } from './samples/proposal'
import { sampleGroupedVoteComments, samplePollVoteComments } from './samples/voteComments'
import { sampleGroupedVotePreferences, samplePollVotePreferences } from './samples/votePreferences'
import { samplePoll } from './samples/poll'
import { samplePrivileges } from './samples/privileges'
import { sampleIDPUser } from './samples/idpUser'
import { sampleScheme } from './samples/scheme'
import { sampleFeatureFlag } from './samples/featureFlag'
import { sampleFetchInvestments } from './samples/fetchInvestments'
import { sampleVotingPolicy } from './samples/votingPolicy'
import { sampleListGeneralMeetings } from './samples/listGeneralMeetings'
import { sampleGetGeneralMeeting } from './samples/getGeneralMeeting'
import { sampleListQuarterlyReportsResponse } from './samples/quarterlyReport'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type FilterNotStartingWith<Set, Needle extends string> = Set extends `${Needle}${infer _}` ? never : Set

// Remove non admin endpoints from client type
type FilteredKeys = FilterNotStartingWith<keyof StewardshipBff, 'Admin'>
type NonAdminStewardshipBff = Pick<StewardshipBff, FilteredKeys>

const schemeName2 = 'habitats/ca37f743-90e2-4f44-bee9-cd004236fdf9/schemes/83c58afa-78c5-4573-aa4d-98270834d603'
const schemeName3 = 'habitats/ca37f743-90e2-4f44-bee9-cd004236fdf9/schemes/83c58afa-78c5-4573-aa4d-98270834d604'

export const MockClient: NonAdminStewardshipBff = {
  ListFundManagers: async () => ({
    fundManagers: [sampleFundManager(), sampleFundManager({ title: 'Manager Mcmanagerface', id: 'new1' })],
    nextPageToken: '',
  }),

  GetFundManager: async () =>
    GetFundManagerResponse.fromPartial({
      fundManager: sampleFundManager(),
      compositeInstruments: Object.values(sampleFetchInvestments().compositeInstruments),
      policies: [sampleFundManagerPolicy()],
    }),

  FetchAnonymizedVotePreferencesByScheme: async () => {
    const vp: FetchAnonymizedVotePreferencesBySchemeResponse = {
      readTime: new Date(),
      summary: [samplePollVotePreferences()],
      votePreferencesByScheme: {},
      polls: {},
      proposals: {},
      generalMeetings: {},
      organizations: {},
    }
    vp.votePreferencesByScheme[sampleGroupedVotePreferences().key] = sampleGroupedVotePreferences()
    vp.polls[samplePoll().name] = samplePoll()
    vp.proposals[sampleTransparencyProposal().name] = sampleTransparencyProposal()
    vp.generalMeetings[sampleTransparencyGeneralMeeting().name] = sampleTransparencyGeneralMeeting()
    vp.organizations[sampleTransparencyOrganization().name] = sampleTransparencyOrganization()
    return vp
  },

  FetchAnonymizedVoteCommentsByScheme: async () => {
    const vc: FetchAnonymizedVoteCommentsBySchemeResponse = {
      readTime: new Date(),
      summary: [samplePollVoteComments()],
      voteCommentsByScheme: {},
      polls: {},
      proposals: {},
      generalMeetings: {},
      organizations: {},
    }
    vc.voteCommentsByScheme[sampleGroupedVoteComments().key] = sampleGroupedVoteComments()
    vc.voteCommentsByScheme[sampleGroupedVoteComments().key] = sampleGroupedVoteComments()
    vc.polls[samplePoll().name] = samplePoll()
    vc.proposals[sampleTransparencyProposal().name] = sampleTransparencyProposal()
    vc.generalMeetings[sampleTransparencyGeneralMeeting().name] = sampleTransparencyGeneralMeeting()
    vc.organizations[sampleTransparencyOrganization().name] = sampleTransparencyOrganization()
    return vc
  },

  FetchPrivileges: async () => samplePrivileges(),

  ListSchemes: async () => ({
    schemes: [
      sampleScheme(),
      sampleScheme({
        name: schemeName2,
        title: 'Scheme 2',
      }),
      sampleScheme({
        name: schemeName3,
        title: 'Scheme 3',
        investorName: undefined,
      }),
    ],
    nextPageToken: '',
  }),

  SubmitVote: async () => ({
    response: Option.OPTION_FOR,
  }),

  SubmitBallotComment: async () => ({
    investorComment: 'comment',
  }),

  ListGeneralMeetings: async (req) => {
    if (req.schemeName == sampleScheme().name) {
      return sampleListGeneralMeetings()
    } else if (req.schemeName == schemeName3) {
      // return error to trigger the no access screen
      throw new Error()
    }

    return ListGeneralMeetingsResponse.fromPartial({})
  },

  ListFeatureFlags: async () => ({
    featureFlags: [sampleFeatureFlag()],
    nextPageToken: '',
  }),

  ListQuarterlyReports: async () => sampleListQuarterlyReportsResponse,

  FetchInvestments: async () => sampleFetchInvestments(),

  GetUser: async () => sampleIDPUser(),

  GetGeneralMeeting: async (req) => {
    if (req.meetingBallotName?.startsWith(sampleGeneralMeeting().name)) {
      return sampleGetGeneralMeeting()
    }

    // meeting closed
    return sampleGetGeneralMeeting({
      meeting: sampleGeneralMeeting({ actionDeadline: new Date() }),
    })
  },

  GetVotingPolicy: async () => {
    return {
      votingPolicy: sampleVotingPolicy(),
    }
  },
}

export const getMockedOrNotMockedClient = ({
  clientType,
  clientAddress,
}: Config): (() => Promise<NonAdminStewardshipBff>) => {
  if (clientType === 'mock') {
    return async () => MockClient
  }
  return async () => {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString()
    const metadata = new BrowserHeaders({ Authorization: `Bearer ${token}` })
    return new StewardshipBffClientImpl(new GrpcWebImpl(clientAddress, { metadata }))
  }
}

export const ClientContext = createContext<() => Promise<NonAdminStewardshipBff>>(() => {
  throw new Error('need to set client context value')
})
