/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** FeatureFlag represents a feature within the Stewardship Platform UI, and whether it should be enabled. */
export interface FeatureFlag {
  /** REQUIRED key is the feature identifier. */
  key: string;
  /** REQUIRED state indicates whether the feature is enabled. */
  state: boolean;
}

function createBaseFeatureFlag(): FeatureFlag {
  return { key: "", state: false };
}

export const FeatureFlag = {
  encode(message: FeatureFlag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.state === true) {
      writer.uint32(16).bool(message.state);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeatureFlag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeatureFlag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeatureFlag {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      state: isSet(object.state) ? Boolean(object.state) : false,
    };
  },

  toJSON(message: FeatureFlag): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.state === true) {
      obj.state = message.state;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FeatureFlag>, I>>(base?: I): FeatureFlag {
    return FeatureFlag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeatureFlag>, I>>(object: I): FeatureFlag {
    const message = createBaseFeatureFlag();
    message.key = object.key ?? "";
    message.state = object.state ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
