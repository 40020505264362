/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/**
 * Organization represents public companies and other institutions such as governments
 * that issue shares or bonds which are then traded on the world's financial markets.
 */
export interface Organization {
  /** REQUIRED the name of the organization (format 'organizations/{uuid}') */
  name: string;
  /** REQUIRED the title used for display purposes */
  displayTitle: string;
  /** OPTIONAL a https link to a logo of the organization (hosted by cloudinary) */
  logoUrl: string;
  /** OPTIONAL is the title of the industry corresponding to the NAICS code */
  naicsTitle: string;
}

function createBaseOrganization(): Organization {
  return { name: "", displayTitle: "", logoUrl: "", naicsTitle: "" };
}

export const Organization = {
  encode(message: Organization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.displayTitle !== "") {
      writer.uint32(18).string(message.displayTitle);
    }
    if (message.logoUrl !== "") {
      writer.uint32(26).string(message.logoUrl);
    }
    if (message.naicsTitle !== "") {
      writer.uint32(34).string(message.naicsTitle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayTitle = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.naicsTitle = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Organization {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      displayTitle: isSet(object.displayTitle) ? String(object.displayTitle) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      naicsTitle: isSet(object.naicsTitle) ? String(object.naicsTitle) : "",
    };
  },

  toJSON(message: Organization): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayTitle !== "") {
      obj.displayTitle = message.displayTitle;
    }
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.naicsTitle !== "") {
      obj.naicsTitle = message.naicsTitle;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Organization>, I>>(base?: I): Organization {
    return Organization.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Organization>, I>>(object: I): Organization {
    const message = createBaseOrganization();
    message.name = object.name ?? "";
    message.displayTitle = object.displayTitle ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.naicsTitle = object.naicsTitle ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
