export interface UserData {
  id?: string
}

export interface LoggerService {
  logError(e: Error | unknown | undefined, context?: Context): void
  setUserData(u: UserData): void
}

export interface Context {
  extra?: {
    where: string
  }
}

export class LoggerServiceCompound implements LoggerService {
  private readonly loggers: LoggerService[]

  constructor(...loggers: LoggerService[]) {
    this.loggers = loggers
  }

  setUserData(u: UserData): void {
    this.loggers.map((l) => l.setUserData(u))
  }

  logError(e: Error | unknown | undefined, context: Context): void {
    this.loggers.map((l) => l.logError(e, context))
  }
}
