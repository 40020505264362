import { Body2, Modal, RadioButtonItem, Stack, StickyButtonsProps } from '@tumelo/designsystem'
import { useRouter } from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import { Scheme } from '../../../proto/tumelo/stewardshipbff/v1/scheme'
import { slugToSchemeName, schemeNameToSlug } from '../../../utils/getCurrentSlug'
import { SchemeTruncatedTitle } from './SchemeTruncatedTitle'

interface Props {
  schemes: Scheme[]
  hideSwitchButton?: boolean
}

export const SwitchScheme: React.FC<Props> = ({ schemes, hideSwitchButton = false }) => {
  const router = useRouter()

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedSchemeName, setSelectedSchemeName] = useState(slugToSchemeName(router))

  const handleModifyUrl = (schemeName: string) => {
    const newSlug = schemeNameToSlug(schemeName)
    router.push(`/${newSlug}/investments`)
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSchemeName(e.target.value)
  }

  const handleSwitchButton = () => {
    setModalOpen(false)
    handleModifyUrl(selectedSchemeName)
  }

  const modalButtons: StickyButtonsProps = [
    { label: 'Cancel', variant: 'secondary', onClick: () => setModalOpen(false) },
    { label: 'Switch', variant: 'primary', onClick: () => handleSwitchButton() },
  ]

  const selectedSchemeTitle = schemes.find((scheme) => scheme.name === selectedSchemeName)?.title

  return (
    <>
      <StyledStack flexDirection="column" alignItems="flex-start" gap="0">
        <SchemeTruncatedTitle title={selectedSchemeTitle || ''} />
        {!hideSwitchButton && <StyledButton onClick={() => setModalOpen(true)}>Switch</StyledButton>}
      </StyledStack>
      <Modal
        headerType="title"
        type="action"
        open={modalOpen}
        title="Switch scheme / portfolio"
        onCloseCallback={() => setModalOpen(false)}
        stickyButtons={modalButtons}
      >
        {schemes.map(({ title, name }) => {
          const isSelected = name === selectedSchemeName
          return (
            <RadioButtonItem value={name} key={name} onChange={handleRadioChange} checked={isSelected}>
              <Body2 mb="0">{title}</Body2>
            </RadioButtonItem>
          )
        })}
      </Modal>
    </>
  )
}

const StyledStack = styled(Stack)`
  border-left: 1px solid ${({ theme }) => theme.colors.grey.dark};
  padding-left: 0.8rem;
  padding-bottom: 0.4rem;
`

const StyledButton = styled.button`
  padding: 0;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.info.light};
  border: none;
  background: none;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.info.light};
  }

  &:hover::after {
    content: none;
  }
`
