import { useContext } from 'react'
import useSWR from 'swr'
import { ClientContext } from '../client'

export const useFetchListSchemes = () => {
  const getClient = useContext(ClientContext)

  const getSchemes = async () => {
    const client = await getClient()
    return await client.ListSchemes({})
  }

  const { data, error, isLoading } = useSWR('/api/schemes', getSchemes, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  return { data, error, isLoading }
}
