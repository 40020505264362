import { Option } from '../../proto/tumelo/poll/v1/option'
import { VoteCommentPair, PollVoteComments, GroupedVoteComments } from '../../proto/tumelo/poll/v1/vote_comments'
import { samplePoll } from './poll'

export const sampleVoteCommentPair = (override?: Partial<VoteCommentPair>): VoteCommentPair =>
  VoteCommentPair.fromPartial({
    vote: Option.OPTION_FOR,
    comment: 'Why not?',
    ...override,
  })

export const samplePollVoteComments = (override?: Partial<PollVoteComments>): PollVoteComments =>
  PollVoteComments.fromPartial({
    pollName: samplePoll().name,
    voteCommentPairs: [sampleVoteCommentPair()],
    ...override,
  })
export const sampleGroupedVoteComments = (override?: Partial<GroupedVoteComments>): GroupedVoteComments =>
  GroupedVoteComments.fromPartial({
    key: 'key',
    pollVoteComments: [samplePollVoteComments()],
    ...override,
  })
