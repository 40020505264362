import { prefixWithSeparator as habitatPrefix } from './habitat';
import { prefix as schemePrefix } from './scheme';
var prefix = "votingPolicyGrants/";
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, schemeID = _a.schemeID, grantID = _a.grantID;
    return habitatPrefix + habitatID + '/' + schemePrefix + schemeID + '/' + prefix + grantID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 6) {
        throw new Error("bad scheme voting policy grant name: ".concat(name));
    }
    return {
        habitatID: parts[1],
        schemeID: parts[3],
        grantID: parts[5],
    };
};
