import { createGlobalStyle } from 'styled-components'
import { SoftConfigThemeSchema } from '@tumelo/designsystem'

const defaultTheme = SoftConfigThemeSchema.parse()

export const PlannedMaintenanceStyles = createGlobalStyle`
  html,body {
    background-color: ${defaultTheme.colors.primary.dark};
    flex-grow:1;
  }

  h1 {
    color: ${defaultTheme.colors.white};
    text-align: center;
    padding-left: 3%;
    padding-right: 3%;
  }
`
