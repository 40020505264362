/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { PolicyProvider } from "./policy_provider";
import { VotingPolicyDocument } from "./voting_policy_document";
export var protobufPackage = "tumelo.votingpolicy.v1";
/**
 * InvestorType describes the type of investor that can utilise this policy.
 *   - INSTITUTIONAL - An investor representing a that gets PTV ballots.
 *   - RETAIL - A Shareholder platform user that gets EOW ballots only.
 *   - RETAIL_PTV - A Retail platform user that gets PTV ballots.
 */
export var AvailableToInvestorType;
(function (AvailableToInvestorType) {
    AvailableToInvestorType[AvailableToInvestorType["AVAILABLE_TO_INVESTOR_TYPE_INVALID"] = 0] = "AVAILABLE_TO_INVESTOR_TYPE_INVALID";
    AvailableToInvestorType[AvailableToInvestorType["AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL"] = 1] = "AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL";
    AvailableToInvestorType[AvailableToInvestorType["AVAILABLE_TO_INVESTOR_TYPE_RETAIL"] = 2] = "AVAILABLE_TO_INVESTOR_TYPE_RETAIL";
    AvailableToInvestorType[AvailableToInvestorType["AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV"] = 3] = "AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV";
    AvailableToInvestorType[AvailableToInvestorType["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(AvailableToInvestorType || (AvailableToInvestorType = {}));
export function availableToInvestorTypeFromJSON(object) {
    switch (object) {
        case 0:
        case "AVAILABLE_TO_INVESTOR_TYPE_INVALID":
            return AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_INVALID;
        case 1:
        case "AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL":
            return AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL;
        case 2:
        case "AVAILABLE_TO_INVESTOR_TYPE_RETAIL":
            return AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_RETAIL;
        case 3:
        case "AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV":
            return AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV;
        case -1:
        case "UNRECOGNIZED":
        default:
            return AvailableToInvestorType.UNRECOGNIZED;
    }
}
export function availableToInvestorTypeToJSON(object) {
    switch (object) {
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_INVALID:
            return "AVAILABLE_TO_INVESTOR_TYPE_INVALID";
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL:
            return "AVAILABLE_TO_INVESTOR_TYPE_INSTITUTIONAL";
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_RETAIL:
            return "AVAILABLE_TO_INVESTOR_TYPE_RETAIL";
        case AvailableToInvestorType.AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV:
            return "AVAILABLE_TO_INVESTOR_TYPE_RETAIL_PTV";
        case AvailableToInvestorType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseVotingPolicy() {
    return {
        name: "",
        title: "",
        policyProvider: undefined,
        formattedDescription: "",
        currentPolicyDocument: undefined,
        externalId: "",
        availableToInvestorType: [],
        createTime: undefined,
        updateTime: undefined,
        imageUrl: "",
    };
}
export var VotingPolicy = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.title !== "") {
            writer.uint32(18).string(message.title);
        }
        if (message.policyProvider !== undefined) {
            PolicyProvider.encode(message.policyProvider, writer.uint32(26).fork()).ldelim();
        }
        if (message.formattedDescription !== "") {
            writer.uint32(34).string(message.formattedDescription);
        }
        if (message.currentPolicyDocument !== undefined) {
            VotingPolicyDocument.encode(message.currentPolicyDocument, writer.uint32(42).fork()).ldelim();
        }
        if (message.externalId !== "") {
            writer.uint32(50).string(message.externalId);
        }
        writer.uint32(58).fork();
        for (var _i = 0, _a = message.availableToInvestorType; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.int32(v);
        }
        writer.ldelim();
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(66).fork()).ldelim();
        }
        if (message.updateTime !== undefined) {
            Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(74).fork()).ldelim();
        }
        if (message.imageUrl !== "") {
            writer.uint32(82).string(message.imageUrl);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseVotingPolicy();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.title = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.policyProvider = PolicyProvider.decode(reader, reader.uint32());
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.formattedDescription = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.currentPolicyDocument = VotingPolicyDocument.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.externalId = reader.string();
                    continue;
                case 7:
                    if (tag === 56) {
                        message.availableToInvestorType.push(reader.int32());
                        continue;
                    }
                    if (tag === 58) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.availableToInvestorType.push(reader.int32());
                        }
                        continue;
                    }
                    break;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.imageUrl = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            title: isSet(object.title) ? String(object.title) : "",
            policyProvider: isSet(object.policyProvider) ? PolicyProvider.fromJSON(object.policyProvider) : undefined,
            formattedDescription: isSet(object.formattedDescription) ? String(object.formattedDescription) : "",
            currentPolicyDocument: isSet(object.currentPolicyDocument)
                ? VotingPolicyDocument.fromJSON(object.currentPolicyDocument)
                : undefined,
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
            availableToInvestorType: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.availableToInvestorType)
                ? object.availableToInvestorType.map(function (e) { return availableToInvestorTypeFromJSON(e); })
                : [],
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
            imageUrl: isSet(object.imageUrl) ? String(object.imageUrl) : "",
        };
    },
    toJSON: function (message) {
        var _a;
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.title !== "") {
            obj.title = message.title;
        }
        if (message.policyProvider !== undefined) {
            obj.policyProvider = PolicyProvider.toJSON(message.policyProvider);
        }
        if (message.formattedDescription !== "") {
            obj.formattedDescription = message.formattedDescription;
        }
        if (message.currentPolicyDocument !== undefined) {
            obj.currentPolicyDocument = VotingPolicyDocument.toJSON(message.currentPolicyDocument);
        }
        if (message.externalId !== "") {
            obj.externalId = message.externalId;
        }
        if ((_a = message.availableToInvestorType) === null || _a === void 0 ? void 0 : _a.length) {
            obj.availableToInvestorType = message.availableToInvestorType.map(function (e) { return availableToInvestorTypeToJSON(e); });
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.updateTime !== undefined) {
            obj.updateTime = message.updateTime.toISOString();
        }
        if (message.imageUrl !== "") {
            obj.imageUrl = message.imageUrl;
        }
        return obj;
    },
    create: function (base) {
        return VotingPolicy.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var message = createBaseVotingPolicy();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.title = (_b = object.title) !== null && _b !== void 0 ? _b : "";
        message.policyProvider = (object.policyProvider !== undefined && object.policyProvider !== null)
            ? PolicyProvider.fromPartial(object.policyProvider)
            : undefined;
        message.formattedDescription = (_c = object.formattedDescription) !== null && _c !== void 0 ? _c : "";
        message.currentPolicyDocument =
            (object.currentPolicyDocument !== undefined && object.currentPolicyDocument !== null)
                ? VotingPolicyDocument.fromPartial(object.currentPolicyDocument)
                : undefined;
        message.externalId = (_d = object.externalId) !== null && _d !== void 0 ? _d : "";
        message.availableToInvestorType = ((_e = object.availableToInvestorType) === null || _e === void 0 ? void 0 : _e.map(function (e) { return e; })) || [];
        message.createTime = (_f = object.createTime) !== null && _f !== void 0 ? _f : undefined;
        message.updateTime = (_g = object.updateTime) !== null && _g !== void 0 ? _g : undefined;
        message.imageUrl = (_h = object.imageUrl) !== null && _h !== void 0 ? _h : "";
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
