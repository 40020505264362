/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Option, optionFromJSON, optionToJSON } from "./option";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** ProposalSource describes who raised the proposal */
export enum ProposalSource {
  PROPOSAL_SOURCE_INVALID = 0,
  PROPOSAL_SOURCE_SHAREHOLDER = 1,
  PROPOSAL_SOURCE_MANAGEMENT = 2,
  UNRECOGNIZED = -1,
}

export function proposalSourceFromJSON(object: any): ProposalSource {
  switch (object) {
    case 0:
    case "PROPOSAL_SOURCE_INVALID":
      return ProposalSource.PROPOSAL_SOURCE_INVALID;
    case 1:
    case "PROPOSAL_SOURCE_SHAREHOLDER":
      return ProposalSource.PROPOSAL_SOURCE_SHAREHOLDER;
    case 2:
    case "PROPOSAL_SOURCE_MANAGEMENT":
      return ProposalSource.PROPOSAL_SOURCE_MANAGEMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProposalSource.UNRECOGNIZED;
  }
}

export function proposalSourceToJSON(object: ProposalSource): string {
  switch (object) {
    case ProposalSource.PROPOSAL_SOURCE_INVALID:
      return "PROPOSAL_SOURCE_INVALID";
    case ProposalSource.PROPOSAL_SOURCE_SHAREHOLDER:
      return "PROPOSAL_SOURCE_SHAREHOLDER";
    case ProposalSource.PROPOSAL_SOURCE_MANAGEMENT:
      return "PROPOSAL_SOURCE_MANAGEMENT";
    case ProposalSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Proposal represents a question being asked at the GM */
export interface Proposal {
  /** REQUIRED the name of the proposal (format 'organizations/{uuid}/generalMeetings/{uuid}/proposals/{uuid}') */
  name: string;
  /** REQUIRED the identifier given in the GM document, e.g. "1" or "1.a" */
  sequenceIdentifier: string;
  /** REQUIRED the number representing the ordinal position of the proposal within the proxy document */
  sequenceNumber: number;
  /** REQUIRED the title of the proposal given in the GM document */
  title: string;
  /** OPTIONAL a markdown formatted description of the poll corresponding to this proposal */
  summary: string;
  /** OPTIONAL (EDI data feed) list of one or more 2-letter classification codes that apply to this proposal */
  classificationCodes: string[];
  /** REQUIRED describes who raised the proposal */
  source: ProposalSource;
  /** REQUIRED available voting options for this proposal, is an empty list when proposal cannot be voted on */
  votingOptions: Option[];
  /** OPTIONAL the voting option that the investor's voting policy recommends */
  votingRecommendation: Option;
  /** OPTIONAL the title of the parent proposal, if this proposal is a sub-proposal */
  parentProposalTitle: string;
}

function createBaseProposal(): Proposal {
  return {
    name: "",
    sequenceIdentifier: "",
    sequenceNumber: 0,
    title: "",
    summary: "",
    classificationCodes: [],
    source: 0,
    votingOptions: [],
    votingRecommendation: 0,
    parentProposalTitle: "",
  };
}

export const Proposal = {
  encode(message: Proposal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.sequenceIdentifier !== "") {
      writer.uint32(18).string(message.sequenceIdentifier);
    }
    if (message.sequenceNumber !== 0) {
      writer.uint32(72).int32(message.sequenceNumber);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.summary !== "") {
      writer.uint32(34).string(message.summary);
    }
    for (const v of message.classificationCodes) {
      writer.uint32(42).string(v!);
    }
    if (message.source !== 0) {
      writer.uint32(48).int32(message.source);
    }
    writer.uint32(58).fork();
    for (const v of message.votingOptions) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.votingRecommendation !== 0) {
      writer.uint32(64).int32(message.votingRecommendation);
    }
    if (message.parentProposalTitle !== "") {
      writer.uint32(82).string(message.parentProposalTitle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Proposal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProposal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sequenceIdentifier = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.sequenceNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.classificationCodes.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 7:
          if (tag === 56) {
            message.votingOptions.push(reader.int32() as any);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.votingOptions.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.votingRecommendation = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.parentProposalTitle = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Proposal {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      sequenceIdentifier: isSet(object.sequenceIdentifier) ? String(object.sequenceIdentifier) : "",
      sequenceNumber: isSet(object.sequenceNumber) ? Number(object.sequenceNumber) : 0,
      title: isSet(object.title) ? String(object.title) : "",
      summary: isSet(object.summary) ? String(object.summary) : "",
      classificationCodes: globalThis.Array.isArray(object?.classificationCodes)
        ? object.classificationCodes.map((e: any) => String(e))
        : [],
      source: isSet(object.source) ? proposalSourceFromJSON(object.source) : 0,
      votingOptions: globalThis.Array.isArray(object?.votingOptions)
        ? object.votingOptions.map((e: any) => optionFromJSON(e))
        : [],
      votingRecommendation: isSet(object.votingRecommendation) ? optionFromJSON(object.votingRecommendation) : 0,
      parentProposalTitle: isSet(object.parentProposalTitle) ? String(object.parentProposalTitle) : "",
    };
  },

  toJSON(message: Proposal): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.sequenceIdentifier !== "") {
      obj.sequenceIdentifier = message.sequenceIdentifier;
    }
    if (message.sequenceNumber !== 0) {
      obj.sequenceNumber = Math.round(message.sequenceNumber);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    if (message.classificationCodes?.length) {
      obj.classificationCodes = message.classificationCodes;
    }
    if (message.source !== 0) {
      obj.source = proposalSourceToJSON(message.source);
    }
    if (message.votingOptions?.length) {
      obj.votingOptions = message.votingOptions.map((e) => optionToJSON(e));
    }
    if (message.votingRecommendation !== 0) {
      obj.votingRecommendation = optionToJSON(message.votingRecommendation);
    }
    if (message.parentProposalTitle !== "") {
      obj.parentProposalTitle = message.parentProposalTitle;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Proposal>, I>>(base?: I): Proposal {
    return Proposal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Proposal>, I>>(object: I): Proposal {
    const message = createBaseProposal();
    message.name = object.name ?? "";
    message.sequenceIdentifier = object.sequenceIdentifier ?? "";
    message.sequenceNumber = object.sequenceNumber ?? 0;
    message.title = object.title ?? "";
    message.summary = object.summary ?? "";
    message.classificationCodes = object.classificationCodes?.map((e) => e) || [];
    message.source = object.source ?? 0;
    message.votingOptions = object.votingOptions?.map((e) => e) || [];
    message.votingRecommendation = object.votingRecommendation ?? 0;
    message.parentProposalTitle = object.parentProposalTitle ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
