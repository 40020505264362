import { QuarterlyReport } from '../../proto/tumelo/stewardshipbff/v1/quarterly_report'
import { ListQuarterlyReportsResponse } from '../../proto/tumelo/stewardshipbff/v1/service'

export const sampleQuarterlyReport = (override?: Partial<QuarterlyReport>): QuarterlyReport =>
  QuarterlyReport.fromPartial({
    displayName: 'Quarterly spreadsheet',
    calendarQuarter: { quarter: 2, year: 2024 },
    signedUrl: 'https://www.golang-book.com/public/pdf/gobook.pdf',
    ...override,
  })

export const sampleListQuarterlyReportsResponse: ListQuarterlyReportsResponse = {
  quarterlyReports: [
    sampleQuarterlyReport(),
    sampleQuarterlyReport({ displayName: 'Quarterly PDF', calendarQuarter: { quarter: 1, year: 2024 } }),
    sampleQuarterlyReport({ calendarQuarter: { quarter: 4, year: 2023 } }),
    sampleQuarterlyReport({ displayName: 'Quarterly PDF', calendarQuarter: { quarter: 3, year: 2023 } }),
  ],
}
