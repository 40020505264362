/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */

export const protobufPackage = "tumelo.stewardshipbff.v1";

/**
 * Option describes an option of how an investor may vote: FOR, AGAINST, ABSTAIN, WITHHOLD, ONE_YEAR, TWO_YEARS, THREE_YEARS, or NOACTION
 * Voting NOACTION would mean the investor
 * - does not want the voting recommendation to be automatically applied (if they have a voting policy selected)
 * - does not want to submit a (FOR/AGAINST/ABSTAIN/WITHHOLD/ONE_YEAR/TWO_YEARS/THREE_YEARS) vote manually
 */
export enum Option {
  OPTION_INVALID = 0,
  OPTION_FOR = 1,
  OPTION_AGAINST = 2,
  OPTION_ABSTAIN = 3,
  OPTION_WITHHOLD = 4,
  OPTION_NO_ACTION = 5,
  OPTION_ONE_YEAR = 6,
  OPTION_TWO_YEARS = 7,
  OPTION_THREE_YEARS = 8,
  UNRECOGNIZED = -1,
}

export function optionFromJSON(object: any): Option {
  switch (object) {
    case 0:
    case "OPTION_INVALID":
      return Option.OPTION_INVALID;
    case 1:
    case "OPTION_FOR":
      return Option.OPTION_FOR;
    case 2:
    case "OPTION_AGAINST":
      return Option.OPTION_AGAINST;
    case 3:
    case "OPTION_ABSTAIN":
      return Option.OPTION_ABSTAIN;
    case 4:
    case "OPTION_WITHHOLD":
      return Option.OPTION_WITHHOLD;
    case 5:
    case "OPTION_NO_ACTION":
      return Option.OPTION_NO_ACTION;
    case 6:
    case "OPTION_ONE_YEAR":
      return Option.OPTION_ONE_YEAR;
    case 7:
    case "OPTION_TWO_YEARS":
      return Option.OPTION_TWO_YEARS;
    case 8:
    case "OPTION_THREE_YEARS":
      return Option.OPTION_THREE_YEARS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Option.UNRECOGNIZED;
  }
}

export function optionToJSON(object: Option): string {
  switch (object) {
    case Option.OPTION_INVALID:
      return "OPTION_INVALID";
    case Option.OPTION_FOR:
      return "OPTION_FOR";
    case Option.OPTION_AGAINST:
      return "OPTION_AGAINST";
    case Option.OPTION_ABSTAIN:
      return "OPTION_ABSTAIN";
    case Option.OPTION_WITHHOLD:
      return "OPTION_WITHHOLD";
    case Option.OPTION_NO_ACTION:
      return "OPTION_NO_ACTION";
    case Option.OPTION_ONE_YEAR:
      return "OPTION_ONE_YEAR";
    case Option.OPTION_TWO_YEARS:
      return "OPTION_TWO_YEARS";
    case Option.OPTION_THREE_YEARS:
      return "OPTION_THREE_YEARS";
    case Option.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
