/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { ClassificationTag } from "./classification_tag";
import { Image } from "./image";

export const protobufPackage = "tumelo.poll.v1";

export enum PollState {
  POLL_STATE_INVALID = 0,
  POLL_STATE_DRAFT = 1,
  POLL_STATE_PUBLISHED = 2,
  UNRECOGNIZED = -1,
}

export function pollStateFromJSON(object: any): PollState {
  switch (object) {
    case 0:
    case "POLL_STATE_INVALID":
      return PollState.POLL_STATE_INVALID;
    case 1:
    case "POLL_STATE_DRAFT":
      return PollState.POLL_STATE_DRAFT;
    case 2:
    case "POLL_STATE_PUBLISHED":
      return PollState.POLL_STATE_PUBLISHED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PollState.UNRECOGNIZED;
  }
}

export function pollStateToJSON(object: PollState): string {
  switch (object) {
    case PollState.POLL_STATE_INVALID:
      return "POLL_STATE_INVALID";
    case PollState.POLL_STATE_DRAFT:
      return "POLL_STATE_DRAFT";
    case PollState.POLL_STATE_PUBLISHED:
      return "POLL_STATE_PUBLISHED";
    case PollState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Poll wraps a proposal and adds tumelo enrichments such as tags and consumer-friendly language
 * Polls represent an organization's proposal that an eligible investor can respond to.
 */
export interface Poll {
  /** OUTPUT ONLY of format polls/{uuid} */
  name: string;
  /** REQUIRED the proposal that this poll relates to */
  proposalName: string;
  /** REQUIRED the title of the poll */
  title: string;
  /** REQUIRED a markdown formatted description of the poll */
  description: string;
  /**
   * REQUIRED the time at which the poll closes. Votes cast after this time may not be reported to all Fund Managers.
   * This field will be deprecated in future. Refer to the voting deadlines on the ballot to find out when votes must be cast.
   */
  closeTime:
    | Date
    | undefined;
  /**
   * GENERATED the time of the associated General Meeting. Automatically set upon the creation of the Poll from the
   * date of the associated General Meeting.
   *
   * The General Meeting date is set here to make upsert ballots more efficient by preventing the need to fetch the
   * GM dates from the Transparency service while creating the ballots.
   *
   * This value is NOT updated when the GM in the Transparency service is updated, so in the event the GM date is
   * changed, we will fall out of sync.
   */
  generalMeetingDate:
    | Date
    | undefined;
  /** OUTPUT ONLY the time the poll was published */
  publishTime:
    | Date
    | undefined;
  /** REQUIRED (at least 1) describe the classification this poll falls into */
  classificationTags: ClassificationTag[];
  /**
   * State describes the current state of the poll
   * OUTPUT ONLY the current state of the poll
   */
  state: PollState;
  /**
   * OPTIONAL brief description of poll, max 250 characters
   * NB this field is required if is_hot_topic is set to true
   */
  teaserText: string;
  /**
   * OPTIONAL teaser_image is the image to accompany the teaser text
   * NB this field is required if is_hot_topic is set to true
   */
  teaserImage:
    | Image
    | undefined;
  /** REQUIRED is_hot_topic is whether this poll has been selected as a hot topic */
  isHotTopic: boolean;
  /** REQUIRED Is this poll meant to be featured as NTTP */
  isNewToPlatform: boolean;
  /**
   * OPTIONAL headline is a more enticing version of the poll title, for use in communications, max 115 characters
   * NB this field is required if is_hot_topic is set to true
   */
  headline: string;
}

function createBasePoll(): Poll {
  return {
    name: "",
    proposalName: "",
    title: "",
    description: "",
    closeTime: undefined,
    generalMeetingDate: undefined,
    publishTime: undefined,
    classificationTags: [],
    state: 0,
    teaserText: "",
    teaserImage: undefined,
    isHotTopic: false,
    isNewToPlatform: false,
    headline: "",
  };
}

export const Poll = {
  encode(message: Poll, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.proposalName !== "") {
      writer.uint32(18).string(message.proposalName);
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.closeTime !== undefined) {
      Timestamp.encode(toTimestamp(message.closeTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.generalMeetingDate !== undefined) {
      Timestamp.encode(toTimestamp(message.generalMeetingDate), writer.uint32(114).fork()).ldelim();
    }
    if (message.publishTime !== undefined) {
      Timestamp.encode(toTimestamp(message.publishTime), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.classificationTags) {
      ClassificationTag.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.state !== 0) {
      writer.uint32(64).int32(message.state);
    }
    if (message.teaserText !== "") {
      writer.uint32(82).string(message.teaserText);
    }
    if (message.teaserImage !== undefined) {
      Image.encode(message.teaserImage, writer.uint32(90).fork()).ldelim();
    }
    if (message.isHotTopic === true) {
      writer.uint32(96).bool(message.isHotTopic);
    }
    if (message.isNewToPlatform === true) {
      writer.uint32(104).bool(message.isNewToPlatform);
    }
    if (message.headline !== "") {
      writer.uint32(122).string(message.headline);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Poll {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoll();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.closeTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.generalMeetingDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.publishTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.classificationTags.push(ClassificationTag.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.teaserText = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.teaserImage = Image.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isHotTopic = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.isNewToPlatform = reader.bool();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.headline = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Poll {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      proposalName: isSet(object.proposalName) ? String(object.proposalName) : "",
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      closeTime: isSet(object.closeTime) ? fromJsonTimestamp(object.closeTime) : undefined,
      generalMeetingDate: isSet(object.generalMeetingDate) ? fromJsonTimestamp(object.generalMeetingDate) : undefined,
      publishTime: isSet(object.publishTime) ? fromJsonTimestamp(object.publishTime) : undefined,
      classificationTags: globalThis.Array.isArray(object?.classificationTags)
        ? object.classificationTags.map((e: any) => ClassificationTag.fromJSON(e))
        : [],
      state: isSet(object.state) ? pollStateFromJSON(object.state) : 0,
      teaserText: isSet(object.teaserText) ? String(object.teaserText) : "",
      teaserImage: isSet(object.teaserImage) ? Image.fromJSON(object.teaserImage) : undefined,
      isHotTopic: isSet(object.isHotTopic) ? Boolean(object.isHotTopic) : false,
      isNewToPlatform: isSet(object.isNewToPlatform) ? Boolean(object.isNewToPlatform) : false,
      headline: isSet(object.headline) ? String(object.headline) : "",
    };
  },

  toJSON(message: Poll): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.proposalName !== "") {
      obj.proposalName = message.proposalName;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.closeTime !== undefined) {
      obj.closeTime = message.closeTime.toISOString();
    }
    if (message.generalMeetingDate !== undefined) {
      obj.generalMeetingDate = message.generalMeetingDate.toISOString();
    }
    if (message.publishTime !== undefined) {
      obj.publishTime = message.publishTime.toISOString();
    }
    if (message.classificationTags?.length) {
      obj.classificationTags = message.classificationTags.map((e) => ClassificationTag.toJSON(e));
    }
    if (message.state !== 0) {
      obj.state = pollStateToJSON(message.state);
    }
    if (message.teaserText !== "") {
      obj.teaserText = message.teaserText;
    }
    if (message.teaserImage !== undefined) {
      obj.teaserImage = Image.toJSON(message.teaserImage);
    }
    if (message.isHotTopic === true) {
      obj.isHotTopic = message.isHotTopic;
    }
    if (message.isNewToPlatform === true) {
      obj.isNewToPlatform = message.isNewToPlatform;
    }
    if (message.headline !== "") {
      obj.headline = message.headline;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Poll>, I>>(base?: I): Poll {
    return Poll.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Poll>, I>>(object: I): Poll {
    const message = createBasePoll();
    message.name = object.name ?? "";
    message.proposalName = object.proposalName ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.closeTime = object.closeTime ?? undefined;
    message.generalMeetingDate = object.generalMeetingDate ?? undefined;
    message.publishTime = object.publishTime ?? undefined;
    message.classificationTags = object.classificationTags?.map((e) => ClassificationTag.fromPartial(e)) || [];
    message.state = object.state ?? 0;
    message.teaserText = object.teaserText ?? "";
    message.teaserImage = (object.teaserImage !== undefined && object.teaserImage !== null)
      ? Image.fromPartial(object.teaserImage)
      : undefined;
    message.isHotTopic = object.isHotTopic ?? false;
    message.isNewToPlatform = object.isNewToPlatform ?? false;
    message.headline = object.headline ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
