/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.poll.v1";

/** ClassificationTag describes what type of poll this is */
export interface ClassificationTag {
  /** REQUIRED The ID of the tag */
  identifier: number;
  /** REQUIRED The human readable name of the tag */
  title: string;
}

function createBaseClassificationTag(): ClassificationTag {
  return { identifier: 0, title: "" };
}

export const ClassificationTag = {
  encode(message: ClassificationTag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== 0) {
      writer.uint32(8).int32(message.identifier);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClassificationTag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClassificationTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.identifier = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClassificationTag {
    return {
      identifier: isSet(object.identifier) ? Number(object.identifier) : 0,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ClassificationTag): unknown {
    const obj: any = {};
    if (message.identifier !== 0) {
      obj.identifier = Math.round(message.identifier);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClassificationTag>, I>>(base?: I): ClassificationTag {
    return ClassificationTag.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClassificationTag>, I>>(object: I): ClassificationTag {
    const message = createBaseClassificationTag();
    message.identifier = object.identifier ?? 0;
    message.title = object.title ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
