import {
  GeneralMeeting as TransparencyGeneralMeeting,
  GeneralMeeting_Type,
} from '../../proto/tumelo/transparency/v1/general_meeting'
import { GeneralMeeting } from '../../proto/tumelo/stewardshipbff/v1/general_meeting'
import { sampleProposal } from './proposal'

const today = new Date()
today.setDate(today.getDate() + 1)
const todayPlusThreeDays = today

export const sampleTransparencyGeneralMeeting = (
  override?: Partial<TransparencyGeneralMeeting>
): TransparencyGeneralMeeting =>
  TransparencyGeneralMeeting.fromPartial({
    name: 'organizations/uuid/generalMeetings/uuid',
    organizationName: 'organizations/uuid', // matches sample org
    type: GeneralMeeting_Type.GENERAL_MEETING_TYPE_ANNUAL,
    meetingDate: todayPlusThreeDays,
    ...override,
  })

export const sampleGeneralMeeting = (override?: Partial<GeneralMeeting>): GeneralMeeting =>
  GeneralMeeting.fromPartial({
    name: 'organizations/uuid/generalMeetings/87ec1877-9edd-4a6a-9ffb-19b74a5b0f18',
    proposalNames: [sampleProposal().name],
    actionDeadline: todayPlusThreeDays,
    meetingDate: todayPlusThreeDays,
    organizationName: 'organizations/uuid', // matches sample org
    proxyNoticeUrl: 'http://example.com/',
    proposalClassificationCodes: ['CC'],
    ...override,
  })
