export var SEPARATOR = '/';
export var genericValidateName = function (validators) {
    return function () {
        var names = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            names[_i] = arguments[_i];
        }
        var errs = [];
        var _loop_1 = function (name_1) {
            var parts = name_1.split(SEPARATOR);
            if (parts.length !== validators.length) {
                errs.push(new Error("name '".concat(name_1, "': got ").concat(parts.length, " parts, expected ").concat(validators.length)));
            }
            else {
                // run only if length of parts and validators matches
                validators.forEach(function (validator, i) {
                    var err = validator(parts[i]);
                    if (err !== undefined) {
                        errs.push(new Error("name '".concat(name_1, "' parts[").concat(i, "]: ").concat(err.message, ",")));
                    }
                });
            }
        };
        for (var _a = 0, names_1 = names; _a < names_1.length; _a++) {
            var name_1 = names_1[_a];
            _loop_1(name_1);
        }
        if (errs.length !== 0) {
            throw new Error(errs.map(function (err) { return err.message; }).join('\n'));
        }
    };
};
export var exactValidator = function (expected) {
    return function (part) {
        if (part !== expected) {
            return new Error("got part '".concat(part, "', expected '").concat(expected, "'"));
        }
        return undefined;
    };
};
var uuidRegexString = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
export var uuidValidator = function () {
    return function (part) {
        if (!uuidRegexString.test(part)) {
            return new Error("part '".concat(part, "'is not a valid UUID"));
        }
        return undefined;
    };
};
