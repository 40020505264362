import { User } from '../domain/user'

export const sampleCognitoUser = (override?: Partial<User>): User => ({
  id: '0a98d094-f715-4002-82f7-f5693753f895',
  firstName: 'John',
  lastName: 'Smith',
  email: 'john@smith.com',
  userName: '123-456',
  ...override,
})
