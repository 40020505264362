import { Caption, Stack } from '@tumelo/designsystem'
import { useFetchListSchemes } from '../../../application/hooks/useFetchListSchemes'
import { SwitchScheme } from './SwitchScheme'

export const SwitchSchemeContainer: React.FC = () => {
  const { data, error, isLoading } = useFetchListSchemes()

  if (!isLoading && (error || !data || data.schemes.length === 0)) {
    return null
  }

  if (isLoading) {
    return (
      <Stack justifyContent="center">
        <Caption semibold>Loading...</Caption>
      </Stack>
    )
  }

  if (data?.schemes.length === 1) {
    return <SwitchScheme schemes={data?.schemes || []} hideSwitchButton />
  }
  return <SwitchScheme schemes={data?.schemes || []} />
}
