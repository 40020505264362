import { FetchInvestmentsResponse } from '../../proto/tumelo/stewardshipbff/v1/service'

export const sampleFetchInvestments = (override?: Partial<FetchInvestmentsResponse>): FetchInvestmentsResponse =>
  FetchInvestmentsResponse.fromPartial({
    compositeInstruments: {
      'securityIDTypes/isin/securityIDs/GB8317088120': {
        id: 'securityIDTypes/isin/securityIDs/GB8317088120',
        title: 'Stewardship Platform Test Fund Variant',
        fundManagerName: 'fundManagers/dbd1defa-6d01-4cfd-857d-ab68e85e3c22',
        instrumentReference: {
          idType: 'isin',
          idValue: 'GB8317088120',
        },
        isResolved: true,
        schemes: {
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b': false,
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/fa9daefb-67c2-4d21-b06c-9016eab49102': true,
        },
      },
      'securityIDTypes/isin/securityIDs/GB8317088000': {
        id: 'securityIDTypes/isin/securityIDs/GB8317088000',
        title: 'Stewardship Platform Test Fund 0',
        fundManagerName: 'fundManagers/dbd1defa-6d01-4cfd-857d-ab68e85e3c22',
        instrumentReference: {
          idType: 'isin',
          idValue: 'GB8317088000',
        },
        isResolved: true,
        schemes: {
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b': false,
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/fa9daefb-67c2-4d21-b06c-9016eab49102': true,
        },
      },
      'securityIDTypes/isin/securityIDs/GB8317088111': {
        id: 'securityIDTypes/isin/securityIDs/GB8317088111',
        title: 'Stewardship Platform Test Fund 1',
        fundManagerName: 'fundManagers/244bad13-a695-41e6-9842-454edd894ac9',
        instrumentReference: {
          idType: 'isin',
          idValue: 'GB8317088111',
        },
        isResolved: true,
        schemes: {
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b': false,
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/fa9daefb-67c2-4d21-b06c-9016eab49102': true,
        },
      },
      'securityIDTypes/isin/securityIDs/GB8317088444': {
        id: 'securityIDTypes/isin/securityIDs/GB8317088444',
        title: 'Stewardship Platform Test Fund 4',
        fundManagerName: 'fundManagers/dbd1defa-6d01-4cfd-857d-ab68e85e3c22',
        instrumentReference: {
          idType: 'isin',
          idValue: 'GB8317088444',
        },
        isResolved: true,
        schemes: {
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b': true,
        },
      },
      'securityIDTypes/isin/securityIDs/GB8317088222': {
        id: 'securityIDTypes/isin/securityIDs/GB8317088222',
        title: 'Stewardship Platform Test Fund 2',
        fundManagerName: 'fundManagers/dbd1defa-6d01-4cfd-857d-ab68e85e3c22',
        instrumentReference: {
          idType: 'customID',
          idValue: '010101010',
        },
        isResolved: true,
        schemes: {
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b': true,
        },
      },
      'securityIDTypes/isin/securityIDs/GB8317088333': {
        id: 'securityIDTypes/isin/securityIDs/GB8317088333',
        title: 'Stewardship Platform Test Fund 3',
        fundManagerName: 'fundManagers/244bad13-a695-41e6-9842-454edd894ac9',
        instrumentReference: {
          idType: 'isin',
          idValue: 'GB8317088333',
        },
        isResolved: true,
        schemes: {
          'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b': true,
        },
      },
    },
    fundManagerNameToTitle: {
      'fundManagers/dbd1defa-6d01-4cfd-857d-ab68e85e3c22': 'Stewardship Platform Test Fund Manager',
      'fundManagers/244bad13-a695-41e6-9842-454edd894ac9': 'Stewardship Platform Fund Manager 2',
    },
    schemeNameToTitle: {
      'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/fa9daefb-67c2-4d21-b06c-9016eab49102':
        'Tumelo Scheme 1 (Votable Scheme)',
      'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/e550c6e5-4f2d-4d69-8e02-e086812a9659':
        'Tumelo Scheme 2 (No Investor)',
      'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/1f65bf2e-5494-4dd9-a9d3-be6b92afa66a':
        'Tumelo Scheme 4 (No Ballots)',
      'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/11c3290d-0b69-4adb-b421-77425afb5e3a':
        'Tumelo Scheme 5 (Non Votable Scheme)',
      'habitats/000129c9-b41c-4724-acf6-bd7206b6d6eb/schemes/9b71ab6e-d6e8-48d5-a35f-ad7a15e8e286': 'test-scheme1',
      'habitats/000129c9-b41c-4724-acf6-bd7206b6d6eb/schemes/b2c0ab4a-b054-4704-b23d-f2098de59c23': 'test-scheme2',
      'habitats/47f7c394-8365-4bce-a2aa-3258c34a927b/schemes/bff5cda5-e2f0-4809-a414-51ca630bebac': 'SizpPNSbeP',
      'habitats/929c5dfa-124e-4fdc-868f-0745b41d09e5/schemes/a341efde-524c-49d7-a90d-c4e11408f430': 'pwgDfBqnGa',
      'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/15b78782-4669-4bd0-92ce-ad8fabd4f12d':
        'Tumelo Scheme 3 (No Investor Account)',
      'habitats/d6d2fd69-b5d3-4eaf-b277-89eb1708d5b7/schemes/09227978-9d00-4efc-955d-ac035700996b':
        'Tumelo Scheme 6 (Fund Group Scheme)',
    },
    edges: [
      {
        from: 'securityIDTypes/isin/securityIDs/GB8317088120',
        to: 'securityIDTypes/isin/securityIDs/GB8317088111',
        weight: 0.3,
      },
      {
        from: 'securityIDTypes/isin/securityIDs/GB8317088120',
        to: 'securityIDTypes/isin/securityIDs/GB8317088000',
        weight: 0.1,
      },
      {
        from: 'securityIDTypes/isin/securityIDs/GB8317088444',
        to: 'securityIDTypes/isin/securityIDs/GB8317088222',
        weight: 0.75,
      },
      {
        from: 'securityIDTypes/isin/securityIDs/GB8317088444',
        to: 'securityIDTypes/isin/securityIDs/GB8317088333',
        weight: 0.25,
      },
    ],

    ...override,
  })
