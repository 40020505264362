import { libFundVariant } from '@tumelo/common'
import { MisalignmentReportRow_FundVote } from '../../proto/tumelo/stewardshipreporting/v1/misalignment_report'
import { Option } from '../../proto/tumelo/stewardshipreporting/v1/option'

export const sampleFundVote = (override?: Partial<MisalignmentReportRow_FundVote>): MisalignmentReportRow_FundVote => {
  return {
    fundVariantName: libFundVariant.IDToName({
      fundID: '3af76256-3dac-41e4-bfef-4791c469d54d',
      variantID: 'c5ad1126-3c8c-4072-acd8-3fd514cfd185',
    }),
    fundVariantTitle: 'Sample Fund Variant',
    instruction: Option.OPTION_FOR,
    rationale: 'Rationale 1',
    ...override,
  }
}
