/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
export var protobufPackage = "tumelo.fundmanager.v1";
/** ContactStatusOption describes a contact status between Tumelo and a fund manager */
export var ContactStatusOption;
(function (ContactStatusOption) {
    ContactStatusOption[ContactStatusOption["CONTACT_STATUS_INVALID"] = 0] = "CONTACT_STATUS_INVALID";
    ContactStatusOption[ContactStatusOption["UNKNOWN"] = 1] = "UNKNOWN";
    ContactStatusOption[ContactStatusOption["NO_CONTACT"] = 2] = "NO_CONTACT";
    ContactStatusOption[ContactStatusOption["INITIAL_CONTACT_MADE"] = 3] = "INITIAL_CONTACT_MADE";
    ContactStatusOption[ContactStatusOption["DISCUSSIONS_IN_PROGRESS"] = 4] = "DISCUSSIONS_IN_PROGRESS";
    ContactStatusOption[ContactStatusOption["INITIAL_DATA_RECEIVED"] = 5] = "INITIAL_DATA_RECEIVED";
    ContactStatusOption[ContactStatusOption["DATA_FEED_ESTABLISHED"] = 6] = "DATA_FEED_ESTABLISHED";
    ContactStatusOption[ContactStatusOption["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ContactStatusOption || (ContactStatusOption = {}));
export function contactStatusOptionFromJSON(object) {
    switch (object) {
        case 0:
        case "CONTACT_STATUS_INVALID":
            return ContactStatusOption.CONTACT_STATUS_INVALID;
        case 1:
        case "UNKNOWN":
            return ContactStatusOption.UNKNOWN;
        case 2:
        case "NO_CONTACT":
            return ContactStatusOption.NO_CONTACT;
        case 3:
        case "INITIAL_CONTACT_MADE":
            return ContactStatusOption.INITIAL_CONTACT_MADE;
        case 4:
        case "DISCUSSIONS_IN_PROGRESS":
            return ContactStatusOption.DISCUSSIONS_IN_PROGRESS;
        case 5:
        case "INITIAL_DATA_RECEIVED":
            return ContactStatusOption.INITIAL_DATA_RECEIVED;
        case 6:
        case "DATA_FEED_ESTABLISHED":
            return ContactStatusOption.DATA_FEED_ESTABLISHED;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ContactStatusOption.UNRECOGNIZED;
    }
}
export function contactStatusOptionToJSON(object) {
    switch (object) {
        case ContactStatusOption.CONTACT_STATUS_INVALID:
            return "CONTACT_STATUS_INVALID";
        case ContactStatusOption.UNKNOWN:
            return "UNKNOWN";
        case ContactStatusOption.NO_CONTACT:
            return "NO_CONTACT";
        case ContactStatusOption.INITIAL_CONTACT_MADE:
            return "INITIAL_CONTACT_MADE";
        case ContactStatusOption.DISCUSSIONS_IN_PROGRESS:
            return "DISCUSSIONS_IN_PROGRESS";
        case ContactStatusOption.INITIAL_DATA_RECEIVED:
            return "INITIAL_DATA_RECEIVED";
        case ContactStatusOption.DATA_FEED_ESTABLISHED:
            return "DATA_FEED_ESTABLISHED";
        case ContactStatusOption.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseContactStatus() {
    return { name: "", createTime: undefined, updateTime: undefined, status: 0 };
}
export var ContactStatus = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.updateTime !== undefined) {
            Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(34).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(8).int32(message.status);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseContactStatus();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.status = reader.int32();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
            status: isSet(object.status) ? contactStatusOptionFromJSON(object.status) : 0,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.updateTime !== undefined) {
            obj.updateTime = message.updateTime.toISOString();
        }
        if (message.status !== 0) {
            obj.status = contactStatusOptionToJSON(message.status);
        }
        return obj;
    },
    create: function (base) {
        return ContactStatus.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d;
        var message = createBaseContactStatus();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.createTime = (_b = object.createTime) !== null && _b !== void 0 ? _b : undefined;
        message.updateTime = (_c = object.updateTime) !== null && _c !== void 0 ? _c : undefined;
        message.status = (_d = object.status) !== null && _d !== void 0 ? _d : 0;
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
