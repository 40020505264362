/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Option, optionFromJSON, optionToJSON } from "./option";

export const protobufPackage = "tumelo.poll.v1";

/** GroupedVoteComments is a group of anonymized vote comments with a key that can be used in a map */
export interface GroupedVoteComments {
  key: string;
  pollVoteComments: PollVoteComments[];
}

/** PollVoteComments contains anonymized votes and comments for a poll */
export interface PollVoteComments {
  /** polls/{uuid} */
  pollName: string;
  voteCommentPairs: VoteCommentPair[];
}

/** VoteCommentPair is an anonymized vote with its accompanying comment */
export interface VoteCommentPair {
  vote: Option;
  comment: string;
}

function createBaseGroupedVoteComments(): GroupedVoteComments {
  return { key: "", pollVoteComments: [] };
}

export const GroupedVoteComments = {
  encode(message: GroupedVoteComments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    for (const v of message.pollVoteComments) {
      PollVoteComments.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GroupedVoteComments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGroupedVoteComments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollVoteComments.push(PollVoteComments.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GroupedVoteComments {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      pollVoteComments: globalThis.Array.isArray(object?.pollVoteComments)
        ? object.pollVoteComments.map((e: any) => PollVoteComments.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GroupedVoteComments): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.pollVoteComments?.length) {
      obj.pollVoteComments = message.pollVoteComments.map((e) => PollVoteComments.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GroupedVoteComments>, I>>(base?: I): GroupedVoteComments {
    return GroupedVoteComments.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GroupedVoteComments>, I>>(object: I): GroupedVoteComments {
    const message = createBaseGroupedVoteComments();
    message.key = object.key ?? "";
    message.pollVoteComments = object.pollVoteComments?.map((e) => PollVoteComments.fromPartial(e)) || [];
    return message;
  },
};

function createBasePollVoteComments(): PollVoteComments {
  return { pollName: "", voteCommentPairs: [] };
}

export const PollVoteComments = {
  encode(message: PollVoteComments, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollName !== "") {
      writer.uint32(10).string(message.pollName);
    }
    for (const v of message.voteCommentPairs) {
      VoteCommentPair.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollVoteComments {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollVoteComments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.voteCommentPairs.push(VoteCommentPair.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollVoteComments {
    return {
      pollName: isSet(object.pollName) ? String(object.pollName) : "",
      voteCommentPairs: globalThis.Array.isArray(object?.voteCommentPairs)
        ? object.voteCommentPairs.map((e: any) => VoteCommentPair.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PollVoteComments): unknown {
    const obj: any = {};
    if (message.pollName !== "") {
      obj.pollName = message.pollName;
    }
    if (message.voteCommentPairs?.length) {
      obj.voteCommentPairs = message.voteCommentPairs.map((e) => VoteCommentPair.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollVoteComments>, I>>(base?: I): PollVoteComments {
    return PollVoteComments.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollVoteComments>, I>>(object: I): PollVoteComments {
    const message = createBasePollVoteComments();
    message.pollName = object.pollName ?? "";
    message.voteCommentPairs = object.voteCommentPairs?.map((e) => VoteCommentPair.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVoteCommentPair(): VoteCommentPair {
  return { vote: 0, comment: "" };
}

export const VoteCommentPair = {
  encode(message: VoteCommentPair, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vote !== 0) {
      writer.uint32(8).int32(message.vote);
    }
    if (message.comment !== "") {
      writer.uint32(18).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoteCommentPair {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoteCommentPair();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.vote = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoteCommentPair {
    return {
      vote: isSet(object.vote) ? optionFromJSON(object.vote) : 0,
      comment: isSet(object.comment) ? String(object.comment) : "",
    };
  },

  toJSON(message: VoteCommentPair): unknown {
    const obj: any = {};
    if (message.vote !== 0) {
      obj.vote = optionToJSON(message.vote);
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoteCommentPair>, I>>(base?: I): VoteCommentPair {
    return VoteCommentPair.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoteCommentPair>, I>>(object: I): VoteCommentPair {
    const message = createBaseVoteCommentPair();
    message.vote = object.vote ?? 0;
    message.comment = object.comment ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
