/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { OrganizationAddress } from "./organization_address";
import { OrganizationBio } from "./organization_bio";

export const protobufPackage = "tumelo.transparency.v1";

/** Organization is an abstraction of a general entity that may have a composition */
export interface Organization {
  /**
   * name GENERATED is a unique identifier for organization that is only to be used within the organization context.
   * It is of format "organizations/{uuid}"
   */
  name: string;
  /** create_time GENERATED is the time the organization was added to the repository */
  createTime:
    | Date
    | undefined;
  /** update_time GENERATED is the time the organization was last updated */
  updateTime:
    | Date
    | undefined;
  /** legal_title REQUIRED is the legal name of the organizations */
  legalTitle: string;
  /**
   * display_name REQUIRED is the title used for display purposes
   * Example would be Apple for Apple Inc.
   */
  displayTitle: string;
  /** externalIdentifiers REQUIRED are an array of identifiers, each of which resolves to the organization. */
  externalIdentifiers: OrganizationIdentifier[];
  /** url OPTIONAL is the link to the organizations website */
  url: string;
  /** logo_url OPTIONAL is a https link to a logo of the organization. It must be hosted by cloudinary. */
  logoUrl: string;
  /** logo_thumbnail_url is an https link to a thumbnail sized logo of the organization. It must be hosted by cloudinary. */
  logoThumbnailUrl: string;
  /**
   * trbc OPTIONAL is a Thomson Reuters Business Classification that is used to classify the organization if it is a
   * business. It encodes information about the sector the organization operates in.
   */
  trbc: string;
  /** bio OPTIONAL is a short description of the organization. */
  bio:
    | OrganizationBio
    | undefined;
  /** issued_isins OPTIONAL is a list of isins associated with this organization */
  issuedIsins: string[];
  /** address OPTIONAL is an address of the headquarters */
  headquarters:
    | OrganizationAddress
    | undefined;
  /** industry OPTIONAL is the industry that the organization operates in */
  industry:
    | Industry
    | undefined;
  /** country_of_incorporation_iso2 OPTIONAL is the country the organizaton is incorporated in */
  countryOfIncorporationIso2: string;
}

/**
 * OrganizationIdentifier is a way of identifying an organization. Each organization must have at least one but can only
 * have one of each type
 */
export interface OrganizationIdentifier {
  /** type REQUIRED is the particular identifier used */
  type: OrganizationIdentifier_Type;
  /** value REQUIRED is the string representation of the specified type */
  value: string;
}

/** Type is the type of organizational identifier */
export enum OrganizationIdentifier_Type {
  ORGANIZATION_IDENTIFIER_TYPE_INVALID = 0,
  ORGANIZATION_IDENTIFIER_TYPE_LEI = 1,
  ORGANIZATION_IDENTIFIER_TYPE_DUNS = 2,
  /** ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID - ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID represents an identifier that Exchange Data International (EDI) uses to uniquely identify organizations internally. This type is intended to be used by internal data processes only and should not be publicly exposed. */
  ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID = 3,
  UNRECOGNIZED = -1,
}

export function organizationIdentifier_TypeFromJSON(object: any): OrganizationIdentifier_Type {
  switch (object) {
    case 0:
    case "ORGANIZATION_IDENTIFIER_TYPE_INVALID":
      return OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_INVALID;
    case 1:
    case "ORGANIZATION_IDENTIFIER_TYPE_LEI":
      return OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_LEI;
    case 2:
    case "ORGANIZATION_IDENTIFIER_TYPE_DUNS":
      return OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_DUNS;
    case 3:
    case "ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID":
      return OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrganizationIdentifier_Type.UNRECOGNIZED;
  }
}

export function organizationIdentifier_TypeToJSON(object: OrganizationIdentifier_Type): string {
  switch (object) {
    case OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_INVALID:
      return "ORGANIZATION_IDENTIFIER_TYPE_INVALID";
    case OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_LEI:
      return "ORGANIZATION_IDENTIFIER_TYPE_LEI";
    case OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_DUNS:
      return "ORGANIZATION_IDENTIFIER_TYPE_DUNS";
    case OrganizationIdentifier_Type.ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID:
      return "ORGANIZATION_IDENTIFIER_TYPE_EDI_ISSUER_ID";
    case OrganizationIdentifier_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Industry {
  /** required the NAICS 2017 classification */
  naics2017: Industry_Naics | undefined;
}

export interface Industry_Naics {
  /** code REQUIRED is the code 2-6 digit naics code */
  code: string;
  /** title GENERATED is the title of the industry corresponding to code */
  title: string;
}

function createBaseOrganization(): Organization {
  return {
    name: "",
    createTime: undefined,
    updateTime: undefined,
    legalTitle: "",
    displayTitle: "",
    externalIdentifiers: [],
    url: "",
    logoUrl: "",
    logoThumbnailUrl: "",
    trbc: "",
    bio: undefined,
    issuedIsins: [],
    headquarters: undefined,
    industry: undefined,
    countryOfIncorporationIso2: "",
  };
}

export const Organization = {
  encode(message: Organization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.legalTitle !== "") {
      writer.uint32(34).string(message.legalTitle);
    }
    if (message.displayTitle !== "") {
      writer.uint32(74).string(message.displayTitle);
    }
    for (const v of message.externalIdentifiers) {
      OrganizationIdentifier.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.url !== "") {
      writer.uint32(50).string(message.url);
    }
    if (message.logoUrl !== "") {
      writer.uint32(58).string(message.logoUrl);
    }
    if (message.logoThumbnailUrl !== "") {
      writer.uint32(82).string(message.logoThumbnailUrl);
    }
    if (message.trbc !== "") {
      writer.uint32(66).string(message.trbc);
    }
    if (message.bio !== undefined) {
      OrganizationBio.encode(message.bio, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.issuedIsins) {
      writer.uint32(106).string(v!);
    }
    if (message.headquarters !== undefined) {
      OrganizationAddress.encode(message.headquarters, writer.uint32(114).fork()).ldelim();
    }
    if (message.industry !== undefined) {
      Industry.encode(message.industry, writer.uint32(122).fork()).ldelim();
    }
    if (message.countryOfIncorporationIso2 !== "") {
      writer.uint32(130).string(message.countryOfIncorporationIso2);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.legalTitle = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.displayTitle = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.externalIdentifiers.push(OrganizationIdentifier.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.url = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.logoThumbnailUrl = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.trbc = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bio = OrganizationBio.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.issuedIsins.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.headquarters = OrganizationAddress.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.industry = Industry.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.countryOfIncorporationIso2 = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Organization {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      legalTitle: isSet(object.legalTitle) ? String(object.legalTitle) : "",
      displayTitle: isSet(object.displayTitle) ? String(object.displayTitle) : "",
      externalIdentifiers: globalThis.Array.isArray(object?.externalIdentifiers)
        ? object.externalIdentifiers.map((e: any) => OrganizationIdentifier.fromJSON(e))
        : [],
      url: isSet(object.url) ? String(object.url) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      logoThumbnailUrl: isSet(object.logoThumbnailUrl) ? String(object.logoThumbnailUrl) : "",
      trbc: isSet(object.trbc) ? String(object.trbc) : "",
      bio: isSet(object.bio) ? OrganizationBio.fromJSON(object.bio) : undefined,
      issuedIsins: globalThis.Array.isArray(object?.issuedIsins) ? object.issuedIsins.map((e: any) => String(e)) : [],
      headquarters: isSet(object.headquarters) ? OrganizationAddress.fromJSON(object.headquarters) : undefined,
      industry: isSet(object.industry) ? Industry.fromJSON(object.industry) : undefined,
      countryOfIncorporationIso2: isSet(object.countryOfIncorporationIso2)
        ? String(object.countryOfIncorporationIso2)
        : "",
    };
  },

  toJSON(message: Organization): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.legalTitle !== "") {
      obj.legalTitle = message.legalTitle;
    }
    if (message.displayTitle !== "") {
      obj.displayTitle = message.displayTitle;
    }
    if (message.externalIdentifiers?.length) {
      obj.externalIdentifiers = message.externalIdentifiers.map((e) => OrganizationIdentifier.toJSON(e));
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.logoThumbnailUrl !== "") {
      obj.logoThumbnailUrl = message.logoThumbnailUrl;
    }
    if (message.trbc !== "") {
      obj.trbc = message.trbc;
    }
    if (message.bio !== undefined) {
      obj.bio = OrganizationBio.toJSON(message.bio);
    }
    if (message.issuedIsins?.length) {
      obj.issuedIsins = message.issuedIsins;
    }
    if (message.headquarters !== undefined) {
      obj.headquarters = OrganizationAddress.toJSON(message.headquarters);
    }
    if (message.industry !== undefined) {
      obj.industry = Industry.toJSON(message.industry);
    }
    if (message.countryOfIncorporationIso2 !== "") {
      obj.countryOfIncorporationIso2 = message.countryOfIncorporationIso2;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Organization>, I>>(base?: I): Organization {
    return Organization.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Organization>, I>>(object: I): Organization {
    const message = createBaseOrganization();
    message.name = object.name ?? "";
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.legalTitle = object.legalTitle ?? "";
    message.displayTitle = object.displayTitle ?? "";
    message.externalIdentifiers = object.externalIdentifiers?.map((e) => OrganizationIdentifier.fromPartial(e)) || [];
    message.url = object.url ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.logoThumbnailUrl = object.logoThumbnailUrl ?? "";
    message.trbc = object.trbc ?? "";
    message.bio = (object.bio !== undefined && object.bio !== null)
      ? OrganizationBio.fromPartial(object.bio)
      : undefined;
    message.issuedIsins = object.issuedIsins?.map((e) => e) || [];
    message.headquarters = (object.headquarters !== undefined && object.headquarters !== null)
      ? OrganizationAddress.fromPartial(object.headquarters)
      : undefined;
    message.industry = (object.industry !== undefined && object.industry !== null)
      ? Industry.fromPartial(object.industry)
      : undefined;
    message.countryOfIncorporationIso2 = object.countryOfIncorporationIso2 ?? "";
    return message;
  },
};

function createBaseOrganizationIdentifier(): OrganizationIdentifier {
  return { type: 0, value: "" };
}

export const OrganizationIdentifier = {
  encode(message: OrganizationIdentifier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationIdentifier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationIdentifier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationIdentifier {
    return {
      type: isSet(object.type) ? organizationIdentifier_TypeFromJSON(object.type) : 0,
      value: isSet(object.value) ? String(object.value) : "",
    };
  },

  toJSON(message: OrganizationIdentifier): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = organizationIdentifier_TypeToJSON(message.type);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationIdentifier>, I>>(base?: I): OrganizationIdentifier {
    return OrganizationIdentifier.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrganizationIdentifier>, I>>(object: I): OrganizationIdentifier {
    const message = createBaseOrganizationIdentifier();
    message.type = object.type ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseIndustry(): Industry {
  return { naics2017: undefined };
}

export const Industry = {
  encode(message: Industry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.naics2017 !== undefined) {
      Industry_Naics.encode(message.naics2017, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Industry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIndustry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.naics2017 = Industry_Naics.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Industry {
    return { naics2017: isSet(object.naics2017) ? Industry_Naics.fromJSON(object.naics2017) : undefined };
  },

  toJSON(message: Industry): unknown {
    const obj: any = {};
    if (message.naics2017 !== undefined) {
      obj.naics2017 = Industry_Naics.toJSON(message.naics2017);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Industry>, I>>(base?: I): Industry {
    return Industry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Industry>, I>>(object: I): Industry {
    const message = createBaseIndustry();
    message.naics2017 = (object.naics2017 !== undefined && object.naics2017 !== null)
      ? Industry_Naics.fromPartial(object.naics2017)
      : undefined;
    return message;
  },
};

function createBaseIndustry_Naics(): Industry_Naics {
  return { code: "", title: "" };
}

export const Industry_Naics = {
  encode(message: Industry_Naics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Industry_Naics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIndustry_Naics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Industry_Naics {
    return {
      code: isSet(object.code) ? String(object.code) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: Industry_Naics): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Industry_Naics>, I>>(base?: I): Industry_Naics {
    return Industry_Naics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Industry_Naics>, I>>(object: I): Industry_Naics {
    const message = createBaseIndustry_Naics();
    message.code = object.code ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
