import { libInvestorAccount } from '.';
// Auto-ordering on save disagrees with eslint
// eslint-disable-next-line import/order
import { SEPARATOR } from './genericName';
export var prefix = 'compositions';
export var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, investorID = _a.investorID, investorAccountID = _a.investorAccountID, investorAccountCompositionID = _a.investorAccountCompositionID;
    return (libInvestorAccount.IDToName({ habitatID: habitatID, investorID: investorID, investorAccountID: investorAccountID }) +
        SEPARATOR +
        prefixWithSeparator +
        investorAccountCompositionID);
};
