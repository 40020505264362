import { DataRequirement_RequirementType, DataRequirement_Status, } from '../proto/tumelo/fundmanager/v1/data_requirement';
import { libFundRequirement, libFundUniverse, libProvider } from '.';
export var requirementTypeToString = function (o) {
    switch (o) {
        case DataRequirement_RequirementType.REQUIREMENT_TYPE_INVALID:
            return 'Invalid';
        case DataRequirement_RequirementType.UNRECOGNIZED:
            return 'Unrecognized';
        case DataRequirement_RequirementType.UNDERLYING_HOLDINGS:
            return 'Underlying holdings';
        case DataRequirement_RequirementType.FUND_MANAGER_VOTES:
            return 'Fund manager votes';
        case DataRequirement_RequirementType.FUND_MANAGER_SIGNIFICANT_VOTES:
            return 'Fund manager significant votes';
        case DataRequirement_RequirementType.PLSA_STATISTICS:
            return 'PLSA statistics';
    }
};
export var statusToString = function (o) {
    switch (o) {
        case DataRequirement_Status.STATUS_INVALID:
            return 'Invalid';
        case DataRequirement_Status.UNRECOGNIZED:
            return 'Unrecognized';
        case DataRequirement_Status.STATUS_UNAVAILABLE:
            return 'Unavailable';
        case DataRequirement_Status.STATUS_REQUESTED:
            return 'Requested';
        case DataRequirement_Status.STATUS_RECEIVED:
            return 'Received';
        case DataRequirement_Status.STATUS_AVAILABLE:
            return 'Available';
        case DataRequirement_Status.STATUS_REFUSED:
            return 'Refused';
        case DataRequirement_Status.STATUS_PENDING_OUTREACH:
            return 'Pending Outreach';
    }
};
export var prefix = 'dataRequirements/';
export var IDToName = function (_a) {
    var providerID = _a.providerID, universeID = _a.universeID, requirementID = _a.requirementID, dataRequirementID = _a.dataRequirementID;
    return libFundRequirement.IDToName({ providerID: providerID, universeID: universeID, requirementID: requirementID }) + '/' + prefix + dataRequirementID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 8 ||
        !name.startsWith(libProvider.prefix) ||
        !name.includes(libFundUniverse.prefix) ||
        !name.includes(libFundRequirement.prefix) ||
        !name.includes(prefix)) {
        throw new Error("bad data requirement name: ".concat(name));
    }
    return {
        providerID: parts[1],
        universeID: parts[3],
        requirementID: parts[5],
        dataRequirementID: parts[7],
    };
};
