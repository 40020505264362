/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

export interface FundManager {
  /** The fund manager identifier. */
  id: string;
  /** The name of the fund manager. */
  title: string;
  /** OPTIONAL A URL to the fund manager's website. */
  websiteUrl: string;
  /** OPTIONAL A URL to the fund manager's logo (hosted by cloudinary). */
  logoUrl: string;
  /** OPTIONAL A URL to a thumbnail of the fund manager's logo (hosted by cloudinary). */
  logoThumbnailUrl: string;
}

export interface FundManagerPolicy {
  /** The fund manager policy identifier. */
  id: string;
  /** The title for the policy document. */
  title: string;
  /** A URL that allows access to the policy document for 24 hours. */
  signedUrl: string;
}

function createBaseFundManager(): FundManager {
  return { id: "", title: "", websiteUrl: "", logoUrl: "", logoThumbnailUrl: "" };
}

export const FundManager = {
  encode(message: FundManager, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.websiteUrl !== "") {
      writer.uint32(26).string(message.websiteUrl);
    }
    if (message.logoUrl !== "") {
      writer.uint32(34).string(message.logoUrl);
    }
    if (message.logoThumbnailUrl !== "") {
      writer.uint32(42).string(message.logoThumbnailUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FundManager {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFundManager();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.websiteUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.logoThumbnailUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FundManager {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      websiteUrl: isSet(object.websiteUrl) ? String(object.websiteUrl) : "",
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      logoThumbnailUrl: isSet(object.logoThumbnailUrl) ? String(object.logoThumbnailUrl) : "",
    };
  },

  toJSON(message: FundManager): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.websiteUrl !== "") {
      obj.websiteUrl = message.websiteUrl;
    }
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.logoThumbnailUrl !== "") {
      obj.logoThumbnailUrl = message.logoThumbnailUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FundManager>, I>>(base?: I): FundManager {
    return FundManager.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FundManager>, I>>(object: I): FundManager {
    const message = createBaseFundManager();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.websiteUrl = object.websiteUrl ?? "";
    message.logoUrl = object.logoUrl ?? "";
    message.logoThumbnailUrl = object.logoThumbnailUrl ?? "";
    return message;
  },
};

function createBaseFundManagerPolicy(): FundManagerPolicy {
  return { id: "", title: "", signedUrl: "" };
}

export const FundManagerPolicy = {
  encode(message: FundManagerPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.signedUrl !== "") {
      writer.uint32(26).string(message.signedUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FundManagerPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFundManagerPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signedUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FundManagerPolicy {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      signedUrl: isSet(object.signedUrl) ? String(object.signedUrl) : "",
    };
  },

  toJSON(message: FundManagerPolicy): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.signedUrl !== "") {
      obj.signedUrl = message.signedUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FundManagerPolicy>, I>>(base?: I): FundManagerPolicy {
    return FundManagerPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FundManagerPolicy>, I>>(object: I): FundManagerPolicy {
    const message = createBaseFundManagerPolicy();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.signedUrl = object.signedUrl ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
