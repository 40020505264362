import styled from 'styled-components'
import { screen, VisuallyHidden } from '@tumelo/designsystem'

interface Props {
  firstName: string
  lastName: string
}

export const AvatarWithInitials: React.FC<Props> = ({ firstName, lastName }) => {
  const getInitials = (firstName: string, lastName: string) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : ''
    const lastInitial = lastName ? lastName[0].toUpperCase() : ''
    return firstInitial + lastInitial
  }

  return (
    <StyledAvatar>
      {getInitials(firstName, lastName)} <VisuallyHidden> profile</VisuallyHidden>
    </StyledAvatar>
  )
}

const StyledAvatar = styled.div`
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.success.medium};
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.caption.mobile};
  @media ${screen.mediumUp} {
    font-size: ${({ theme }) => theme.fontSizes.caption.default};
  }
`
