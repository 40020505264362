import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
export var prefix = 'habitats';
export var prefixWithSeparator = 'habitats' + SEPARATOR;
export var IDToName = function (_a) {
    var habitatID = _a.habitatID;
    return prefixWithSeparator + habitatID;
};
export var validateName = genericValidateName([exactValidator(prefix), uuidValidator()]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split('/');
    return {
        habitatID: parts[1],
    };
};
