/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { ProposalVotes } from "./proposal_votes";
export var protobufPackage = "tumelo.investor.v1";
export var ProxyIntermediary;
(function (ProxyIntermediary) {
    ProxyIntermediary[ProxyIntermediary["PROXY_INTERMEDIARY_INVALID"] = 0] = "PROXY_INTERMEDIARY_INVALID";
    ProxyIntermediary[ProxyIntermediary["PROXY_INTERMEDIARY_PROXYMITY"] = 1] = "PROXY_INTERMEDIARY_PROXYMITY";
    ProxyIntermediary[ProxyIntermediary["PROXY_INTERMEDIARY_PROXYEDGE"] = 2] = "PROXY_INTERMEDIARY_PROXYEDGE";
    ProxyIntermediary[ProxyIntermediary["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(ProxyIntermediary || (ProxyIntermediary = {}));
export function proxyIntermediaryFromJSON(object) {
    switch (object) {
        case 0:
        case "PROXY_INTERMEDIARY_INVALID":
            return ProxyIntermediary.PROXY_INTERMEDIARY_INVALID;
        case 1:
        case "PROXY_INTERMEDIARY_PROXYMITY":
            return ProxyIntermediary.PROXY_INTERMEDIARY_PROXYMITY;
        case 2:
        case "PROXY_INTERMEDIARY_PROXYEDGE":
            return ProxyIntermediary.PROXY_INTERMEDIARY_PROXYEDGE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ProxyIntermediary.UNRECOGNIZED;
    }
}
export function proxyIntermediaryToJSON(object) {
    switch (object) {
        case ProxyIntermediary.PROXY_INTERMEDIARY_INVALID:
            return "PROXY_INTERMEDIARY_INVALID";
        case ProxyIntermediary.PROXY_INTERMEDIARY_PROXYMITY:
            return "PROXY_INTERMEDIARY_PROXYMITY";
        case ProxyIntermediary.PROXY_INTERMEDIARY_PROXYEDGE:
            return "PROXY_INTERMEDIARY_PROXYEDGE";
        case ProxyIntermediary.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/**
 * SplitVoteInstructionSubmissionAcknowledgementStatus defines the statuses of
 * a split vote instruction submission acknowledgement: ACCEPTED, REJECTED, CONFIRMED, OVERRIDDEN
 */
export var SplitVoteInstructionSubmissionAcknowledgementStatus;
(function (SplitVoteInstructionSubmissionAcknowledgementStatus) {
    SplitVoteInstructionSubmissionAcknowledgementStatus[SplitVoteInstructionSubmissionAcknowledgementStatus["SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_INVALID"] = 0] = "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_INVALID";
    SplitVoteInstructionSubmissionAcknowledgementStatus[SplitVoteInstructionSubmissionAcknowledgementStatus["SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_ACCEPTED"] = 1] = "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_ACCEPTED";
    SplitVoteInstructionSubmissionAcknowledgementStatus[SplitVoteInstructionSubmissionAcknowledgementStatus["SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_REJECTED"] = 2] = "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_REJECTED";
    SplitVoteInstructionSubmissionAcknowledgementStatus[SplitVoteInstructionSubmissionAcknowledgementStatus["SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_CONFIRMED"] = 3] = "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_CONFIRMED";
    SplitVoteInstructionSubmissionAcknowledgementStatus[SplitVoteInstructionSubmissionAcknowledgementStatus["SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_OVERRIDDEN"] = 4] = "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_OVERRIDDEN";
    SplitVoteInstructionSubmissionAcknowledgementStatus[SplitVoteInstructionSubmissionAcknowledgementStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(SplitVoteInstructionSubmissionAcknowledgementStatus || (SplitVoteInstructionSubmissionAcknowledgementStatus = {}));
export function splitVoteInstructionSubmissionAcknowledgementStatusFromJSON(object) {
    switch (object) {
        case 0:
        case "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_INVALID":
            return SplitVoteInstructionSubmissionAcknowledgementStatus
                .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_INVALID;
        case 1:
        case "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_ACCEPTED":
            return SplitVoteInstructionSubmissionAcknowledgementStatus
                .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_ACCEPTED;
        case 2:
        case "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_REJECTED":
            return SplitVoteInstructionSubmissionAcknowledgementStatus
                .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_REJECTED;
        case 3:
        case "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_CONFIRMED":
            return SplitVoteInstructionSubmissionAcknowledgementStatus
                .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_CONFIRMED;
        case 4:
        case "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_OVERRIDDEN":
            return SplitVoteInstructionSubmissionAcknowledgementStatus
                .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_OVERRIDDEN;
        case -1:
        case "UNRECOGNIZED":
        default:
            return SplitVoteInstructionSubmissionAcknowledgementStatus.UNRECOGNIZED;
    }
}
export function splitVoteInstructionSubmissionAcknowledgementStatusToJSON(object) {
    switch (object) {
        case SplitVoteInstructionSubmissionAcknowledgementStatus
            .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_INVALID:
            return "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_INVALID";
        case SplitVoteInstructionSubmissionAcknowledgementStatus
            .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_ACCEPTED:
            return "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_ACCEPTED";
        case SplitVoteInstructionSubmissionAcknowledgementStatus
            .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_REJECTED:
            return "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_REJECTED";
        case SplitVoteInstructionSubmissionAcknowledgementStatus
            .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_CONFIRMED:
            return "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_CONFIRMED";
        case SplitVoteInstructionSubmissionAcknowledgementStatus
            .SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_OVERRIDDEN:
            return "SPLIT_VOTE_INSTRUCTION_SUBMISSION_ACKNOWLEDGEMENT_STATUS_OVERRIDDEN";
        case SplitVoteInstructionSubmissionAcknowledgementStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseSplitVoteInstruction() {
    return {
        name: "",
        fundName: "",
        generalMeetingName: "",
        proxyIntermediaryBallotReference: undefined,
        votedIssuedInstrumentSecurityIdNames: [],
        votedFundVariantSecurityIdNames: [],
        totalVotes: 0,
        createTime: undefined,
        proposals: [],
        includesNonReportingHabitats: false,
        submissions: [],
        proxyIntermediaryBallotExternalId: "",
    };
}
export var SplitVoteInstruction = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.fundName !== "") {
            writer.uint32(18).string(message.fundName);
        }
        if (message.generalMeetingName !== "") {
            writer.uint32(26).string(message.generalMeetingName);
        }
        if (message.proxyIntermediaryBallotReference !== undefined) {
            ProxyIntermediaryBallotReference.encode(message.proxyIntermediaryBallotReference, writer.uint32(98).fork())
                .ldelim();
        }
        for (var _i = 0, _a = message.votedIssuedInstrumentSecurityIdNames; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.uint32(34).string(v);
        }
        for (var _b = 0, _c = message.votedFundVariantSecurityIdNames; _b < _c.length; _b++) {
            var v = _c[_b];
            writer.uint32(42).string(v);
        }
        if (message.totalVotes !== 0) {
            writer.uint32(49).double(message.totalVotes);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(58).fork()).ldelim();
        }
        for (var _d = 0, _e = message.proposals; _d < _e.length; _d++) {
            var v = _e[_d];
            SplitVoteInstructionProposal.encode(v, writer.uint32(66).fork()).ldelim();
        }
        if (message.includesNonReportingHabitats === true) {
            writer.uint32(72).bool(message.includesNonReportingHabitats);
        }
        for (var _f = 0, _g = message.submissions; _f < _g.length; _f++) {
            var v = _g[_f];
            SplitVoteInstructionSubmission.encode(v, writer.uint32(82).fork()).ldelim();
        }
        if (message.proxyIntermediaryBallotExternalId !== "") {
            writer.uint32(90).string(message.proxyIntermediaryBallotExternalId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseSplitVoteInstruction();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.fundName = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.generalMeetingName = reader.string();
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }
                    message.proxyIntermediaryBallotReference = ProxyIntermediaryBallotReference.decode(reader, reader.uint32());
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.votedIssuedInstrumentSecurityIdNames.push(reader.string());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.votedFundVariantSecurityIdNames.push(reader.string());
                    continue;
                case 6:
                    if (tag !== 49) {
                        break;
                    }
                    message.totalVotes = reader.double();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.proposals.push(SplitVoteInstructionProposal.decode(reader, reader.uint32()));
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }
                    message.includesNonReportingHabitats = reader.bool();
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.submissions.push(SplitVoteInstructionSubmission.decode(reader, reader.uint32()));
                    continue;
                case 11:
                    if (tag !== 90) {
                        break;
                    }
                    message.proxyIntermediaryBallotExternalId = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            fundName: isSet(object.fundName) ? String(object.fundName) : "",
            generalMeetingName: isSet(object.generalMeetingName) ? String(object.generalMeetingName) : "",
            proxyIntermediaryBallotReference: isSet(object.proxyIntermediaryBallotReference)
                ? ProxyIntermediaryBallotReference.fromJSON(object.proxyIntermediaryBallotReference)
                : undefined,
            votedIssuedInstrumentSecurityIdNames: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.votedIssuedInstrumentSecurityIdNames)
                ? object.votedIssuedInstrumentSecurityIdNames.map(function (e) { return String(e); })
                : [],
            votedFundVariantSecurityIdNames: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.votedFundVariantSecurityIdNames)
                ? object.votedFundVariantSecurityIdNames.map(function (e) { return String(e); })
                : [],
            totalVotes: isSet(object.totalVotes) ? Number(object.totalVotes) : 0,
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            proposals: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.proposals)
                ? object.proposals.map(function (e) { return SplitVoteInstructionProposal.fromJSON(e); })
                : [],
            includesNonReportingHabitats: isSet(object.includesNonReportingHabitats)
                ? Boolean(object.includesNonReportingHabitats)
                : false,
            submissions: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.submissions)
                ? object.submissions.map(function (e) { return SplitVoteInstructionSubmission.fromJSON(e); })
                : [],
            proxyIntermediaryBallotExternalId: isSet(object.proxyIntermediaryBallotExternalId)
                ? String(object.proxyIntermediaryBallotExternalId)
                : "",
        };
    },
    toJSON: function (message) {
        var _a, _b, _c, _d;
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.fundName !== "") {
            obj.fundName = message.fundName;
        }
        if (message.generalMeetingName !== "") {
            obj.generalMeetingName = message.generalMeetingName;
        }
        if (message.proxyIntermediaryBallotReference !== undefined) {
            obj.proxyIntermediaryBallotReference = ProxyIntermediaryBallotReference.toJSON(message.proxyIntermediaryBallotReference);
        }
        if ((_a = message.votedIssuedInstrumentSecurityIdNames) === null || _a === void 0 ? void 0 : _a.length) {
            obj.votedIssuedInstrumentSecurityIdNames = message.votedIssuedInstrumentSecurityIdNames;
        }
        if ((_b = message.votedFundVariantSecurityIdNames) === null || _b === void 0 ? void 0 : _b.length) {
            obj.votedFundVariantSecurityIdNames = message.votedFundVariantSecurityIdNames;
        }
        if (message.totalVotes !== 0) {
            obj.totalVotes = message.totalVotes;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if ((_c = message.proposals) === null || _c === void 0 ? void 0 : _c.length) {
            obj.proposals = message.proposals.map(function (e) { return SplitVoteInstructionProposal.toJSON(e); });
        }
        if (message.includesNonReportingHabitats === true) {
            obj.includesNonReportingHabitats = message.includesNonReportingHabitats;
        }
        if ((_d = message.submissions) === null || _d === void 0 ? void 0 : _d.length) {
            obj.submissions = message.submissions.map(function (e) { return SplitVoteInstructionSubmission.toJSON(e); });
        }
        if (message.proxyIntermediaryBallotExternalId !== "") {
            obj.proxyIntermediaryBallotExternalId = message.proxyIntermediaryBallotExternalId;
        }
        return obj;
    },
    create: function (base) {
        return SplitVoteInstruction.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var message = createBaseSplitVoteInstruction();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.fundName = (_b = object.fundName) !== null && _b !== void 0 ? _b : "";
        message.generalMeetingName = (_c = object.generalMeetingName) !== null && _c !== void 0 ? _c : "";
        message.proxyIntermediaryBallotReference =
            (object.proxyIntermediaryBallotReference !== undefined && object.proxyIntermediaryBallotReference !== null)
                ? ProxyIntermediaryBallotReference.fromPartial(object.proxyIntermediaryBallotReference)
                : undefined;
        message.votedIssuedInstrumentSecurityIdNames = ((_d = object.votedIssuedInstrumentSecurityIdNames) === null || _d === void 0 ? void 0 : _d.map(function (e) { return e; })) || [];
        message.votedFundVariantSecurityIdNames = ((_e = object.votedFundVariantSecurityIdNames) === null || _e === void 0 ? void 0 : _e.map(function (e) { return e; })) || [];
        message.totalVotes = (_f = object.totalVotes) !== null && _f !== void 0 ? _f : 0;
        message.createTime = (_g = object.createTime) !== null && _g !== void 0 ? _g : undefined;
        message.proposals = ((_h = object.proposals) === null || _h === void 0 ? void 0 : _h.map(function (e) { return SplitVoteInstructionProposal.fromPartial(e); })) || [];
        message.includesNonReportingHabitats = (_j = object.includesNonReportingHabitats) !== null && _j !== void 0 ? _j : false;
        message.submissions = ((_k = object.submissions) === null || _k === void 0 ? void 0 : _k.map(function (e) { return SplitVoteInstructionSubmission.fromPartial(e); })) || [];
        message.proxyIntermediaryBallotExternalId = (_l = object.proxyIntermediaryBallotExternalId) !== null && _l !== void 0 ? _l : "";
        return message;
    },
};
function createBaseProxyIntermediaryBallotReference() {
    return { proxyIntermediary: 0, externalId: "" };
}
export var ProxyIntermediaryBallotReference = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.proxyIntermediary !== 0) {
            writer.uint32(8).int32(message.proxyIntermediary);
        }
        if (message.externalId !== "") {
            writer.uint32(18).string(message.externalId);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProxyIntermediaryBallotReference();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.proxyIntermediary = reader.int32();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.externalId = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            proxyIntermediary: isSet(object.proxyIntermediary) ? proxyIntermediaryFromJSON(object.proxyIntermediary) : 0,
            externalId: isSet(object.externalId) ? String(object.externalId) : "",
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.proxyIntermediary !== 0) {
            obj.proxyIntermediary = proxyIntermediaryToJSON(message.proxyIntermediary);
        }
        if (message.externalId !== "") {
            obj.externalId = message.externalId;
        }
        return obj;
    },
    create: function (base) {
        return ProxyIntermediaryBallotReference.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b;
        var message = createBaseProxyIntermediaryBallotReference();
        message.proxyIntermediary = (_a = object.proxyIntermediary) !== null && _a !== void 0 ? _a : 0;
        message.externalId = (_b = object.externalId) !== null && _b !== void 0 ? _b : "";
        return message;
    },
};
function createBaseSplitVoteInstructionProposal() {
    return {
        proposalName: "",
        fundQuantifiedVoteName: "",
        investorVotes: undefined,
        splitVotes: undefined,
        splitVotesPercentage: undefined,
    };
}
export var SplitVoteInstructionProposal = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.proposalName !== "") {
            writer.uint32(10).string(message.proposalName);
        }
        if (message.fundQuantifiedVoteName !== "") {
            writer.uint32(18).string(message.fundQuantifiedVoteName);
        }
        if (message.investorVotes !== undefined) {
            ProposalVotes.encode(message.investorVotes, writer.uint32(26).fork()).ldelim();
        }
        if (message.splitVotes !== undefined) {
            ProposalVotes.encode(message.splitVotes, writer.uint32(34).fork()).ldelim();
        }
        if (message.splitVotesPercentage !== undefined) {
            ProposalVotes.encode(message.splitVotesPercentage, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseSplitVoteInstructionProposal();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.proposalName = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.fundQuantifiedVoteName = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.investorVotes = ProposalVotes.decode(reader, reader.uint32());
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.splitVotes = ProposalVotes.decode(reader, reader.uint32());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.splitVotesPercentage = ProposalVotes.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            proposalName: isSet(object.proposalName) ? String(object.proposalName) : "",
            fundQuantifiedVoteName: isSet(object.fundQuantifiedVoteName) ? String(object.fundQuantifiedVoteName) : "",
            investorVotes: isSet(object.investorVotes) ? ProposalVotes.fromJSON(object.investorVotes) : undefined,
            splitVotes: isSet(object.splitVotes) ? ProposalVotes.fromJSON(object.splitVotes) : undefined,
            splitVotesPercentage: isSet(object.splitVotesPercentage)
                ? ProposalVotes.fromJSON(object.splitVotesPercentage)
                : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.proposalName !== "") {
            obj.proposalName = message.proposalName;
        }
        if (message.fundQuantifiedVoteName !== "") {
            obj.fundQuantifiedVoteName = message.fundQuantifiedVoteName;
        }
        if (message.investorVotes !== undefined) {
            obj.investorVotes = ProposalVotes.toJSON(message.investorVotes);
        }
        if (message.splitVotes !== undefined) {
            obj.splitVotes = ProposalVotes.toJSON(message.splitVotes);
        }
        if (message.splitVotesPercentage !== undefined) {
            obj.splitVotesPercentage = ProposalVotes.toJSON(message.splitVotesPercentage);
        }
        return obj;
    },
    create: function (base) {
        return SplitVoteInstructionProposal.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b;
        var message = createBaseSplitVoteInstructionProposal();
        message.proposalName = (_a = object.proposalName) !== null && _a !== void 0 ? _a : "";
        message.fundQuantifiedVoteName = (_b = object.fundQuantifiedVoteName) !== null && _b !== void 0 ? _b : "";
        message.investorVotes = (object.investorVotes !== undefined && object.investorVotes !== null)
            ? ProposalVotes.fromPartial(object.investorVotes)
            : undefined;
        message.splitVotes = (object.splitVotes !== undefined && object.splitVotes !== null)
            ? ProposalVotes.fromPartial(object.splitVotes)
            : undefined;
        message.splitVotesPercentage = (object.splitVotesPercentage !== undefined && object.splitVotesPercentage !== null)
            ? ProposalVotes.fromPartial(object.splitVotesPercentage)
            : undefined;
        return message;
    },
};
function createBaseSplitVoteInstructionSubmission() {
    return {
        name: "",
        proxyIntermediaryBallotIdentifier: "",
        proxyIntermediaryName: "",
        submitTime: undefined,
        submittedBy: "",
        notes: "",
        acknowledgement: undefined,
        checkedBy: "",
        checkTime: undefined,
    };
}
export var SplitVoteInstructionSubmission = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.proxyIntermediaryBallotIdentifier !== "") {
            writer.uint32(18).string(message.proxyIntermediaryBallotIdentifier);
        }
        if (message.proxyIntermediaryName !== "") {
            writer.uint32(74).string(message.proxyIntermediaryName);
        }
        if (message.submitTime !== undefined) {
            Timestamp.encode(toTimestamp(message.submitTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.submittedBy !== "") {
            writer.uint32(34).string(message.submittedBy);
        }
        if (message.notes !== "") {
            writer.uint32(42).string(message.notes);
        }
        if (message.acknowledgement !== undefined) {
            SplitVoteInstructionSubmissionAcknowledgement.encode(message.acknowledgement, writer.uint32(50).fork()).ldelim();
        }
        if (message.checkedBy !== "") {
            writer.uint32(58).string(message.checkedBy);
        }
        if (message.checkTime !== undefined) {
            Timestamp.encode(toTimestamp(message.checkTime), writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseSplitVoteInstructionSubmission();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.proxyIntermediaryBallotIdentifier = reader.string();
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }
                    message.proxyIntermediaryName = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.submitTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.submittedBy = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.notes = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.acknowledgement = SplitVoteInstructionSubmissionAcknowledgement.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.checkedBy = reader.string();
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.checkTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            proxyIntermediaryBallotIdentifier: isSet(object.proxyIntermediaryBallotIdentifier)
                ? String(object.proxyIntermediaryBallotIdentifier)
                : "",
            proxyIntermediaryName: isSet(object.proxyIntermediaryName) ? String(object.proxyIntermediaryName) : "",
            submitTime: isSet(object.submitTime) ? fromJsonTimestamp(object.submitTime) : undefined,
            submittedBy: isSet(object.submittedBy) ? String(object.submittedBy) : "",
            notes: isSet(object.notes) ? String(object.notes) : "",
            acknowledgement: isSet(object.acknowledgement)
                ? SplitVoteInstructionSubmissionAcknowledgement.fromJSON(object.acknowledgement)
                : undefined,
            checkedBy: isSet(object.checkedBy) ? String(object.checkedBy) : "",
            checkTime: isSet(object.checkTime) ? fromJsonTimestamp(object.checkTime) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.proxyIntermediaryBallotIdentifier !== "") {
            obj.proxyIntermediaryBallotIdentifier = message.proxyIntermediaryBallotIdentifier;
        }
        if (message.proxyIntermediaryName !== "") {
            obj.proxyIntermediaryName = message.proxyIntermediaryName;
        }
        if (message.submitTime !== undefined) {
            obj.submitTime = message.submitTime.toISOString();
        }
        if (message.submittedBy !== "") {
            obj.submittedBy = message.submittedBy;
        }
        if (message.notes !== "") {
            obj.notes = message.notes;
        }
        if (message.acknowledgement !== undefined) {
            obj.acknowledgement = SplitVoteInstructionSubmissionAcknowledgement.toJSON(message.acknowledgement);
        }
        if (message.checkedBy !== "") {
            obj.checkedBy = message.checkedBy;
        }
        if (message.checkTime !== undefined) {
            obj.checkTime = message.checkTime.toISOString();
        }
        return obj;
    },
    create: function (base) {
        return SplitVoteInstructionSubmission.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var message = createBaseSplitVoteInstructionSubmission();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.proxyIntermediaryBallotIdentifier = (_b = object.proxyIntermediaryBallotIdentifier) !== null && _b !== void 0 ? _b : "";
        message.proxyIntermediaryName = (_c = object.proxyIntermediaryName) !== null && _c !== void 0 ? _c : "";
        message.submitTime = (_d = object.submitTime) !== null && _d !== void 0 ? _d : undefined;
        message.submittedBy = (_e = object.submittedBy) !== null && _e !== void 0 ? _e : "";
        message.notes = (_f = object.notes) !== null && _f !== void 0 ? _f : "";
        message.acknowledgement = (object.acknowledgement !== undefined && object.acknowledgement !== null)
            ? SplitVoteInstructionSubmissionAcknowledgement.fromPartial(object.acknowledgement)
            : undefined;
        message.checkedBy = (_g = object.checkedBy) !== null && _g !== void 0 ? _g : "";
        message.checkTime = (_h = object.checkTime) !== null && _h !== void 0 ? _h : undefined;
        return message;
    },
};
function createBaseSplitVoteInstructionSubmissionAcknowledgement() {
    return { acknowledgeTime: undefined, status: 0, notes: "", createdBy: "", createTime: undefined };
}
export var SplitVoteInstructionSubmissionAcknowledgement = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.acknowledgeTime !== undefined) {
            Timestamp.encode(toTimestamp(message.acknowledgeTime), writer.uint32(10).fork()).ldelim();
        }
        if (message.status !== 0) {
            writer.uint32(16).int32(message.status);
        }
        if (message.notes !== "") {
            writer.uint32(26).string(message.notes);
        }
        if (message.createdBy !== "") {
            writer.uint32(34).string(message.createdBy);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseSplitVoteInstructionSubmissionAcknowledgement();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.acknowledgeTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 2:
                    if (tag !== 16) {
                        break;
                    }
                    message.status = reader.int32();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.notes = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.createdBy = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            acknowledgeTime: isSet(object.acknowledgeTime) ? fromJsonTimestamp(object.acknowledgeTime) : undefined,
            status: isSet(object.status) ? splitVoteInstructionSubmissionAcknowledgementStatusFromJSON(object.status) : 0,
            notes: isSet(object.notes) ? String(object.notes) : "",
            createdBy: isSet(object.createdBy) ? String(object.createdBy) : "",
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.acknowledgeTime !== undefined) {
            obj.acknowledgeTime = message.acknowledgeTime.toISOString();
        }
        if (message.status !== 0) {
            obj.status = splitVoteInstructionSubmissionAcknowledgementStatusToJSON(message.status);
        }
        if (message.notes !== "") {
            obj.notes = message.notes;
        }
        if (message.createdBy !== "") {
            obj.createdBy = message.createdBy;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        return obj;
    },
    create: function (base) {
        return SplitVoteInstructionSubmissionAcknowledgement.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e;
        var message = createBaseSplitVoteInstructionSubmissionAcknowledgement();
        message.acknowledgeTime = (_a = object.acknowledgeTime) !== null && _a !== void 0 ? _a : undefined;
        message.status = (_b = object.status) !== null && _b !== void 0 ? _b : 0;
        message.notes = (_c = object.notes) !== null && _c !== void 0 ? _c : "";
        message.createdBy = (_d = object.createdBy) !== null && _d !== void 0 ? _d : "";
        message.createTime = (_e = object.createTime) !== null && _e !== void 0 ? _e : undefined;
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
