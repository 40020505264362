import moment from 'moment';
import { DateTime } from 'luxon';
var defaultDateFormat = 'D MMM YYYY';
// Returns a formatted date string using moment's date formatting capabilities
export var formattedDate = function (date, format) {
    if (format === void 0) { format = defaultDateFormat; }
    // An undefined date is unknown
    if (date === undefined)
        return '-';
    // A null date is interpreted as 'now' and can be parsed normally
    var dateAsMoment = moment(date);
    return dateAsMoment.format(format);
};
// formatDateTimeWithTimeZoneOffset returns a date string formatted in en-GB locale with the specified timezone.
export var formatDateTimeWithTimeZoneOffset = function (date, timeZone) {
    return new Intl.DateTimeFormat('en-GB', { timeZone: timeZone, dateStyle: 'medium', timeStyle: 'long' }).format(date);
};
// formatDateTimeWithTimeZoneDisplay takes a date and a timezone and returns a formatted string
// If a timeZone is not provided, the date is displayed in the timezone of the supplied date OR in the local timezone
export function formatDateTimeWithTimeZoneDisplay(date, timeZone, dateFormat) {
    var dateToDisplay = DateTime.fromJSDate(new Date(date));
    if (timeZone) {
        return dateToDisplay.setZone(timeZone).toFormat(dateFormat);
    }
    return dateToDisplay.toFormat(dateFormat);
}
