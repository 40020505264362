import React from 'react'
import styled from 'styled-components'

interface PageProps {
  children: React.ReactNode
}

export const LayoutWrapper: React.FC<PageProps> = ({ children }) => {
  return <StyledWrapperDiv>{children}</StyledWrapperDiv>
}

const StyledWrapperDiv = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  overflow: inherit;
  height: 100vh;
`
