import { __awaiter, __generator } from "tslib";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
export var downloadExcel = function (doc) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                workbook = createWorkbook();
                doc.sheets.forEach(function (sheet) {
                    var ws = workbook.addWorksheet(sheet.title);
                    // If there are top info rows, add them to the top of the sheet
                    if (sheet.topInfoRows) {
                        // Add the top info rows
                        for (var i = 0; i < sheet.topInfoRows.length; i++) {
                            ws.getCell("A".concat(i + 1)).value = '' + sheet.topInfoRows[i];
                        }
                        // leave one row as a gap then add header row
                        ws.getRow(sheet.topInfoRows.length + 2).values = sheet.headers.flatMap(function (h) { return h.header; });
                        var keys = [];
                        for (var i = 0; i < sheet.headers.length; i++) {
                            keys.push({ key: sheet.headers[i].key, width: sheet.headers[i].width });
                        }
                        ws.columns = keys;
                    }
                    else {
                        // Otherwise, just add the headers
                        ws.columns = sheet.headers;
                    }
                    // Now add the data rows
                    sheet.data.forEach(function (row) { return ws.addRow(row); });
                });
                _a = saveAs;
                return [4 /*yield*/, workbookToBlob(workbook)];
            case 1:
                _a.apply(void 0, [_b.sent(), doc.name]);
                return [2 /*return*/];
        }
    });
}); };
export var createWorkbook = function () {
    var workbook = new ExcelJS.Workbook();
    workbook.creator = 'Tumelo';
    workbook.lastModifiedBy = 'Tumelo';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    return workbook;
};
export var workbookToBlob = function (workbook) { return __awaiter(void 0, void 0, void 0, function () {
    var buf;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, workbook.xlsx.writeBuffer()];
            case 1:
                buf = _a.sent();
                return [2 /*return*/, new Blob([buf])];
        }
    });
}); };
