/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { optionFromJSON, optionToJSON } from "./option";
export var protobufPackage = "tumelo.transparency.v1";
export var VotingOption;
(function (VotingOption) {
    VotingOption[VotingOption["VOTING_OPTION_INVALID"] = 0] = "VOTING_OPTION_INVALID";
    VotingOption[VotingOption["VOTING_OPTION_FOR"] = 1] = "VOTING_OPTION_FOR";
    VotingOption[VotingOption["VOTING_OPTION_AGAINST"] = 2] = "VOTING_OPTION_AGAINST";
    VotingOption[VotingOption["VOTING_OPTION_ABSTAIN"] = 3] = "VOTING_OPTION_ABSTAIN";
    VotingOption[VotingOption["VOTING_OPTION_WITHHOLD"] = 4] = "VOTING_OPTION_WITHHOLD";
    VotingOption[VotingOption["VOTING_OPTION_NO_ACTION"] = 5] = "VOTING_OPTION_NO_ACTION";
    VotingOption[VotingOption["VOTING_OPTION_ONE_YEAR"] = 6] = "VOTING_OPTION_ONE_YEAR";
    VotingOption[VotingOption["VOTING_OPTION_TWO_YEARS"] = 7] = "VOTING_OPTION_TWO_YEARS";
    VotingOption[VotingOption["VOTING_OPTION_THREE_YEARS"] = 8] = "VOTING_OPTION_THREE_YEARS";
    VotingOption[VotingOption["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(VotingOption || (VotingOption = {}));
export function votingOptionFromJSON(object) {
    switch (object) {
        case 0:
        case "VOTING_OPTION_INVALID":
            return VotingOption.VOTING_OPTION_INVALID;
        case 1:
        case "VOTING_OPTION_FOR":
            return VotingOption.VOTING_OPTION_FOR;
        case 2:
        case "VOTING_OPTION_AGAINST":
            return VotingOption.VOTING_OPTION_AGAINST;
        case 3:
        case "VOTING_OPTION_ABSTAIN":
            return VotingOption.VOTING_OPTION_ABSTAIN;
        case 4:
        case "VOTING_OPTION_WITHHOLD":
            return VotingOption.VOTING_OPTION_WITHHOLD;
        case 5:
        case "VOTING_OPTION_NO_ACTION":
            return VotingOption.VOTING_OPTION_NO_ACTION;
        case 6:
        case "VOTING_OPTION_ONE_YEAR":
            return VotingOption.VOTING_OPTION_ONE_YEAR;
        case 7:
        case "VOTING_OPTION_TWO_YEARS":
            return VotingOption.VOTING_OPTION_TWO_YEARS;
        case 8:
        case "VOTING_OPTION_THREE_YEARS":
            return VotingOption.VOTING_OPTION_THREE_YEARS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return VotingOption.UNRECOGNIZED;
    }
}
export function votingOptionToJSON(object) {
    switch (object) {
        case VotingOption.VOTING_OPTION_INVALID:
            return "VOTING_OPTION_INVALID";
        case VotingOption.VOTING_OPTION_FOR:
            return "VOTING_OPTION_FOR";
        case VotingOption.VOTING_OPTION_AGAINST:
            return "VOTING_OPTION_AGAINST";
        case VotingOption.VOTING_OPTION_ABSTAIN:
            return "VOTING_OPTION_ABSTAIN";
        case VotingOption.VOTING_OPTION_WITHHOLD:
            return "VOTING_OPTION_WITHHOLD";
        case VotingOption.VOTING_OPTION_NO_ACTION:
            return "VOTING_OPTION_NO_ACTION";
        case VotingOption.VOTING_OPTION_ONE_YEAR:
            return "VOTING_OPTION_ONE_YEAR";
        case VotingOption.VOTING_OPTION_TWO_YEARS:
            return "VOTING_OPTION_TWO_YEARS";
        case VotingOption.VOTING_OPTION_THREE_YEARS:
            return "VOTING_OPTION_THREE_YEARS";
        case VotingOption.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** Source describes who raised the proposal */
export var Proposal_Source;
(function (Proposal_Source) {
    Proposal_Source[Proposal_Source["PROPOSAL_SOURCE_INVALID"] = 0] = "PROPOSAL_SOURCE_INVALID";
    Proposal_Source[Proposal_Source["PROPOSAL_SOURCE_SHAREHOLDER"] = 1] = "PROPOSAL_SOURCE_SHAREHOLDER";
    Proposal_Source[Proposal_Source["PROPOSAL_SOURCE_MANAGEMENT"] = 2] = "PROPOSAL_SOURCE_MANAGEMENT";
    Proposal_Source[Proposal_Source["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Proposal_Source || (Proposal_Source = {}));
export function proposal_SourceFromJSON(object) {
    switch (object) {
        case 0:
        case "PROPOSAL_SOURCE_INVALID":
            return Proposal_Source.PROPOSAL_SOURCE_INVALID;
        case 1:
        case "PROPOSAL_SOURCE_SHAREHOLDER":
            return Proposal_Source.PROPOSAL_SOURCE_SHAREHOLDER;
        case 2:
        case "PROPOSAL_SOURCE_MANAGEMENT":
            return Proposal_Source.PROPOSAL_SOURCE_MANAGEMENT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Proposal_Source.UNRECOGNIZED;
    }
}
export function proposal_SourceToJSON(object) {
    switch (object) {
        case Proposal_Source.PROPOSAL_SOURCE_INVALID:
            return "PROPOSAL_SOURCE_INVALID";
        case Proposal_Source.PROPOSAL_SOURCE_SHAREHOLDER:
            return "PROPOSAL_SOURCE_SHAREHOLDER";
        case Proposal_Source.PROPOSAL_SOURCE_MANAGEMENT:
            return "PROPOSAL_SOURCE_MANAGEMENT";
        case Proposal_Source.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
/** Decision is the final decision on the proposal */
export var Proposal_Outcome_Decision;
(function (Proposal_Outcome_Decision) {
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_INVALID"] = 0] = "PROPOSAL_OUTCOME_DECISION_INVALID";
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_FOR"] = 1] = "PROPOSAL_OUTCOME_DECISION_FOR";
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_AGAINST"] = 2] = "PROPOSAL_OUTCOME_DECISION_AGAINST";
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_WITHDRAWN"] = 3] = "PROPOSAL_OUTCOME_DECISION_WITHDRAWN";
    /** PROPOSAL_OUTCOME_DECISION_ONE_YEAR - say on frequency options */
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_ONE_YEAR"] = 4] = "PROPOSAL_OUTCOME_DECISION_ONE_YEAR";
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_TWO_YEARS"] = 5] = "PROPOSAL_OUTCOME_DECISION_TWO_YEARS";
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["PROPOSAL_OUTCOME_DECISION_THREE_YEARS"] = 6] = "PROPOSAL_OUTCOME_DECISION_THREE_YEARS";
    Proposal_Outcome_Decision[Proposal_Outcome_Decision["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Proposal_Outcome_Decision || (Proposal_Outcome_Decision = {}));
export function proposal_Outcome_DecisionFromJSON(object) {
    switch (object) {
        case 0:
        case "PROPOSAL_OUTCOME_DECISION_INVALID":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_INVALID;
        case 1:
        case "PROPOSAL_OUTCOME_DECISION_FOR":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_FOR;
        case 2:
        case "PROPOSAL_OUTCOME_DECISION_AGAINST":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_AGAINST;
        case 3:
        case "PROPOSAL_OUTCOME_DECISION_WITHDRAWN":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_WITHDRAWN;
        case 4:
        case "PROPOSAL_OUTCOME_DECISION_ONE_YEAR":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_ONE_YEAR;
        case 5:
        case "PROPOSAL_OUTCOME_DECISION_TWO_YEARS":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_TWO_YEARS;
        case 6:
        case "PROPOSAL_OUTCOME_DECISION_THREE_YEARS":
            return Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_THREE_YEARS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return Proposal_Outcome_Decision.UNRECOGNIZED;
    }
}
export function proposal_Outcome_DecisionToJSON(object) {
    switch (object) {
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_INVALID:
            return "PROPOSAL_OUTCOME_DECISION_INVALID";
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_FOR:
            return "PROPOSAL_OUTCOME_DECISION_FOR";
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_AGAINST:
            return "PROPOSAL_OUTCOME_DECISION_AGAINST";
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_WITHDRAWN:
            return "PROPOSAL_OUTCOME_DECISION_WITHDRAWN";
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_ONE_YEAR:
            return "PROPOSAL_OUTCOME_DECISION_ONE_YEAR";
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_TWO_YEARS:
            return "PROPOSAL_OUTCOME_DECISION_TWO_YEARS";
        case Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_THREE_YEARS:
            return "PROPOSAL_OUTCOME_DECISION_THREE_YEARS";
        case Proposal_Outcome_Decision.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}
function createBaseProposal() {
    return {
        name: "",
        sequenceIdentifier: "",
        title: "",
        description: "",
        managementRecommendation: undefined,
        source: 0,
        passThreshold: undefined,
        outcome: undefined,
        ediProposalIdentifier: 0,
        parentProposalName: "",
        ediSequenceNumber: 0,
        votable: false,
        fullText: "",
        classificationCodes: [],
        votingOptions: [],
        withdrawTime: undefined,
        currentlyVotable: false,
        secClassificationCodes: [],
        proxyEdgeProposalNumber: "",
        proxymityProposalNumber: "",
        createTime: undefined,
        updateTime: undefined,
    };
}
export var Proposal = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.sequenceIdentifier !== "") {
            writer.uint32(18).string(message.sequenceIdentifier);
        }
        if (message.title !== "") {
            writer.uint32(26).string(message.title);
        }
        if (message.description !== "") {
            writer.uint32(34).string(message.description);
        }
        if (message.managementRecommendation !== undefined) {
            Proposal_ManagementRecommendation.encode(message.managementRecommendation, writer.uint32(42).fork()).ldelim();
        }
        if (message.source !== 0) {
            writer.uint32(48).int32(message.source);
        }
        if (message.passThreshold !== undefined) {
            Proposal_PassThreshold.encode(message.passThreshold, writer.uint32(58).fork()).ldelim();
        }
        if (message.outcome !== undefined) {
            Proposal_Outcome.encode(message.outcome, writer.uint32(66).fork()).ldelim();
        }
        if (message.ediProposalIdentifier !== 0) {
            writer.uint32(120).int32(message.ediProposalIdentifier);
        }
        if (message.parentProposalName !== "") {
            writer.uint32(82).string(message.parentProposalName);
        }
        if (message.ediSequenceNumber !== 0) {
            writer.uint32(128).int32(message.ediSequenceNumber);
        }
        if (message.votable === true) {
            writer.uint32(96).bool(message.votable);
        }
        if (message.fullText !== "") {
            writer.uint32(106).string(message.fullText);
        }
        for (var _i = 0, _a = message.classificationCodes; _i < _a.length; _i++) {
            var v = _a[_i];
            writer.uint32(114).string(v);
        }
        writer.uint32(138).fork();
        for (var _b = 0, _c = message.votingOptions; _b < _c.length; _b++) {
            var v = _c[_b];
            writer.int32(v);
        }
        writer.ldelim();
        if (message.withdrawTime !== undefined) {
            Timestamp.encode(toTimestamp(message.withdrawTime), writer.uint32(146).fork()).ldelim();
        }
        if (message.currentlyVotable === true) {
            writer.uint32(152).bool(message.currentlyVotable);
        }
        for (var _d = 0, _e = message.secClassificationCodes; _d < _e.length; _d++) {
            var v = _e[_d];
            writer.uint32(162).string(v);
        }
        if (message.proxyEdgeProposalNumber !== "") {
            writer.uint32(170).string(message.proxyEdgeProposalNumber);
        }
        if (message.proxymityProposalNumber !== "") {
            writer.uint32(178).string(message.proxymityProposalNumber);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(186).fork()).ldelim();
        }
        if (message.updateTime !== undefined) {
            Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(194).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposal();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.sequenceIdentifier = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.title = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.description = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.managementRecommendation = Proposal_ManagementRecommendation.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 48) {
                        break;
                    }
                    message.source = reader.int32();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.passThreshold = Proposal_PassThreshold.decode(reader, reader.uint32());
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.outcome = Proposal_Outcome.decode(reader, reader.uint32());
                    continue;
                case 15:
                    if (tag !== 120) {
                        break;
                    }
                    message.ediProposalIdentifier = reader.int32();
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }
                    message.parentProposalName = reader.string();
                    continue;
                case 16:
                    if (tag !== 128) {
                        break;
                    }
                    message.ediSequenceNumber = reader.int32();
                    continue;
                case 12:
                    if (tag !== 96) {
                        break;
                    }
                    message.votable = reader.bool();
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }
                    message.fullText = reader.string();
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }
                    message.classificationCodes.push(reader.string());
                    continue;
                case 17:
                    if (tag === 136) {
                        message.votingOptions.push(reader.int32());
                        continue;
                    }
                    if (tag === 138) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.votingOptions.push(reader.int32());
                        }
                        continue;
                    }
                    break;
                case 18:
                    if (tag !== 146) {
                        break;
                    }
                    message.withdrawTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 19:
                    if (tag !== 152) {
                        break;
                    }
                    message.currentlyVotable = reader.bool();
                    continue;
                case 20:
                    if (tag !== 162) {
                        break;
                    }
                    message.secClassificationCodes.push(reader.string());
                    continue;
                case 21:
                    if (tag !== 170) {
                        break;
                    }
                    message.proxyEdgeProposalNumber = reader.string();
                    continue;
                case 22:
                    if (tag !== 178) {
                        break;
                    }
                    message.proxymityProposalNumber = reader.string();
                    continue;
                case 23:
                    if (tag !== 186) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 24:
                    if (tag !== 194) {
                        break;
                    }
                    message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            sequenceIdentifier: isSet(object.sequenceIdentifier) ? String(object.sequenceIdentifier) : "",
            title: isSet(object.title) ? String(object.title) : "",
            description: isSet(object.description) ? String(object.description) : "",
            managementRecommendation: isSet(object.managementRecommendation)
                ? Proposal_ManagementRecommendation.fromJSON(object.managementRecommendation)
                : undefined,
            source: isSet(object.source) ? proposal_SourceFromJSON(object.source) : 0,
            passThreshold: isSet(object.passThreshold) ? Proposal_PassThreshold.fromJSON(object.passThreshold) : undefined,
            outcome: isSet(object.outcome) ? Proposal_Outcome.fromJSON(object.outcome) : undefined,
            ediProposalIdentifier: isSet(object.ediProposalIdentifier) ? Number(object.ediProposalIdentifier) : 0,
            parentProposalName: isSet(object.parentProposalName) ? String(object.parentProposalName) : "",
            ediSequenceNumber: isSet(object.ediSequenceNumber) ? Number(object.ediSequenceNumber) : 0,
            votable: isSet(object.votable) ? Boolean(object.votable) : false,
            fullText: isSet(object.fullText) ? String(object.fullText) : "",
            classificationCodes: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.classificationCodes)
                ? object.classificationCodes.map(function (e) { return String(e); })
                : [],
            votingOptions: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.votingOptions)
                ? object.votingOptions.map(function (e) { return votingOptionFromJSON(e); })
                : [],
            withdrawTime: isSet(object.withdrawTime) ? fromJsonTimestamp(object.withdrawTime) : undefined,
            currentlyVotable: isSet(object.currentlyVotable) ? Boolean(object.currentlyVotable) : false,
            secClassificationCodes: globalThis.Array.isArray(object === null || object === void 0 ? void 0 : object.secClassificationCodes)
                ? object.secClassificationCodes.map(function (e) { return String(e); })
                : [],
            proxyEdgeProposalNumber: isSet(object.proxyEdgeProposalNumber) ? String(object.proxyEdgeProposalNumber) : "",
            proxymityProposalNumber: isSet(object.proxymityProposalNumber) ? String(object.proxymityProposalNumber) : "",
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
        };
    },
    toJSON: function (message) {
        var _a, _b, _c;
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.sequenceIdentifier !== "") {
            obj.sequenceIdentifier = message.sequenceIdentifier;
        }
        if (message.title !== "") {
            obj.title = message.title;
        }
        if (message.description !== "") {
            obj.description = message.description;
        }
        if (message.managementRecommendation !== undefined) {
            obj.managementRecommendation = Proposal_ManagementRecommendation.toJSON(message.managementRecommendation);
        }
        if (message.source !== 0) {
            obj.source = proposal_SourceToJSON(message.source);
        }
        if (message.passThreshold !== undefined) {
            obj.passThreshold = Proposal_PassThreshold.toJSON(message.passThreshold);
        }
        if (message.outcome !== undefined) {
            obj.outcome = Proposal_Outcome.toJSON(message.outcome);
        }
        if (message.ediProposalIdentifier !== 0) {
            obj.ediProposalIdentifier = Math.round(message.ediProposalIdentifier);
        }
        if (message.parentProposalName !== "") {
            obj.parentProposalName = message.parentProposalName;
        }
        if (message.ediSequenceNumber !== 0) {
            obj.ediSequenceNumber = Math.round(message.ediSequenceNumber);
        }
        if (message.votable === true) {
            obj.votable = message.votable;
        }
        if (message.fullText !== "") {
            obj.fullText = message.fullText;
        }
        if ((_a = message.classificationCodes) === null || _a === void 0 ? void 0 : _a.length) {
            obj.classificationCodes = message.classificationCodes;
        }
        if ((_b = message.votingOptions) === null || _b === void 0 ? void 0 : _b.length) {
            obj.votingOptions = message.votingOptions.map(function (e) { return votingOptionToJSON(e); });
        }
        if (message.withdrawTime !== undefined) {
            obj.withdrawTime = message.withdrawTime.toISOString();
        }
        if (message.currentlyVotable === true) {
            obj.currentlyVotable = message.currentlyVotable;
        }
        if ((_c = message.secClassificationCodes) === null || _c === void 0 ? void 0 : _c.length) {
            obj.secClassificationCodes = message.secClassificationCodes;
        }
        if (message.proxyEdgeProposalNumber !== "") {
            obj.proxyEdgeProposalNumber = message.proxyEdgeProposalNumber;
        }
        if (message.proxymityProposalNumber !== "") {
            obj.proxymityProposalNumber = message.proxymityProposalNumber;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.updateTime !== undefined) {
            obj.updateTime = message.updateTime.toISOString();
        }
        return obj;
    },
    create: function (base) {
        return Proposal.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        var message = createBaseProposal();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.sequenceIdentifier = (_b = object.sequenceIdentifier) !== null && _b !== void 0 ? _b : "";
        message.title = (_c = object.title) !== null && _c !== void 0 ? _c : "";
        message.description = (_d = object.description) !== null && _d !== void 0 ? _d : "";
        message.managementRecommendation =
            (object.managementRecommendation !== undefined && object.managementRecommendation !== null)
                ? Proposal_ManagementRecommendation.fromPartial(object.managementRecommendation)
                : undefined;
        message.source = (_e = object.source) !== null && _e !== void 0 ? _e : 0;
        message.passThreshold = (object.passThreshold !== undefined && object.passThreshold !== null)
            ? Proposal_PassThreshold.fromPartial(object.passThreshold)
            : undefined;
        message.outcome = (object.outcome !== undefined && object.outcome !== null)
            ? Proposal_Outcome.fromPartial(object.outcome)
            : undefined;
        message.ediProposalIdentifier = (_f = object.ediProposalIdentifier) !== null && _f !== void 0 ? _f : 0;
        message.parentProposalName = (_g = object.parentProposalName) !== null && _g !== void 0 ? _g : "";
        message.ediSequenceNumber = (_h = object.ediSequenceNumber) !== null && _h !== void 0 ? _h : 0;
        message.votable = (_j = object.votable) !== null && _j !== void 0 ? _j : false;
        message.fullText = (_k = object.fullText) !== null && _k !== void 0 ? _k : "";
        message.classificationCodes = ((_l = object.classificationCodes) === null || _l === void 0 ? void 0 : _l.map(function (e) { return e; })) || [];
        message.votingOptions = ((_m = object.votingOptions) === null || _m === void 0 ? void 0 : _m.map(function (e) { return e; })) || [];
        message.withdrawTime = (_o = object.withdrawTime) !== null && _o !== void 0 ? _o : undefined;
        message.currentlyVotable = (_p = object.currentlyVotable) !== null && _p !== void 0 ? _p : false;
        message.secClassificationCodes = ((_q = object.secClassificationCodes) === null || _q === void 0 ? void 0 : _q.map(function (e) { return e; })) || [];
        message.proxyEdgeProposalNumber = (_r = object.proxyEdgeProposalNumber) !== null && _r !== void 0 ? _r : "";
        message.proxymityProposalNumber = (_s = object.proxymityProposalNumber) !== null && _s !== void 0 ? _s : "";
        message.createTime = (_t = object.createTime) !== null && _t !== void 0 ? _t : undefined;
        message.updateTime = (_u = object.updateTime) !== null && _u !== void 0 ? _u : undefined;
        return message;
    },
};
function createBaseProposal_ManagementRecommendation() {
    return { recommendation: 0, recommendationDescription: "" };
}
export var Proposal_ManagementRecommendation = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.recommendation !== 0) {
            writer.uint32(8).int32(message.recommendation);
        }
        if (message.recommendationDescription !== "") {
            writer.uint32(18).string(message.recommendationDescription);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposal_ManagementRecommendation();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.recommendation = reader.int32();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.recommendationDescription = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            recommendation: isSet(object.recommendation) ? optionFromJSON(object.recommendation) : 0,
            recommendationDescription: isSet(object.recommendationDescription)
                ? String(object.recommendationDescription)
                : "",
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.recommendation !== 0) {
            obj.recommendation = optionToJSON(message.recommendation);
        }
        if (message.recommendationDescription !== "") {
            obj.recommendationDescription = message.recommendationDescription;
        }
        return obj;
    },
    create: function (base) {
        return Proposal_ManagementRecommendation.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b;
        var message = createBaseProposal_ManagementRecommendation();
        message.recommendation = (_a = object.recommendation) !== null && _a !== void 0 ? _a : 0;
        message.recommendationDescription = (_b = object.recommendationDescription) !== null && _b !== void 0 ? _b : "";
        return message;
    },
};
function createBaseProposal_PassThreshold() {
    return { threshold: 0 };
}
export var Proposal_PassThreshold = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.threshold !== 0) {
            writer.uint32(9).double(message.threshold);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposal_PassThreshold();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 9) {
                        break;
                    }
                    message.threshold = reader.double();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return { threshold: isSet(object.threshold) ? Number(object.threshold) : 0 };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.threshold !== 0) {
            obj.threshold = message.threshold;
        }
        return obj;
    },
    create: function (base) {
        return Proposal_PassThreshold.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a;
        var message = createBaseProposal_PassThreshold();
        message.threshold = (_a = object.threshold) !== null && _a !== void 0 ? _a : 0;
        return message;
    },
};
function createBaseProposal_Outcome() {
    return { decision: 0, votes: undefined, createTime: undefined, withdrawalReason: "" };
}
export var Proposal_Outcome = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.decision !== 0) {
            writer.uint32(8).int32(message.decision);
        }
        if (message.votes !== undefined) {
            Proposal_Outcome_Votes.encode(message.votes, writer.uint32(18).fork()).ldelim();
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.withdrawalReason !== "") {
            writer.uint32(34).string(message.withdrawalReason);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposal_Outcome();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.decision = reader.int32();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.votes = Proposal_Outcome_Votes.decode(reader, reader.uint32());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.withdrawalReason = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            decision: isSet(object.decision) ? proposal_Outcome_DecisionFromJSON(object.decision) : 0,
            votes: isSet(object.votes) ? Proposal_Outcome_Votes.fromJSON(object.votes) : undefined,
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            withdrawalReason: isSet(object.withdrawalReason) ? String(object.withdrawalReason) : "",
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.decision !== 0) {
            obj.decision = proposal_Outcome_DecisionToJSON(message.decision);
        }
        if (message.votes !== undefined) {
            obj.votes = Proposal_Outcome_Votes.toJSON(message.votes);
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.withdrawalReason !== "") {
            obj.withdrawalReason = message.withdrawalReason;
        }
        return obj;
    },
    create: function (base) {
        return Proposal_Outcome.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c;
        var message = createBaseProposal_Outcome();
        message.decision = (_a = object.decision) !== null && _a !== void 0 ? _a : 0;
        message.votes = (object.votes !== undefined && object.votes !== null)
            ? Proposal_Outcome_Votes.fromPartial(object.votes)
            : undefined;
        message.createTime = (_b = object.createTime) !== null && _b !== void 0 ? _b : undefined;
        message.withdrawalReason = (_c = object.withdrawalReason) !== null && _c !== void 0 ? _c : "";
        return message;
    },
};
function createBaseProposal_Outcome_Votes() {
    return {
        for: undefined,
        against: undefined,
        abstentions: undefined,
        brokerNonVotes: undefined,
        withheld: undefined,
        oneYear: undefined,
        twoYears: undefined,
        threeYears: undefined,
    };
}
export var Proposal_Outcome_Votes = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.for !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.for, writer.uint32(10).fork()).ldelim();
        }
        if (message.against !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.against, writer.uint32(18).fork()).ldelim();
        }
        if (message.abstentions !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.abstentions, writer.uint32(26).fork()).ldelim();
        }
        if (message.brokerNonVotes !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.brokerNonVotes, writer.uint32(34).fork()).ldelim();
        }
        if (message.withheld !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.withheld, writer.uint32(42).fork()).ldelim();
        }
        if (message.oneYear !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.oneYear, writer.uint32(50).fork()).ldelim();
        }
        if (message.twoYears !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.twoYears, writer.uint32(58).fork()).ldelim();
        }
        if (message.threeYears !== undefined) {
            Proposal_Outcome_Votes_Count.encode(message.threeYears, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposal_Outcome_Votes();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.for = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.against = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.abstentions = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.brokerNonVotes = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.withheld = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.oneYear = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }
                    message.twoYears = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }
                    message.threeYears = Proposal_Outcome_Votes_Count.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            for: isSet(object.for) ? Proposal_Outcome_Votes_Count.fromJSON(object.for) : undefined,
            against: isSet(object.against) ? Proposal_Outcome_Votes_Count.fromJSON(object.against) : undefined,
            abstentions: isSet(object.abstentions) ? Proposal_Outcome_Votes_Count.fromJSON(object.abstentions) : undefined,
            brokerNonVotes: isSet(object.brokerNonVotes)
                ? Proposal_Outcome_Votes_Count.fromJSON(object.brokerNonVotes)
                : undefined,
            withheld: isSet(object.withheld) ? Proposal_Outcome_Votes_Count.fromJSON(object.withheld) : undefined,
            oneYear: isSet(object.oneYear) ? Proposal_Outcome_Votes_Count.fromJSON(object.oneYear) : undefined,
            twoYears: isSet(object.twoYears) ? Proposal_Outcome_Votes_Count.fromJSON(object.twoYears) : undefined,
            threeYears: isSet(object.threeYears) ? Proposal_Outcome_Votes_Count.fromJSON(object.threeYears) : undefined,
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.for !== undefined) {
            obj.for = Proposal_Outcome_Votes_Count.toJSON(message.for);
        }
        if (message.against !== undefined) {
            obj.against = Proposal_Outcome_Votes_Count.toJSON(message.against);
        }
        if (message.abstentions !== undefined) {
            obj.abstentions = Proposal_Outcome_Votes_Count.toJSON(message.abstentions);
        }
        if (message.brokerNonVotes !== undefined) {
            obj.brokerNonVotes = Proposal_Outcome_Votes_Count.toJSON(message.brokerNonVotes);
        }
        if (message.withheld !== undefined) {
            obj.withheld = Proposal_Outcome_Votes_Count.toJSON(message.withheld);
        }
        if (message.oneYear !== undefined) {
            obj.oneYear = Proposal_Outcome_Votes_Count.toJSON(message.oneYear);
        }
        if (message.twoYears !== undefined) {
            obj.twoYears = Proposal_Outcome_Votes_Count.toJSON(message.twoYears);
        }
        if (message.threeYears !== undefined) {
            obj.threeYears = Proposal_Outcome_Votes_Count.toJSON(message.threeYears);
        }
        return obj;
    },
    create: function (base) {
        return Proposal_Outcome_Votes.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var message = createBaseProposal_Outcome_Votes();
        message.for = (object.for !== undefined && object.for !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.for)
            : undefined;
        message.against = (object.against !== undefined && object.against !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.against)
            : undefined;
        message.abstentions = (object.abstentions !== undefined && object.abstentions !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.abstentions)
            : undefined;
        message.brokerNonVotes = (object.brokerNonVotes !== undefined && object.brokerNonVotes !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.brokerNonVotes)
            : undefined;
        message.withheld = (object.withheld !== undefined && object.withheld !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.withheld)
            : undefined;
        message.oneYear = (object.oneYear !== undefined && object.oneYear !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.oneYear)
            : undefined;
        message.twoYears = (object.twoYears !== undefined && object.twoYears !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.twoYears)
            : undefined;
        message.threeYears = (object.threeYears !== undefined && object.threeYears !== null)
            ? Proposal_Outcome_Votes_Count.fromPartial(object.threeYears)
            : undefined;
        return message;
    },
};
function createBaseProposal_Outcome_Votes_Count() {
    return { votes: 0 };
}
export var Proposal_Outcome_Votes_Count = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.votes !== 0) {
            writer.uint32(8).uint64(message.votes);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseProposal_Outcome_Votes_Count();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }
                    message.votes = longToNumber(reader.uint64());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return { votes: isSet(object.votes) ? Number(object.votes) : 0 };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.votes !== 0) {
            obj.votes = Math.round(message.votes);
        }
        return obj;
    },
    create: function (base) {
        return Proposal_Outcome_Votes_Count.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a;
        var message = createBaseProposal_Outcome_Votes_Count();
        message.votes = (_a = object.votes) !== null && _a !== void 0 ? _a : 0;
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function longToNumber(long) {
    if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
        throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long;
    _m0.configure();
}
function isSet(value) {
    return value !== null && value !== undefined;
}
