import { Option } from '../proto/tumelo/poll/v1/option';
export var VoteOptionToString = function (option) {
    switch (option) {
        case Option.OPTION_INVALID:
            return 'invalid';
        case Option.OPTION_FOR:
            return 'for';
        case Option.OPTION_AGAINST:
            return 'against';
        case Option.OPTION_ABSTAIN:
            return 'abstain';
        case Option.OPTION_WITHHOLD:
            return 'withhold';
        case Option.OPTION_NO_ACTION:
            return 'no_action';
        default:
            return 'unknown';
    }
};
export var getVoteOptionsToValueLabelPairs = function () {
    return [
        { value: Option.OPTION_FOR, label: 'For' },
        { value: Option.OPTION_AGAINST, label: 'Against' },
        { value: Option.OPTION_ABSTAIN, label: 'Abstain' },
        { value: Option.OPTION_WITHHOLD, label: 'Withhold' },
        { value: Option.OPTION_NO_ACTION, label: 'No Action' },
    ];
};
