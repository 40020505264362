export var IsinBreakdownColumns = [
    { header: 'ISIN', key: 'isin', width: 15 },
    { header: 'Instrument title', key: 'instrumentTitle', width: 20 },
    { header: 'MiFIR code', key: 'mifirCode', width: 5 },
    { header: 'Organization ID', key: 'organizationID', width: 34 },
    { header: 'Organization title', key: 'organizationTitle', width: 20 },
    { header: 'Instrument weight (%)', key: 'instrumentWeight', width: 10 },
];
export var isinBreakdownSheet = function (rows, validAtDate) {
    var sheet = {
        title: 'IsinBreakdown',
        headers: IsinBreakdownColumns,
        data: rows,
        topInfoRows: ['Valid at: ' + validAtDate],
    };
    return sheet;
};
