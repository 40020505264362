import { Ballot } from '../../proto/tumelo/stewardshipbff/v1/ballot'
import { Option } from '../../proto/tumelo/stewardshipbff/v1/option'

export const sampleBallot = (override?: Partial<Ballot>): Ballot =>
  Ballot.fromPartial({
    name: 'meetingBallots/f8db1121-b30d-419e-8a6e-89cd9d6b4f06',
    comment: 'Test ballot comment',
    response: Option.OPTION_FOR,
    ...override,
  })
