import { libFundUniverse, libProvider } from '.';
export var prefix = 'fundRequirements/';
export var IDToName = function (_a) {
    var providerID = _a.providerID, universeID = _a.universeID, requirementID = _a.requirementID;
    return libFundUniverse.IDToName({ providerID: providerID, universeID: universeID }) + '/' + prefix + requirementID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 6 ||
        !name.startsWith(libProvider.prefix) ||
        !name.includes(libFundUniverse.prefix) ||
        !name.includes(prefix)) {
        throw new Error("bad fund requirement name: ".concat(name));
    }
    return {
        providerID: parts[1],
        universeID: parts[3],
        requirementID: parts[5],
    };
};
