import { libFund } from '.';
// Order on save disagrees with eslint
// eslint-disable-next-line import/order
import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
var prefix = 'compositions';
var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var fundID = _a.fundID, compositionID = _a.compositionID;
    return libFund.IDToName({ fundID: fundID }) + SEPARATOR + prefixWithSeparator + compositionID;
};
export var validateName = genericValidateName([
    exactValidator(libFund.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split(SEPARATOR);
    return {
        fundID: parts[1],
        compositionID: parts[3],
    };
};
