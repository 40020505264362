import { ProxyIntermediary } from '../proto/tumelo/investor/v1/split_vote_instruction';
export var prefix = 'splitVoteInstructions/';
export var IDToName = function (_a) {
    var splitVoteInstructionID = _a.splitVoteInstructionID;
    return prefix + splitVoteInstructionID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (!name.startsWith(prefix) || parts.length !== 2) {
        throw new Error("bad split vote instruction name: ".concat(name));
    }
    return { splitVoteInstructionID: parts[1] };
};
export function toPBProxyIntermediary(proxyIntermediary) {
    switch (proxyIntermediary) {
        case 'ProxyEdge':
            return ProxyIntermediary.PROXY_INTERMEDIARY_PROXYEDGE;
        case 'Proxymity':
            return ProxyIntermediary.PROXY_INTERMEDIARY_PROXYMITY;
        default:
            return ProxyIntermediary.PROXY_INTERMEDIARY_INVALID;
    }
}
export function toProxyIntermediaryString(proxyIntermediary) {
    switch (proxyIntermediary) {
        case ProxyIntermediary.PROXY_INTERMEDIARY_PROXYEDGE:
            return 'ProxyEdge';
        case ProxyIntermediary.PROXY_INTERMEDIARY_PROXYMITY:
            return 'Proxymity';
        default:
            throw new Error("Invalid proxy intermediary");
    }
}
