import { libProposal, libOrganization } from '..';
import { VoteOptionToString } from '../domain/pollOption';
export var VoteCommentsColumns = [
    { header: 'Organization title', key: 'orgTitle', width: 20 },
    { header: 'Poll title', key: 'pollTitle', width: 20 },
    { header: 'Poll classification tags', key: 'classificationTags', width: 20 },
    { header: 'Comment', key: 'comment', width: 20 },
    { header: 'Vote', key: 'investorVote', width: 10 },
];
export var voteCommentsFromHabitats = function (habitats, fetchAnonymizedCommentsResponse) {
    var voteCommentsSheets = habitats.map(function (h) {
        var habitatVoteComments = fetchAnonymizedCommentsResponse.voteCommentsByHabitat[h.name];
        var data = pollVoteCommentsToVoteCommentRows(habitatVoteComments.pollVoteComments, fetchAnonymizedCommentsResponse.polls, fetchAnonymizedCommentsResponse.proposals, fetchAnonymizedCommentsResponse.organizations);
        return {
            title: "C|".concat(h.title),
            headers: VoteCommentsColumns,
            data: data,
        };
    });
    return voteCommentsSheets;
};
export var voteCommentsFromSchemes = function (schemes, fetchAnonymizedCommentsResponse) {
    var voteCommentsSheets = schemes.map(function (s) {
        var schemeVoteComments = fetchAnonymizedCommentsResponse.voteCommentsByScheme[s.name];
        var data = pollVoteCommentsToVoteCommentRows(schemeVoteComments.pollVoteComments, fetchAnonymizedCommentsResponse.polls, fetchAnonymizedCommentsResponse.proposals, fetchAnonymizedCommentsResponse.organizations);
        return {
            title: "C|".concat(s.title),
            headers: VoteCommentsColumns,
            data: data,
        };
    });
    return voteCommentsSheets;
};
export var summaryVoteCommentsSheet = function (fetchAnonymizedCommentsResponse) {
    var data = pollVoteCommentsToVoteCommentRows(fetchAnonymizedCommentsResponse.summary, fetchAnonymizedCommentsResponse.polls, fetchAnonymizedCommentsResponse.proposals, fetchAnonymizedCommentsResponse.organizations);
    var summaryVoteCommentsSheet = {
        title: 'C|Summary',
        headers: VoteCommentsColumns,
        data: data,
    };
    return summaryVoteCommentsSheet;
};
export var pollVoteCommentsToVoteCommentRows = function (pollVoteComments, polls, proposals, organizations) {
    return pollVoteComments
        .map(function (pvc) {
        var poll = polls[pvc.pollName];
        var proposal = proposals[poll.proposalName];
        var organizationID = libProposal.nameToID(proposal.name).organizationID;
        var organization = organizations[libOrganization.IDToName({ organizationID: organizationID })];
        return pvc.voteCommentPairs
            .map(function (vcp) { return ({
            orgTitle: organization.legalTitle,
            pollTitle: poll.title,
            classificationTags: poll.classificationTags.map(function (t) { return t.title; }).join('|'),
            comment: vcp.comment,
            investorVote: VoteOptionToString(vcp.vote),
        }); })
            .flat();
    })
        .flat();
};
