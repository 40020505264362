import { libScheme } from '@tumelo/common'
import { NextRouter } from 'next/router'

export const getCurrentSlug = (router: NextRouter): string => {
  const { habitatID, schemeID } = router.query
  return habitatID && schemeID ? `/${habitatID}/${schemeID}` : '/-/-'
}

export const schemeNameToSlug = (schemeName: string): string => {
  const { habitatID, schemeID } = libScheme.nameToID(schemeName)
  return `/${habitatID}/${schemeID}`
}

export const slugToSchemeName = (router: NextRouter): string => {
  const { schemeID, habitatID } = router.query
  return libScheme.IDToName({ habitatID: habitatID as string, schemeID: schemeID as string })
}
