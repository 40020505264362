import { useAsync } from '@tumelo/common'
import React, { useContext, useState } from 'react'
import { FeatureFlag } from '../proto/tumelo/stewardshipbff/v1/feature_flag'
import { ClientContext } from './client'

export const FlagsContext = React.createContext<{
  flags: Map<FeatureFlag['key'], FeatureFlag['state']>
}>({
  flags: new Map(),
})

export const FlagsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const getClient = useContext(ClientContext)

  const [items, setItems] = useState(new Map())

  useAsync(async () => {
    const client = await getClient()
    const { featureFlags } = await client.ListFeatureFlags({})
    const flagsMap = new Map()
    featureFlags.forEach((item) => {
      flagsMap.set(item.key, item.state)
    })
    setItems(flagsMap)
  })

  return <FlagsContext.Provider value={{ flags: items }}>{children}</FlagsContext.Provider>
}
