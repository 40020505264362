import { __awaiter, __generator } from "tslib";
import { libGeneralMeeting, libProposal, libOrganization } from '..';
import { sourceToString } from '../domain/proposal';
export var VotePreferencesColumns = [
    { header: 'Organization title', key: 'organizationTitle', width: 20 },
    { header: 'Organization Issued Isins', key: 'organizationIssuedIsins', width: 20 },
    { header: 'Proposal title', key: 'proposalTitle', width: 20 },
    { header: 'Proposal identifier', key: 'proposalIdentifier', width: 20 },
    { header: 'Proposal source', key: 'proposalSource', width: 20 },
    { header: 'Poll title', key: 'pollTitle', width: 20 },
    { header: 'Poll classification tags', key: 'classificationTags', width: 20 },
    { header: 'Votes for', key: 'votesFor', width: 8 },
    { header: 'Votes against', key: 'votesAgainst', width: 11 },
    { header: 'Votes abstain', key: 'votesAbstain', width: 11 },
    { header: 'Votes no action', key: 'votesNoAction', width: 11 },
    { header: 'Votes withhold', key: 'votesWithhold', width: 11 },
    { header: 'Percentage for', key: 'percentageFor', width: 11 },
    { header: 'Percentage against', key: 'percentageAgainst', width: 15 },
    { header: 'Percentage abstain', key: 'percentageAbstain', width: 15 },
    { header: 'Manual Votes for', key: 'manualVotesFor', width: 15 },
    { header: 'Manual Votes against', key: 'manualVotesAgainst', width: 15 },
    { header: 'Manual Votes abstain', key: 'manualVotesAbstain', width: 15 },
    { header: 'Manual Votes no action', key: 'manualVotesNoAction', width: 15 },
    { header: 'Manual Votes withhold', key: 'manualVotesWithhold', width: 15 },
    { header: 'Auto Votes for', key: 'autoVotesFor', width: 15 },
    { header: 'Auto Votes against', key: 'autoVotesAgainst', width: 15 },
    { header: 'Auto Votes abstain', key: 'autoVotesAbstain', width: 15 },
    { header: 'Auto Votes no action', key: 'autoVotesNoAction', width: 15 },
    { header: 'Auto Votes withhold', key: 'autoVotesWithhold', width: 15 },
    { header: 'General meeting time', key: 'generalMeetingTime', width: 17 },
    { header: 'Export time', key: 'exportTime', width: 17 },
];
export var votePreferencesFromHabitats = function (habitats, fetchAnonymizedVotePreferencesResponse) {
    var votePreferencesSheets = habitats.map(function (h) {
        var habitatVotePreferences = fetchAnonymizedVotePreferencesResponse.votePreferencesByHabitat[h.name];
        var data = pollVotePreferencesToVotePreferencesRows(habitatVotePreferences.pollVotePreferences, fetchAnonymizedVotePreferencesResponse.readTime, fetchAnonymizedVotePreferencesResponse.polls, fetchAnonymizedVotePreferencesResponse.proposals, fetchAnonymizedVotePreferencesResponse.generalMeetings, fetchAnonymizedVotePreferencesResponse.organizations);
        return {
            title: "V|".concat(h.title),
            headers: VotePreferencesColumns,
            data: data,
        };
    });
    return votePreferencesSheets;
};
export var votePreferencesFromSchemes = function (schemes, fetchAnonymizedVotePreferencesResponse) {
    var votePreferencesSheets = schemes.map(function (s) {
        var schemeVotePreferences = fetchAnonymizedVotePreferencesResponse.votePreferencesByScheme[s.name];
        var data = pollVotePreferencesToVotePreferencesRows(schemeVotePreferences.pollVotePreferences, fetchAnonymizedVotePreferencesResponse.readTime, fetchAnonymizedVotePreferencesResponse.polls, fetchAnonymizedVotePreferencesResponse.proposals, fetchAnonymizedVotePreferencesResponse.generalMeetings, fetchAnonymizedVotePreferencesResponse.organizations);
        return {
            title: "V|".concat(s.title),
            headers: VotePreferencesColumns,
            data: data,
        };
    });
    return votePreferencesSheets;
};
export var votePreferencesFromFundVariants = function (fundVariants, fetchAnonymizedVotePreferencesResponse, getPreferredLabel) { return __awaiter(void 0, void 0, void 0, function () {
    var votePreferencesSheets;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(fundVariants
                    .sort(function (a, b) { return a.name.localeCompare(b.name); }) // Sort by FundVariant name for consistency
                    .map(function (fv) { return __awaiter(void 0, void 0, void 0, function () {
                    var fvVotePreferences, data, preferredLabel;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                fvVotePreferences = fetchAnonymizedVotePreferencesResponse.votePreferencesByFundVariant[fv.name];
                                data = pollVotePreferencesToVotePreferencesRows(fvVotePreferences.pollVotePreferences, fetchAnonymizedVotePreferencesResponse.readTime, fetchAnonymizedVotePreferencesResponse.polls, fetchAnonymizedVotePreferencesResponse.proposals, fetchAnonymizedVotePreferencesResponse.generalMeetings, fetchAnonymizedVotePreferencesResponse.organizations);
                                return [4 /*yield*/, getPreferredLabel(fv)];
                            case 1:
                                preferredLabel = _a.sent();
                                return [2 /*return*/, {
                                        title: "V|".concat(preferredLabel.substring(-29)), // Restrict arbitrary label to 31 characters
                                        headers: VotePreferencesColumns,
                                        data: data,
                                    }];
                        }
                    });
                }); }))];
            case 1:
                votePreferencesSheets = _a.sent();
                return [2 /*return*/, votePreferencesSheets];
        }
    });
}); };
export var summaryVotePreferencesSheet = function (fetchAnonymizedVotePreferencesResponse) {
    var data = pollVotePreferencesToVotePreferencesRows(fetchAnonymizedVotePreferencesResponse.summary, new Date(), fetchAnonymizedVotePreferencesResponse.polls, fetchAnonymizedVotePreferencesResponse.proposals, fetchAnonymizedVotePreferencesResponse.generalMeetings, fetchAnonymizedVotePreferencesResponse.organizations);
    var summaryVotePreferencesSheet = {
        title: 'V|Summary',
        headers: VotePreferencesColumns,
        data: data,
    };
    return summaryVotePreferencesSheet;
};
export var pollVotePreferencesToVotePreferencesRows = function (pollVotePreferences, createTime, polls, proposals, generalMeetings, organizations) {
    var getPVPDetails = function (pvp) {
        var poll = polls[pvp.pollName];
        var proposal = proposals[poll.proposalName];
        var _a = libProposal.nameToID(proposal.name), organizationID = _a.organizationID, generalMeetingID = _a.generalMeetingID;
        var generalMeeting = generalMeetings[libGeneralMeeting.IDToName({ organizationID: organizationID, generalMeetingID: generalMeetingID })];
        var organization = organizations[libOrganization.IDToName({ organizationID: organizationID })];
        return { poll: poll, proposal: proposal, generalMeeting: generalMeeting, organization: organization };
    };
    return pollVotePreferences
        .sort(function (a, b) {
        var gmA = getPVPDetails(a).generalMeeting;
        var gmB = getPVPDetails(b).generalMeeting;
        var dateA = gmA.meetingDate || new Date();
        var dateB = gmB.meetingDate || new Date();
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    })
        .map(function (pvp) {
        var _a = getPVPDetails(pvp), poll = _a.poll, proposal = _a.proposal, organization = _a.organization, generalMeeting = _a.generalMeeting;
        var row = {
            organizationTitle: organization.legalTitle,
            organizationIssuedIsins: organization.issuedIsins.join(','),
            proposalTitle: proposal.title,
            proposalIdentifier: proposal.sequenceIdentifier,
            proposalSource: sourceToString(proposal.source),
            pollTitle: poll.title,
            classificationTags: poll.classificationTags.map(function (t) { return t.title; }).join('|'),
            votesTotal: pvp.votesTotal,
            votesFor: pvp.votesFor,
            votesAgainst: pvp.votesAgainst,
            votesAbstain: pvp.votesAbstain,
            votesNoAction: pvp.votesNoAction,
            votesWithhold: pvp.votesWithhold,
            percentageFor: pvp.votesFor / pvp.votesTotal,
            percentageAgainst: pvp.votesAgainst / pvp.votesTotal,
            percentageAbstain: pvp.votesAbstain / pvp.votesTotal,
            percentageNoAction: pvp.votesNoAction / pvp.votesTotal,
            percentageWithhold: pvp.votesWithhold / pvp.votesTotal,
            manualVotesFor: pvp.votesFor - pvp.autoVotesFor,
            manualVotesAgainst: pvp.votesAgainst - pvp.autoVotesAgainst,
            manualVotesAbstain: pvp.votesAbstain - pvp.autoVotesAbstain,
            manualVotesNoAction: pvp.votesNoAction - pvp.autoVotesNoAction,
            manualVotesWithhold: pvp.votesWithhold - pvp.autoVotesWithhold,
            autoVotesFor: pvp.autoVotesFor,
            autoVotesAgainst: pvp.autoVotesAgainst,
            autoVotesAbstain: pvp.autoVotesAbstain,
            autoVotesNoAction: pvp.autoVotesNoAction,
            autoVotesWithhold: pvp.autoVotesWithhold,
            generalMeetingTime: generalMeeting.meetingDate,
            exportTime: createTime,
        };
        return row;
    });
};
