import { libHabitat, libInvestor } from '.';
// Auto-ordering on save disagrees with eslint
// eslint-disable-next-line import/order
import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
export var prefix = 'accounts';
export var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, investorID = _a.investorID, investorAccountID = _a.investorAccountID;
    return libInvestor.IDToName({ habitatID: habitatID, investorID: investorID }) + SEPARATOR + prefixWithSeparator + investorAccountID;
};
export var validateName = genericValidateName([
    exactValidator(libHabitat.prefix),
    uuidValidator(),
    exactValidator(libInvestor.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split('/');
    return {
        habitatID: parts[1],
        investorID: parts[3],
        investorAccountID: parts[5],
    };
};
