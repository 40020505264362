import { prefixWithSeparator as habitatPrefix } from './habitat';
var prefix = "votingPolicyGrants/";
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, grantID = _a.grantID;
    return habitatPrefix + habitatID + '/' + prefix + grantID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4) {
        throw new Error("bad habitat voting policy grant name: ".concat(name));
    }
    return {
        habitatID: parts[1],
        grantID: parts[3],
    };
};
