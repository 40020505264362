import { createContext } from 'react'

export interface Config {
  clientAddress: string
  clientType: 'mock' | 'api'
  userpoolId: string
  appClientId: string
  appEnv: 'development' | 'production'
  plannedMaintenance: boolean
}

export const getConfig = (): Config => {
  const clientAddress = process.env.NEXT_PUBLIC_CLIENT_ADDRESS_BFF
  const userpoolId = process.env.NEXT_PUBLIC_USERPOOL_ID
  const appClientId = process.env.NEXT_PUBLIC_APP_CLIENT_ID
  const clientType = process.env.NEXT_PUBLIC_CLIENT_TYPE
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV
  const plannedMaintenance = process.env.NEXT_PUBLIC_PLANNED_MAINTENANCE?.toLowerCase() === 'true'
  if (clientAddress === undefined) {
    throw new Error('Client address not defined. ENV var NEXT_PUBLIC_CLIENT_ADDRESS_BFF')
  }
  if (userpoolId === undefined) {
    throw new Error('userpoolId not defined. ENV var NEXT_PUBLIC_USERPOOL_ID')
  }
  if (appClientId === undefined) {
    throw new Error('appClientId not defined. ENV var NEXT_PUBLIC_APP_CLIENT_ID')
  }
  if (clientType !== 'mock' && clientType !== 'api') {
    throw new Error('clientType is not valid. ENV var NEXT_PUBLIC_CLIENT_TYPE')
  }
  if (appEnv !== 'development' && appEnv !== 'production') {
    throw new Error('appEnv is not valid. ENV var NEXT_PUBLIC_APP_ENV')
  }
  return { clientAddress, clientType, userpoolId, appClientId, appEnv, plannedMaintenance }
}

export const ConfigContext = createContext<Config>({
  appClientId: '',
  clientAddress: '',
  clientType: 'mock',
  userpoolId: '',
  appEnv: 'development',
  plannedMaintenance: false,
})
