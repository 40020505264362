/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Option, optionFromJSON, optionToJSON } from "./option";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/**
 * Ballot is a virtual piece of paper that a user can vote on for a given poll.
 * A ballot is unique per investor and poll.
 */
export interface Ballot {
  /** REQUIRED the name of the ballot (format 'ballots/{uuid}') */
  name: string;
  /** OPTIONAL how the investor voted on this ballot */
  response: Option;
  /** OPTIONAL the content of the comment placed by the investor */
  comment: string;
}

function createBaseBallot(): Ballot {
  return { name: "", response: 0, comment: "" };
}

export const Ballot = {
  encode(message: Ballot, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.response !== 0) {
      writer.uint32(16).int32(message.response);
    }
    if (message.comment !== "") {
      writer.uint32(26).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ballot {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBallot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.response = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ballot {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      response: isSet(object.response) ? optionFromJSON(object.response) : 0,
      comment: isSet(object.comment) ? String(object.comment) : "",
    };
  },

  toJSON(message: Ballot): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.response !== 0) {
      obj.response = optionToJSON(message.response);
    }
    if (message.comment !== "") {
      obj.comment = message.comment;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ballot>, I>>(base?: I): Ballot {
    return Ballot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ballot>, I>>(object: I): Ballot {
    const message = createBaseBallot();
    message.name = object.name ?? "";
    message.response = object.response ?? 0;
    message.comment = object.comment ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
