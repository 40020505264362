import {
  Proposal as TransparencyProposal,
  Proposal_Outcome_Decision,
  Proposal_Source,
} from '../../proto/tumelo/transparency/v1/proposal'
import { Proposal, ProposalSource } from '../../proto/tumelo/stewardshipbff/v1/proposal'
import { Option } from '../../proto/tumelo/stewardshipbff/v1/option'

export const sampleTransparencyProposal = (override?: Partial<TransparencyProposal>): TransparencyProposal =>
  TransparencyProposal.fromPartial({
    title: 'Authorise Issue of Equity in Relation to the Issuance of Contingent Equity Conversion Notes',
    description: 'Description for test proposal',
    name: 'organizations/uuid/generalMeetings/uuid/proposals/uuid',
    source: Proposal_Source.PROPOSAL_SOURCE_SHAREHOLDER,
    outcome: {
      withdrawalReason: '',
      decision: Proposal_Outcome_Decision.PROPOSAL_OUTCOME_DECISION_FOR,
      createTime: new Date('2021-03-03'),
      votes: {
        for: { votes: 9876543 },
        against: { votes: 1000000000 },
        brokerNonVotes: { votes: 200000 },
        abstentions: { votes: 940001 },
        withheld: { votes: 10000 },
      },
    },
    managementRecommendation: undefined,
    passThreshold: { threshold: 0.5 },
    sequenceIdentifier: '1',
    ...override,
  })

export const sampleProposal = (override?: Partial<Proposal>): Proposal =>
  Proposal.fromPartial({
    name: 'organizations/uuid/generalMeetings/uuid/proposals/uuid',
    classificationCodes: ['CC'],
    sequenceIdentifier: '1.a',
    source: ProposalSource.PROPOSAL_SOURCE_SHAREHOLDER,
    title: 'Sample proposal title',
    summary: 'Summary for sample proposal',
    votingRecommendation: Option.OPTION_AGAINST,
    votingOptions: [Option.OPTION_FOR, Option.OPTION_AGAINST, Option.OPTION_ABSTAIN],
    ...override,
  })
