import React from 'react'
import { useRouter } from 'next/router'
import { useAsync } from '@tumelo/common'
import { Stack } from '@tumelo/designsystem'
import Link from 'next/link'
import styled from 'styled-components'
import { AmplifyUserCtx, sampleUserContext } from '../../../application/user'
import { AsyncUnwrapper } from '../../asyncUnwrapper'
import { getConfig } from '../../../application/environment'
import { getCurrentSlug } from '../../../utils/getCurrentSlug'
import { AvatarWithInitials } from '../../AvatarWithInitials'

export const HeaderProfile: React.FC = () => {
  const userContext = getConfig().clientType === 'mock' ? sampleUserContext() : AmplifyUserCtx()
  const detailsFetch = useAsync(async () => userContext.getUserDetails())

  const router = useRouter()

  const currentSlug = getCurrentSlug(router)

  return (
    <StyledContainer>
      <Stack flexDirection="row" gap="0.8rem">
        <AsyncUnwrapper
          result={detailsFetch}
          onData={(user) => (
            <Link href={`${currentSlug}/profile`}>
              <AvatarWithInitials firstName={user ? user.firstName : ''} lastName={user ? user.lastName : ''} />
            </Link>
          )}
        />
      </Stack>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  position: relative;
`
