import { ContactStatusOption } from '../proto/tumelo/fundmanager/v1/contact_status';
var prefix = 'fundManagers/';
export var IDToName = function (_a) {
    var fundManagerID = _a.fundManagerID;
    return prefix + fundManagerID;
};
export var nameToID = function (name) {
    if (!validateName(name)) {
        throw new Error("bad fund manager name: ".concat(name));
    }
    var parts = name.split('/');
    return { fundManagerID: parts[1] };
};
export var validateName = function (name) {
    var parts = name.split('/');
    return parts.length === 2 && name.startsWith(prefix);
};
export var contactStatusToString = function (o) {
    switch (o) {
        case ContactStatusOption.CONTACT_STATUS_INVALID:
            return 'Select';
        case ContactStatusOption.UNRECOGNIZED:
            return 'Unrecognized';
        case ContactStatusOption.UNKNOWN:
            return 'Unknown';
        case ContactStatusOption.NO_CONTACT:
            return 'No contact';
        case ContactStatusOption.INITIAL_CONTACT_MADE:
            return 'Initial contact made';
        case ContactStatusOption.DISCUSSIONS_IN_PROGRESS:
            return 'Discussions in progress';
        case ContactStatusOption.INITIAL_DATA_RECEIVED:
            return 'Initial data received';
        case ContactStatusOption.DATA_FEED_ESTABLISHED:
            return 'Data feed established';
    }
};
export var relationshipOwnerToName = function (owner) {
    return owner
        .replace('@tumelo.com', '')
        .split('.')
        .map(function (lowercase) { return "".concat(lowercase[0].toUpperCase()).concat(lowercase.slice(1)); })
        .join(' ');
};
