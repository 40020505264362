import moment from 'moment';
var defaultTimestampFormat = 'D MMM YYYY, HH:mm:ss Z';
// Returns a formatted date string using moment's date formatting capabilities
export var formattedTimestamp = function (date, format) {
    if (format === void 0) { format = defaultTimestampFormat; }
    // An undefined date is unknown
    if (date === undefined)
        return '-';
    // A null date is interpreted as 'now' and can be parsed normally
    var dateAsMoment = moment(date);
    return dateAsMoment.format(format);
};
