import { Amplify, ResourcesConfig } from 'aws-amplify'
import { Authenticator, CheckboxField, useAuthenticator } from '@aws-amplify/ui-react'
import { Stack } from '@tumelo/designsystem'
import React, { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ConfigContext, getConfig } from './environment'
import '@aws-amplify/ui-react/styles.css'
import { AmplifyStyles } from './AmplifyStyles'

interface AuthProps {
  children: React.ReactNode
}

export const Auth: React.FC<AuthProps> = ({ children }) => {
  const { userpoolId, appClientId } = useContext(ConfigContext)
  const config = getConfig()
  const router = useRouter()
  const publicPaths: string[] = ['/legal/cookies-policy']

  useMemo(() => {
    const authConfig: ResourcesConfig = {
      Auth: {
        Cognito: {
          userPoolId: userpoolId,
          userPoolClientId: appClientId,
        },
      },
    }

    Amplify.configure(authConfig)
    return Auth
  }, [userpoolId, appClientId])

  if (publicPaths.includes(router.pathname)) {
    return <>{children}</>
  }
  if (config.clientType === 'mock') {
    return <>{children}</>
  }

  return (
    <>
      <AmplifyStyles />
      <div>
        <Authenticator
          loginMechanisms={['email']}
          signUpAttributes={['email', 'family_name', 'given_name', 'phone_number']}
          formFields={{
            signUp: {
              email: {
                isRequired: true,
                order: 1,
              },
              phone_number: {
                label: 'Phone Number',
                placeholder: '7700900123',
                dialCode: '+44',
                isRequired: true,
                order: 2,
              },
              password: {
                isRequired: true,
                order: 3,
              },
              confirm_password: {
                isRequired: true,
                order: 4,
              },
              given_name: {
                label: 'First Name',
                placeholder: 'Enter your First Name',
                isRequired: true,
                order: 5,
              },
              family_name: {
                label: 'Last Name',
                placeholder: 'Enter your Last Name',
                isRequired: true,
                order: 6,
              },
            },
          }}
          components={{
            Header() {
              return (
                <Stack alignItems="center" mb="3.2rem">
                  <img alt="tumelo" src="/images/Main_Logo_Tumelo_White-min.png" width="250" height="55" />
                </Stack>
              )
            },

            SignUp: {
              FormFields() {
                const { validationErrors } = useAuthenticator()
                return (
                  <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />

                    {/* Append & require acknowledgement fields to sign up */}
                    <CheckboxField
                      errorMessage={validationErrors.termsAndConditionsMessage as string}
                      hasError={!validationErrors.termsAndConditionsValid}
                      name="terms_and_conditions"
                      value="yes"
                      label={
                        <>
                          I agree with the{' '}
                          <StyledAnchor href="https://www.tumelo.com/terms-and-conditions" target="_blank">
                            Terms &#38; Conditions
                          </StyledAnchor>
                        </>
                      }
                    />
                    <CheckboxField
                      errorMessage={validationErrors.privacyPolicyMessage as string}
                      hasError={!validationErrors.privacyPolicyValid}
                      name="privacy_policy"
                      value="yes"
                      label={
                        <>
                          I agree with the{' '}
                          <StyledAnchor href="https://www.tumelo.com/privacy-policy" target="_blank">
                            Privacy Policy
                          </StyledAnchor>
                        </>
                      }
                    />
                  </>
                )
              },
            },
          }}
          services={{
            async validateCustomSignUp(formData, touchData) {
              if (formData.terms_and_conditions && formData.privacy_policy) {
                return
              }
              return {
                termsAndConditionsValid: formData.terms_and_conditions,
                termsAndConditionsMessage:
                  touchData.terms_and_conditions && !formData.terms_and_conditions
                    ? 'You must agree to the Terms & Conditions'
                    : '',
                privacyPolicyValid: formData.privacy_policy,
                privacyPolicyMessage:
                  touchData.privacy_policy && !formData.privacy_policy ? 'You must agree to the Privacy Policy' : '',
              }
            },
          }}
        >
          {children}
        </Authenticator>
      </div>
    </>
  )
}

const StyledAnchor = styled.a`
  text-decoration: underline;
`
