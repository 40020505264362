/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** Quarter defines the calendar quarter of the year (e.g. Q1, Q2, Q3, Q4). */
export enum Quarter {
  QUARTER_INVALID = 0,
  QUARTER_1 = 1,
  QUARTER_2 = 2,
  QUARTER_3 = 3,
  QUARTER_4 = 4,
  UNRECOGNIZED = -1,
}

export function quarterFromJSON(object: any): Quarter {
  switch (object) {
    case 0:
    case "QUARTER_INVALID":
      return Quarter.QUARTER_INVALID;
    case 1:
    case "QUARTER_1":
      return Quarter.QUARTER_1;
    case 2:
    case "QUARTER_2":
      return Quarter.QUARTER_2;
    case 3:
    case "QUARTER_3":
      return Quarter.QUARTER_3;
    case 4:
    case "QUARTER_4":
      return Quarter.QUARTER_4;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Quarter.UNRECOGNIZED;
  }
}

export function quarterToJSON(object: Quarter): string {
  switch (object) {
    case Quarter.QUARTER_INVALID:
      return "QUARTER_INVALID";
    case Quarter.QUARTER_1:
      return "QUARTER_1";
    case Quarter.QUARTER_2:
      return "QUARTER_2";
    case Quarter.QUARTER_3:
      return "QUARTER_3";
    case Quarter.QUARTER_4:
      return "QUARTER_4";
    case Quarter.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** CalendarQuarter of the year (e.g. Q1 2024). */
export interface CalendarQuarter {
  /** REQUIRED The quarter of the year (e.g. Q1, Q2, Q3, Q4). */
  quarter: Quarter;
  /** REQUIRED The year (e.g. 2024). */
  year: number;
}

/** QuarterlyReport represents a report for a given scheme and calendar quarter. */
export interface QuarterlyReport {
  /** Unique relative identifier in the format "habitats/{uuid}/schemes/{uuid}/{year}/{quarter}/{filename.ext}". */
  name: string;
  /** Filename of the quarterly report, displayed in the Stewardship Platform UI. */
  displayName: string;
  /** Quarter which the report covers. */
  calendarQuarter:
    | CalendarQuarter
    | undefined;
  /** Signed URL that allows unauthenticated access to the document for 24 hours. */
  signedUrl: string;
}

function createBaseCalendarQuarter(): CalendarQuarter {
  return { quarter: 0, year: 0 };
}

export const CalendarQuarter = {
  encode(message: CalendarQuarter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quarter !== 0) {
      writer.uint32(8).int32(message.quarter);
    }
    if (message.year !== 0) {
      writer.uint32(16).int32(message.year);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CalendarQuarter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCalendarQuarter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.quarter = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.year = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CalendarQuarter {
    return {
      quarter: isSet(object.quarter) ? quarterFromJSON(object.quarter) : 0,
      year: isSet(object.year) ? Number(object.year) : 0,
    };
  },

  toJSON(message: CalendarQuarter): unknown {
    const obj: any = {};
    if (message.quarter !== 0) {
      obj.quarter = quarterToJSON(message.quarter);
    }
    if (message.year !== 0) {
      obj.year = Math.round(message.year);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CalendarQuarter>, I>>(base?: I): CalendarQuarter {
    return CalendarQuarter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CalendarQuarter>, I>>(object: I): CalendarQuarter {
    const message = createBaseCalendarQuarter();
    message.quarter = object.quarter ?? 0;
    message.year = object.year ?? 0;
    return message;
  },
};

function createBaseQuarterlyReport(): QuarterlyReport {
  return { name: "", displayName: "", calendarQuarter: undefined, signedUrl: "" };
}

export const QuarterlyReport = {
  encode(message: QuarterlyReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.calendarQuarter !== undefined) {
      CalendarQuarter.encode(message.calendarQuarter, writer.uint32(26).fork()).ldelim();
    }
    if (message.signedUrl !== "") {
      writer.uint32(34).string(message.signedUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuarterlyReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuarterlyReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.calendarQuarter = CalendarQuarter.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.signedUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuarterlyReport {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      displayName: isSet(object.displayName) ? String(object.displayName) : "",
      calendarQuarter: isSet(object.calendarQuarter) ? CalendarQuarter.fromJSON(object.calendarQuarter) : undefined,
      signedUrl: isSet(object.signedUrl) ? String(object.signedUrl) : "",
    };
  },

  toJSON(message: QuarterlyReport): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.calendarQuarter !== undefined) {
      obj.calendarQuarter = CalendarQuarter.toJSON(message.calendarQuarter);
    }
    if (message.signedUrl !== "") {
      obj.signedUrl = message.signedUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuarterlyReport>, I>>(base?: I): QuarterlyReport {
    return QuarterlyReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuarterlyReport>, I>>(object: I): QuarterlyReport {
    const message = createBaseQuarterlyReport();
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    message.calendarQuarter = (object.calendarQuarter !== undefined && object.calendarQuarter !== null)
      ? CalendarQuarter.fromPartial(object.calendarQuarter)
      : undefined;
    message.signedUrl = object.signedUrl ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
