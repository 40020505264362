import { PollVotePreferences, GroupedVotePreferences } from '../../proto/tumelo/poll/v1/vote_preferences'
import { samplePoll } from './poll'

export const samplePollVotePreferences = (override?: Partial<PollVotePreferences>): PollVotePreferences =>
  PollVotePreferences.fromPartial({
    pollName: samplePoll().name,
    votesTotal: 10,
    votesFor: 7,
    votesAgainst: 3,
    ...override,
  })

export const sampleGroupedVotePreferences = (override?: Partial<GroupedVotePreferences>): GroupedVotePreferences =>
  GroupedVotePreferences.fromPartial({
    key: 'fundManagers/dbd1defa-6d01-4cfd-857d-ab68e85e3c22/managedInstruments/GB8317088120',
    pollVotePreferences: [samplePollVotePreferences()],
    ...override,
  })
