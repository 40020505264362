/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
export var protobufPackage = "tumelo.votingpolicy.v1";
function createBaseVotingPolicyDocument() {
    return { name: "", createTime: undefined, updateTime: undefined, issueTime: undefined, title: "", signedUrl: "" };
}
export var VotingPolicyDocument = {
    encode: function (message, writer) {
        if (writer === void 0) { writer = _m0.Writer.create(); }
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.createTime !== undefined) {
            Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).ldelim();
        }
        if (message.updateTime !== undefined) {
            Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(26).fork()).ldelim();
        }
        if (message.issueTime !== undefined) {
            Timestamp.encode(toTimestamp(message.issueTime), writer.uint32(34).fork()).ldelim();
        }
        if (message.title !== "") {
            writer.uint32(42).string(message.title);
        }
        if (message.signedUrl !== "") {
            writer.uint32(50).string(message.signedUrl);
        }
        return writer;
    },
    decode: function (input, length) {
        var reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        var end = length === undefined ? reader.len : reader.pos + length;
        var message = createBaseVotingPolicyDocument();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }
                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }
                    message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }
                    message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }
                    message.issueTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }
                    message.title = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }
                    message.signedUrl = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },
    fromJSON: function (object) {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
            updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
            issueTime: isSet(object.issueTime) ? fromJsonTimestamp(object.issueTime) : undefined,
            title: isSet(object.title) ? String(object.title) : "",
            signedUrl: isSet(object.signedUrl) ? String(object.signedUrl) : "",
        };
    },
    toJSON: function (message) {
        var obj = {};
        if (message.name !== "") {
            obj.name = message.name;
        }
        if (message.createTime !== undefined) {
            obj.createTime = message.createTime.toISOString();
        }
        if (message.updateTime !== undefined) {
            obj.updateTime = message.updateTime.toISOString();
        }
        if (message.issueTime !== undefined) {
            obj.issueTime = message.issueTime.toISOString();
        }
        if (message.title !== "") {
            obj.title = message.title;
        }
        if (message.signedUrl !== "") {
            obj.signedUrl = message.signedUrl;
        }
        return obj;
    },
    create: function (base) {
        return VotingPolicyDocument.fromPartial(base !== null && base !== void 0 ? base : {});
    },
    fromPartial: function (object) {
        var _a, _b, _c, _d, _e, _f;
        var message = createBaseVotingPolicyDocument();
        message.name = (_a = object.name) !== null && _a !== void 0 ? _a : "";
        message.createTime = (_b = object.createTime) !== null && _b !== void 0 ? _b : undefined;
        message.updateTime = (_c = object.updateTime) !== null && _c !== void 0 ? _c : undefined;
        message.issueTime = (_d = object.issueTime) !== null && _d !== void 0 ? _d : undefined;
        message.title = (_e = object.title) !== null && _e !== void 0 ? _e : "";
        message.signedUrl = (_f = object.signedUrl) !== null && _f !== void 0 ? _f : "";
        return message;
    },
};
function toTimestamp(date) {
    var seconds = date.getTime() / 1000;
    var nanos = (date.getTime() % 1000) * 1000000;
    return { seconds: seconds, nanos: nanos };
}
function fromTimestamp(t) {
    var millis = (t.seconds || 0) * 1000;
    millis += (t.nanos || 0) / 1000000;
    return new globalThis.Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof globalThis.Date) {
        return o;
    }
    else if (typeof o === "string") {
        return new globalThis.Date(o);
    }
    else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
