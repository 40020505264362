/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.stewardshipbff.v1";

/** VotingPolicy defines a voting policy within the stewardship domain. */
export interface VotingPolicy {
  /**
   * name identifying the voting policy.
   *
   * example: "votingPolicies/{uuid}"
   */
  name: string;
  /** title of the voting policy. */
  title: string;
  /** url to the policy document. */
  policyDocumentUrl: string;
}

function createBaseVotingPolicy(): VotingPolicy {
  return { name: "", title: "", policyDocumentUrl: "" };
}

export const VotingPolicy = {
  encode(message: VotingPolicy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.policyDocumentUrl !== "") {
      writer.uint32(26).string(message.policyDocumentUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VotingPolicy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVotingPolicy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.policyDocumentUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VotingPolicy {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      title: isSet(object.title) ? String(object.title) : "",
      policyDocumentUrl: isSet(object.policyDocumentUrl) ? String(object.policyDocumentUrl) : "",
    };
  },

  toJSON(message: VotingPolicy): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.policyDocumentUrl !== "") {
      obj.policyDocumentUrl = message.policyDocumentUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VotingPolicy>, I>>(base?: I): VotingPolicy {
    return VotingPolicy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VotingPolicy>, I>>(object: I): VotingPolicy {
    const message = createBaseVotingPolicy();
    message.name = object.name ?? "";
    message.title = object.title ?? "";
    message.policyDocumentUrl = object.policyDocumentUrl ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
